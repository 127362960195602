import React from 'react';
import {faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './footer.scss'
import {Link, useHistory} from "react-router-dom";
import playStore from './playstore.png'
import appleStore from './appleStore.png'
import CookiesAleart from "../CookiesAleart/CookiesAleart";


export default function Footer(props) {
    const history = useHistory();


    return (
        <>
            <CookiesAleart/>
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-3">
                            <p onClick={() => {
                                history.push('/about-us')
                            }}>
                                About Us</p>
                            <p onClick={() => {
                                history.push('/contact-us')
                            }}>Contact Us</p>
                        </div>

                        <div className="col-sm-12 col-md-3">
                            
                            <p onClick={() => {
                                    window.open('https://merchant.vuacifood.com', '_blank')
                                }}>
                                    Merchant Login
                            </p>
                            <p onClick={() => {
                                history.push('/become-a-partner')
                            }}>
                                Become A Partner
                            </p>
                            <p onClick={() => {
                                history.push('/become-a-promoter')
                            }}>
                                Become A Promoter
                            </p>
                        </div>

                        <div className="col-xs-6 col-md-3">
                            <p onClick={() => {
                                history.push('/terms-and-conditions')
                            }}>Terms & Conditions</p>
                            <p onClick={() => {
                                history.push('/privacy-statement')
                            }}>Privacy Statements</p>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <img width="120px"
                                 src={playStore} alt={"Play Store"}/>
                            <img width="120px"
                                 src={appleStore} alt={"Apple Store"}/>
                        </div>
                    </div>
                    <hr/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-12">
                            <p className="copyright-text">Copyright &copy;2020
                                <a href="/"> Vuacifood</a>.
                            </p>
                        </div>

                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <ul className="social-icons">
                                <li><a className="facebook" href="https://facebook.com"><FontAwesomeIcon icon={faFacebook} color='white'/></a>
                                </li>
                                <li><a className="twitter" href="https://twitter.com"><FontAwesomeIcon icon={faTwitter}
                                                                                     color='white'/></a>
                                </li>
                                <li><a className="instagram" href="https://instagram.com"><FontAwesomeIcon icon={faInstagram}
                                                                                       color='white'/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}