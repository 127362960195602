import { Collapse, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import {getOtpForForgotPassword, verifyOtpForForgotPassword} from "../../common/components/SignInDialog/functions";
import {useHistory} from 'react-router-dom';
// MUI
import MuiPhoneNumber from "material-ui-phone-number";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// CSS
import './ForgotPassword.scss';

function ForgotPassword(props) {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [alertInfo, setAlertInfo] = React.useState({});
    const [forgotPassword, setForgotPassword] = useState(false);
    const [mobile, setMobile] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [forgotPasswordOtpReceived, setForgotPasswordOtpReceived] = useState(false);
    const [forgotPasswordOtp, setForgotPasswordOtp] = useState("")
    const [otpToken, setOtpToken] = useState("");

    const getOtpForPassword = () => {
        setForgotPassword(true)
        let mobileNumber = mobile.replace("(", "");
        mobileNumber = mobileNumber.replace(")", "");
        mobileNumber = mobileNumber.replace("-", "");
        let mobileArray = mobileNumber.split(" ");
        mobileArray[2] === undefined ?
            mobileNumber = mobileArray[1]
            :
            mobileNumber = mobileArray[1] + mobileArray[2];
        let countryCode = mobileArray[0];
        console.log("forgot", mobileNumber)
        getOtpForForgotPassword(mobileNumber)
            .then(data => {
                if (data.success) {
                    setForgotPasswordOtpReceived(true);
                    setOtpToken(data.token);
                }else{
                    setOpen(true);
                    setAlertInfo({ severity: "error", msg: data.message });
                    setTimeout(() => {
                        setOpen(false);
                      }, 2000);
                }
            }).catch(error=>{
            console.log(error);
            setAlertInfo({severity: "error", msg:"Try Again..."})
        })
    }

    const verifyOtpForPassword = ()=>{
        let mobileNumber = mobile.replace("(", "");
        mobileNumber = mobileNumber.replace(")", "");
        mobileNumber = mobileNumber.replace("-", "");
        let mobileArray = mobileNumber.split(" ");
        mobileArray[2] === undefined ?
            mobileNumber = mobileArray[1]
            :
            mobileNumber = mobileArray[1] + mobileArray[2];
        let countryCode = mobileArray[0];

        console.log({mobileNumber, otpToken, newPassword, forgotPasswordOtp})
        verifyOtpForForgotPassword(mobileNumber, otpToken, newPassword, forgotPasswordOtp)
            .then(data=>{
                console.log(data)
                if(data.success){
                    history.push('/login');
                } else{
                    setAlertInfo({severity: "error", msg:data.message});
                    setOpen(true);
                }
            }).catch(error=>{
            console.log(error);
            setAlertInfo({severity: "error", msg:"Try Again..."})
        })
    }

    const returnToLogin = (event) => {
        history.push('/login');
    }

    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-lg-6 col-md-8 mx-auto">
                    <Typography variant='h5' className='bhooky-bold'>Get OTP</Typography>
                    <Collapse in={open}>
                        <Alert severity={alertInfo.severity}>{alertInfo.msg}</Alert>
                    </Collapse>
                    <Button onClick={(event) => returnToLogin()} 
                    className="text-capitalize my-2 rounded font-weight-bold">
                        <ArrowBackIcon className="mr-1" fontSize="small" />
                        Return to Login
                    </Button>
                    <div>
                        <MuiPhoneNumber
                            defaultCountry='us'
                            onlyCountries={['in', 'us']}
                            variant="outlined"
                            margin="normal"
                            required
                            value={mobile}
                            fullWidth
                            id="mobile"
                            placeholder='Phone Number'
                            label="Mobile"
                            name="mobile"
                            autoComplete="mobile"
                            countryCodeEditable={false}
                            onChange={(mobile) => setMobile(mobile)}
                        />
                        <Button
                            fullWidth
                            className="my-2"
                            variant="contained"
                            color="primary"
                            onClick={(e) => getOtpForPassword()}
                        >GET OTP</Button>
                    </div>
                    {
                        forgotPasswordOtpReceived
                            ?
                            <div className="my-2">
                                <TextField
                                    fullWidth
                                    type={"password"}
                                    label={"New Password"}
                                    placeholder={"New Password"}
                                    value={newPassword}
                                    className="my-2"
                                    required={true}
                                    onChange={(e)=>setNewPassword(e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    label={"OTP"}
                                    placeholder={"OTP"}
                                    value={forgotPasswordOtp}
                                    className="my-2"
                                    required={true}
                                    onChange={(e)=>setForgotPasswordOtp(e.target.value)}
                                    />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className="my-2"
                                    onClick={(e) => verifyOtpForPassword()}
                                >VERIFY OTP</Button>
                            </div>
                            :null
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
