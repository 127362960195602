import { IconButton } from '@material-ui/core';
import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import "./LocationSearchInput.scss";
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import Geocode from "react-geocode";
import { BhookyConstants } from '../../AppConstants';
import { func } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import { updateLocation, UPDATE_LOCATION } from '../redux/actions';
import store from '../redux/store';
import { useEffect } from 'react';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import {useCookies} from "react-cookie";
import {logger} from "redux-logger/src";
import AddDays from "../AddDays";

function LocationSearchInput(props) {
    const [cookies, setCookies] = useCookies(['latitude', 'longitude'])
    const [address, setAddress] = React.useState('');
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const handleChange = address => {
        setAddress(address);
    };
    store.subscribe(() => {
        setAddress(store.getState()['userLocation'][0].formatted_address);
    });
    useEffect(() => {
        if (store.getState()['userLocation']) {
            setAddress(store.getState()['userLocation'][0].formatted_address);
        }
    }, []);
    const handleSelect = address => {
        Geocode.setApiKey(BhookyConstants.apiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                    let {lat, lng} = latLng
                    setCookies('latitude', lat, {path: "/", maxAge: AddDays(30)})
                    setCookies('longitude', lng, {path: "/", maxAge: AddDays(30)})
                    Geocode.fromLatLng(lat, lng)
                        .then(
                            response => {
                                const address = response.results[0].formatted_address;
                                setCookies('userLocation', address, {path: "/", maxAge: AddDays(30)})
                                dispatch(updateLocation(address));
                                setShowBackdrop(false);
                                window.location.reload();
                            },
                            error => {
                                setShowBackdrop(false);
                                console.error(error);
                            }
                        )
                },
                (error) => {
                    console.error(error);
                    setShowBackdrop(false);
                })
            .catch(error => console.error('Error', error));
    };
    const onError = (status, clearSuggestions) => {
        console.log('Google Maps API returned error with status: ', status)
        clearSuggestions()
    }
    var options = {
        enableHighAccuracy: true,
        timeout: 500,
        maximumAge: 10000
    };

    const getCurrentLocation = () => {
        Geocode.setApiKey(BhookyConstants.apiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();
        setShowBackdrop(true);
        let address = '';

        navigator.geolocation.getCurrentPosition(
            async function (position) {
                const {latitude, longitude} = position.coords;
                setCookies('latitude', latitude, {path: "/", maxAge: AddDays(30)});
                setCookies('longitude', longitude, {path: "/", maxAge: AddDays(30)})
            await Geocode.fromLatLng(latitude, longitude)
                .then(
                response => {
                    setShowBackdrop(false);
                    const address = response.results[0].formatted_address;
                    setCookies('userLocation', address, {path: "/", maxAge: AddDays(30)})
                    dispatch(updateLocation(response.results));
                    history.push('/merchants');
                    window.location.reload();
                },
                error => {
                    setShowBackdrop(false);
                    console.error(error);
                });
        }, () => {

        }, options);

    }

    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            onError={onError}
        >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="landing-search-container">
                <form>
                    <input
                        id="search-bar"
                        {...getInputProps({
                            placeholder: 'Search Places...',
                            className: 'location-search-input',
                        })}
                    />
                    {
                        showBackdrop ? <CircularProgress style={{ width: 20, height: 20, color: 'black' }} /> 
                        :
                        <IconButton className="btn-locate-me" onClick={getCurrentLocation} style={{ color: 'black' }}><MyLocationIcon /></IconButton>
                    }
                </form>
                <div className="autocomplete-dropdown-container">
                    {loading && <div className='d-flex bg-white p-4' style={{ borderRadius: "20px", transition: ".2s linear" }}>Loading</div>}
                    {suggestions.map(suggestion => {
                        const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                            <div
                                {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                })}
                            >
                                <span>{suggestion.description}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        )}
        </PlacesAutocomplete>
    );
}

const mapStateToProps = (state) => {
    return {
        userLocation: state.userLocation
    };
}
export default connect(mapStateToProps)(LocationSearchInput);

