import React, {useState} from 'react';
import {useOptions} from "../CartSummary/Stripe";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import StripePromise from "../../api/StripePromise";
import instance from "../../api/axios";
import {Alert} from "@material-ui/lab";


const Temp = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const [cardHolderName, setCardHolderName] = useState("");
    const [message, setMessage] = useState("");
const [success, setSuccess] = useState("");
const [isSuccess, setIsSuccess]= useState(false);
    const onCardHolderChange = name => {
        setCardHolderName(name);
        setMessage("");
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        if (cardHolderName !== "") {
            let cardElement = elements.getElement(CardElement);
            console.log({cardElement})
            const {paymentMethod, error} = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                    billing_details: {
                        name: cardHolderName,
                    },
                }
            );

            if (error) {
                // Display error.message in your UI.
                setMessage(error.message)
                // resultContainer.textContent = result.error.message;
            } else {
                // You have successfully created a new PaymentMethod
                console.log({paymentMethod})
                SaveTheCard(paymentMethod.id)
                // resultContainer.textContent = "Created payment method: " + result.paymentMethod.id;
            }
        } else {
            setMessage("Invalid Name...")
        }

    }

    const setSuccessMessage =  (message)=>{
        console.log({message})
        setIsSuccess(true);
        setSuccess(message)
    }
    const SaveTheCard = (id) => {
        // const stripeUrl = "https://api.stripe.com/v1/payment_methods";
        let body = {
            customer_id: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).stripeId : "",
            payment_method_id: id,
        }
        console.log({body})

        instance.post('user/savecard', body)
            .then(response => {
                console.log({response})
                let {data} = response;
                if(data.status){
                    setSuccessMessage(data.message)
                    setTimeout(window.location.href="/profile", 3000)
                }else{

                }
            })
            .catch(error => {
                console.log({error})
            })
    }
    return (
        <div className="stripe">
            {isSuccess ?             <Alert severity={"success"}>{success}</Alert>
                :null}
            <form onSubmit={(e) => onSubmit(e)}>

                <div className="div-input">
                    <label htmlFor={"cardHolderName"}>Card Holder Name:</label>
                    <input
                        autoFocus={true}
                        required={true}
                        type="text"
                        className="change-input"
                        id="cardHolderName"
                        name="cardHolderName"
                        placeholder="Card Holder Name..."
                        value={cardHolderName}
                        onChange={(e) => onCardHolderChange(e.target.value)}
                    />
                </div>
                <div className="div-input">
                    <label htmlFor="card">Card Details:
                        <CardElement
                            required={true}
                            autoFocus={true}
                            options={options}
                            // onChange={event => onCardChange(event.value)}
                            onChange={event => event}
                        />
                    </label>
                </div>
                <p>{message}</p>
                <button type={"submit"} onClick={(e) => onSubmit(e)}>Save Card</button>
            </form>
        </div>
    );
}

export default (props) => {
    return (
        <Elements stripe={StripePromise}>
            <Temp/>
        </Elements>
    )
}