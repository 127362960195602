import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Collapse, makeStyles, Typography} from '@material-ui/core';
import AppContext from '../store/AuthContext';
import {useHistory, Link} from 'react-router-dom';
import {useForm} from '../Form/useForm';
import PropTypes from 'prop-types';
import FacebookButton from '../FacebookButton';
import GoogleButton from '../GoogleButton';
import {CloseButton} from 'react-bootstrap';
import './SignInDialog.scss';
import * as _ from 'lodash';
import MuiPhoneNumber from "material-ui-phone-number";
import axios from '../../../api/axios';
import Alert from '@material-ui/lab/Alert';
import sha256 from 'crypto-js/sha256';
import {fetchUserDetails} from "../../../components/Profile/functions";
import {getOtpForForgotPassword, verifyOtpForForgotPassword} from "./functions";
import Geocode from "react-geocode";
import {updateLocation} from "../redux/actions";
import {useDispatch} from "react-redux";
import {useCookies} from "react-cookie";
import {BhookyConstants} from "../../AppConstants";
import AddDays from "../AddDays";
import {profilePic} from "../../../assets";

// Icons
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
}));


function SignInDialog(props) {
    const dispatch = useDispatch();
    const [cookies, setCookies] = useCookies(['latitude', 'longitude'])
    const [location, setLocation] = React.useState(cookies['userLocation'] ? cookies['userLocation'] : "");

    const classes = useStyles();
    const initialFValues = {
        mobile: "",
        password: ""
    };
    const {globalState, globalDispatch} = useContext(AppContext);
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [alertInfo, setAlertInfo] = React.useState({});

    const [forgotPassword, setForgotPassword] = useState(false);
    const [mobile, setMobile] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [forgotPasswordOtpReceived, setForgotPasswordOtpReceived] = useState(false);
    const [forgotPasswordOtp, setForgotPasswordOtp] = useState("")
    const [otpToken, setOtpToken] = useState("");
    const {
        values,
        setValues,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true);

    const getOtpForPassword = () => {

        setForgotPassword(true)
        let mobileNumber = mobile.replace("(", "");
        mobileNumber = mobileNumber.replace(")", "");
        mobileNumber = mobileNumber.replace("-", "");
        let mobileArray = mobileNumber.split(" ");
        mobileArray[2] === undefined ?
            mobileNumber = mobileArray[1]
            :
            mobileNumber = mobileArray[1] + mobileArray[2];
        let countryCode = mobileArray[0];
        console.log("forfot", mobileNumber)
        getOtpForForgotPassword(mobileNumber)
            .then(data => {
                console.log({data})
                if (data.success) {
                    setForgotPasswordOtpReceived(true);
                    setOtpToken(data.token);
                }else{
                    setAlertInfo({severity: "error", msg:data.message})
                }
            }).catch(error=>{
            console.log(error);
            setAlertInfo({severity: "error", msg:"Try Again..."})
        })
    }

    const verifyOtpForPassword = ()=>{
        setForgotPassword(true)
        let mobileNumber = mobile.replace("(", "");
        mobileNumber = mobileNumber.replace(")", "");
        mobileNumber = mobileNumber.replace("-", "");
        let mobileArray = mobileNumber.split(" ");
        mobileArray[2] === undefined ?
            mobileNumber = mobileArray[1]
            :
            mobileNumber = mobileArray[1] + mobileArray[2];
        let countryCode = mobileArray[0];

        console.log({mobileNumber, otpToken, newPassword, forgotPasswordOtp})
        verifyOtpForForgotPassword(mobileNumber, otpToken, newPassword, forgotPasswordOtp)
            .then(data=>{
                console.log(data)
                if(data.success){
                    setAlertInfo({severity: "error", msg:data.message})
                    window.location.reload();
                }else{
                    setAlertInfo({severity: "error", msg:data.message})
                }
            }).catch(error=>{
            console.log(error);
            setAlertInfo({severity: "error", msg:"Try Again..."})
        })
    }

    const login = () => {
        const numArr = values.mobile.split(" ");
        numArr.shift();
        let mobile = _.join(numArr, "").replace(/[^A-Z0-9]/ig, "");
        const loginObj = {
            mobile,
            password: values.password
        }
        axios.defaults.headers.post['login-type'] = 'custom'
        axios
            .post(`/auth/login/`, loginObj)
            .then(res => {
                const data = res.data;
                if (data.success) {
                    console.log({data})
                    localStorage.setItem('password', sha256(values.password + values.password));
                    localStorage.setItem('phoneNumber', mobile)
                    props.onLoginSuccess(data);

                    fetchUserDetails()
                            .then(userData => {
                                if (userData.success) {
                                    localStorage.setItem('user', JSON.stringify(userData.user_details))
                                }
                            })
                            .catch(error => {
                                console.log({error})
                            })

                    AddUserId(data._id);

                } else {
                    setOpen(true);
                    setAlertInfo({severity: "error", msg: data.message});
                }
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            })
            .catch((res) => {
                if (res.response) {
                    const data = res.response.data;
                    setOpen(true);
                    setAlertInfo({severity: "error", msg: data.message});
                    setTimeout(() => {
                        setOpen(false);
                    }, 2000);
                }
            })

    }

    const AddUserId = (userId) =>{
        let deviceId = localStorage.getItem('uuid');
        let body = {
            userId,
            deviceId
        }
        console.log({body})
        axios.post('user/updatecartIds', body )
            .then(response=>{
                console.log(response.data)
                if(response.data.success){
                    if(cookies['userLocation']){
                        fetchUserDetails()
                            .then(data => {
                                console.log("----->", {data})
                                if (data.success) {
                                    localStorage.setItem('user', JSON.stringify(data.user_details))
                                    window.location.reload();
                                } else {
                                }
                            })
                            .catch(error => {
                                console.log({error})
                            })
                    }else{
                        getLocation();
                    }
                }
            })
            .catch(error=>{
                console.error(error)
            })
    }

    const getLocation= ()=>{
        Geocode.setApiKey(BhookyConstants.apiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();

        navigator.geolocation.getCurrentPosition(
            async function (position) {
                const {latitude, longitude} = position.coords;
                setCookies('latitude', latitude, {path: "/", maxAge: AddDays(30)});
                setCookies('longitude', longitude, {path: "/", maxAge: AddDays(30)})
                await Geocode.fromLatLng(latitude, longitude)
                    .then(
                        response => {
                            const address = response.results[0].formatted_address;
                            setCookies('userLocation', address, {path: "/", maxAge: AddDays(30)})
                            setLocation(address)
                            dispatch(updateLocation(address));
                            fetchUserDetails()
                                .then(data => {
                                    if (data.success) {
                                        localStorage.setItem('user', JSON.stringify(data.user_details))
                                        window.location.reload();
                                    } else {
                                    }
                                })
                                .catch(error => {
                                    console.log({error})
                                })
                            // window.location.reload();
                        },
                        error => {
                            console.error(error);
                        }
                    )
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const returnToLogin = (event) => {
        setForgotPassword(false)
    }

    if (forgotPassword) {
        return (
            <Dialog open={props.show} han aria-labelledby="form-dialog-title">
                <div className='d-flex justify-content-between'>
                    <DialogTitle id="form-dialog-title">
                        <Typography variant='h5' className='bhooky-bold'>Get OTP</Typography>
                    </DialogTitle>
                    <CloseButton onClick={event => {
                        props.onClose();
                        resetForm();
                    }} className='mr-4 mb-2'/>
                </div>
                <DialogContent>
                    <Collapse in={open}>
                        <Alert severity={alertInfo.severity}>{alertInfo.msg}</Alert>
                    </Collapse>
                        <Button onClick={(event) => returnToLogin()} 
                        className="text-capitalize mb-2 rounded font-weight-bold">
                            <ArrowBackIcon className="mr-1" fontSize="small" />
                            Go Back 
                        </Button>
                    <div className={classes.paper} style={{display:"flex"}}>
                        <MuiPhoneNumber
                            defaultCountry='us'
                            onlyCountries={['in', 'us']}
                            variant="outlined"
                            margin="normal"
                            required
                            value={mobile}
                            // fullWidth
                            id="mobile"
                            placeholder='Phone Number'
                            label="Mobile"
                            name="mobile"
                            autoComplete="mobile"
                            countryCodeEditable={false}
                            onChange={(mobile) => setMobile(mobile)}
                        />
                        <Button
                            // fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => getOtpForPassword()}
                        >GET OTP</Button>
                    </div>
                    {
                        forgotPasswordOtpReceived
                            ?
                            <>
                                <TextField
                                    fullWidth
                                    type={"password"}
                                    label={"New Password"}
                                    placeholder={"New Password"}
                                    value={newPassword}
                                    required={true}
                                    onChange={(e)=>setNewPassword(e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    label={"OTP"}
                                    placeholder={"OTP"}
                                    value={forgotPasswordOtp}
                                    required={true}
                                    onChange={(e)=>setForgotPasswordOtp(e.target.value)}
                                    />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={(e) => verifyOtpForPassword()}
                                >VERIFY OTP</Button>
                            </>
                            :null
                    }
                </DialogContent>
            </Dialog>
        )
    } else {
        return (
            <div>
                <Dialog open={props.show} han aria-labelledby="form-dialog-title">
                    <div className='d-flex justify-content-between'>
                        <DialogTitle id="form-dialog-title">
                            <Typography variant='h5' className='bhooky-bold'>Sign In</Typography>
                        </DialogTitle>
                        <CloseButton onClick={event => {
                            props.onClose();
                            resetForm();
                        }} className='mr-4 mb-2'> </CloseButton>
                    </div>

                    <DialogContent>
                        <Collapse in={open}>
                            <Alert severity={alertInfo.severity}>{alertInfo.msg}</Alert>
                        </Collapse>
                        {/* <DialogContentText>
                        To subscribe to this website, please enter your email address here. We will send updates
                        occasionally.
          </DialogContentText> */}
                        <div className={classes.paper}>
                            <form className={classes.form} noValidate>
                                <MuiPhoneNumber
                                    defaultCountry='us'
                                    onlyCountries={['in', 'us']}
                                    variant="outlined"
                                    margin="normal"
                                    required

                                    fullWidth
                                    id="mobile"
                                    value={values.mobile}
                                    placeholder='Phone Number'
                                    label="Mobile"
                                    name="mobile"
                                    autoComplete="mobile"
                                    countryCodeEditable={false}
                                    onChange={event => handleInputChange({target: {name: 'mobile', value: event}})}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    value={values.password}
                                    onChange={handleInputChange}
                                    autoComplete="current-password"
                                />
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className='flex-column mx-3 pt-0 my-auto'>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={_.values(values).some(v => v === "")}
                            className={classes.submit}
                            onClick={login}
                        >
                            Sign In</Button>
                        <div className='d-flex justify-content-between w-100 mb-3 option-btns'>
                            <p onClick={(e) => {
                                getOtpForPassword()
                            }} style={{cursor: "pointer"}} variant="body2">
                                Forgot password?
                            </p>

                            <Link to={"/signup"} href="#" variant="body2" onClick={event => {
                                props.onSignup();
                                resetForm();
                            }}>
                                Don't have an account? Sign Up
                            </Link>
                        </div>
                        {/* <div class="or-div mb-2">
                        <hr class="or-hr" />
                        <span class="or-span">or</span>
                    </div> */}
                         <div className='d-flex mb-4 justify-content-between w-100'>
                        {/*<GoogleButton className='w-100' btnText='Sign In' handleLogin={props.onSuccessfulLogin} />*/}
                        {/*<FacebookButton />*/}
                    </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

SignInDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    children: PropTypes.node,
};

export default SignInDialog;
