import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useHistory } from "react-router-dom";
import AppContext from "../store/AuthContext";
import "./Header.scss";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "../../../api/axios";
import instance from "../../../api/axios";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import SignInDialog from "../SignInDialog/SignInDialog";
import SignUpDialog from "../SignUpDialog/SignUpDialog";
import Search from "../../../components/Search/Search";
import { Badge, IconButton, List, TextField } from "@material-ui/core";
import * as _ from "lodash";
import { BhookyConstants } from "../../AppConstants";
import Geocode from "react-geocode";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect, useDispatch } from "react-redux";
import store from "../redux/store";
import { updateLocation } from "../redux/actions";
import { useCookies } from "react-cookie";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import AddDays from "../AddDays";

// Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) =>
	createStyles({
		grow: {
			flexGrow: 1,
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		title: {
			display: "none",
			fontWeight: 600,
			color: "black",
			fontSize: 25,
			fontFamily: "'BhookyFont-Regular'",
			[theme.breakpoints.up("sm")]: {
				display: "block",
			},
		},
		sectionDesktop: {
			display: "none",
			[theme.breakpoints.up("md")]: {
				display: "flex",
			},
		},
		list: {
			width: 200,
		},
		padding: {
			paddingRight: 30,
			cursor: "pointer",
		},
		sideBarIcon: {
			marginRight: 30,
			color: "black",
			cursor: "pointer",
			display: "flex",
			alignSelf: "start",
			marginTop: 30,
		},
	})
);
const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
	},
})((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		{...props}
	/>
));
const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);
const StyledBadge = withStyles((theme) => ({
	badge: {
		right: -3,
		top: 13,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: "0 4px",
	},
}))(Badge);

function Header(props) {
	const classes = useStyles();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const logoutUser = () => {
		handleClose();
		setAnchorEl(null);
		globalDispatch({ type: "LOGOUT" });

		window.location.reload();
		console.log(globalState.isLoggedIn);
	};

	const goToOrders = () => {
		window.location.href = "/orders";
	};

	const [cookies, setCookies] = useCookies(["latitude", "longitude"]);
	const history = useHistory();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [showSignInDialog, setShowSignInDialog] = React.useState(false);
	const [drawer, setDrawer] = React.useState("0");
	const [isResponsive, setIsResponsive] = React.useState(false);
	const [showinNewLine, setShowInNewLine] = React.useState(false);
	const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);
	const [notiAnchorEl, setNotiAnchorEl] = React.useState(null);
	const [location, setLocation] = React.useState(
		cookies["userLocation"] ? cookies["userLocation"] : ""
	);
	const [address, setAddress] = useState(location);
	const [showBackdrop, setShowBackdrop] = React.useState(false);
	const [cart, setCart] = React.useState(0);
	const [pathname, setPathname] = React.useState(history.location.pathname);
	const dispatch = useDispatch();
	const open = Boolean(anchorEl);
	const openNoti = Boolean(notiAnchorEl);
	const { globalState, globalDispatch } = useContext(AppContext);
	const profileObj = JSON.parse(localStorage.getItem("profileObj"));
	const user = JSON.parse(localStorage.getItem("user"));
	const [openSearch, setOpenSearchBar] = useState(false);
	const [searchItems, setSearchItems] = useState([]);
	const [showLinks, setShowLinks] = useState(false);

	store.subscribe(() => {
		setLocation(cookies["userLocation"]);
		setCart(_.sum(_.values(store.getState()["cart"])));
		// history.push('/merchants');
	});

	const toggleSignInDialog = () => {
		setShowSignInDialog(!showSignInDialog);
		setShowSignUpDialog(false);
		setShowLinks(false);
	};

	const handleSignUp = () => {
		setShowSignInDialog(false);
		setShowSignUpDialog(!showSignUpDialog);
	};

	const onSuccessfulLogin = (response) => {
		// console.log({response})
		localStorage.setItem("accessToken", response.accessToken);
		localStorage.setItem("refreshToken", response.refreshToken);
		localStorage.setItem("profileObj", JSON.stringify(response));

		globalDispatch({ type: "LOGIN" });

		history.push("/merchants");
		setShowSignInDialog(false);
		setShowSignUpDialog(false);
	};

	const onSuccessfulSignup = (
		response,
		formValues,
		mobileNumber,
		isFormSignup
	) => {
		// debugger;
		console.log(response, formValues);
		let countryCode = mobileNumber.split(" ")[0];
		let mobile = mobileNumber.split(" ")[1].replace("-", "");
		const userObj = {
			userDetails: {
				userName: formValues.firstName + " " + formValues.lastName,
				email: formValues.email,
				mobile,
				password: formValues.password,
				countryCode,
				profilePic: isFormSignup ? "" : formValues.imageUrl,
				signupType: isFormSignup ? "custom" : "google",
				type: "user",
			},
		};
		console.log(userObj);
		axios
			.post(`/user/create`, userObj)
			.then((res) => {
				const data = res.data;
				console.log({ data });
				if (data.success) {
					// globalDispatch({type: "LOGIN"});
					setShowSignInDialog(false);
					setShowSignUpDialog(false);
					// window.localStorage.setItem("accessToken", data.accessToken);
					// window.localStorage.setItem('refreshToken', data.refreshToken);
					history.push("/merchants");
				}
			})
			.catch((error) => {});
	};

	const handleChange = (address) => {
		setAddress(address);
	};

	const handleSelect = (address) => {
		Geocode.setApiKey(BhookyConstants.apiKey);
		Geocode.setLanguage("en");
		Geocode.enableDebug();

		geocodeByAddress(address)
			.then((results) => getLatLng(results[0]))
			.then(
				(latLng) => {
					let { lat, lng } = latLng;

					setCookies("latitude", lat, {
						path: "/",
						maxAge: AddDays(30),
					});
					setCookies("longitude", lng, {
						path: "/",
						maxAge: AddDays(30),
					});
					Geocode.fromLatLng(lat, lng).then(
						(response) => {
							const address =
								response.results[0].formatted_address;
							setCookies("userLocation", address, {
								path: "/",
								maxAge: AddDays(30),
							});
							setLocation(address);
							dispatch(updateLocation(address));
							setShowBackdrop(false);
							window.location.reload();
						},
						(error) => {
							setShowBackdrop(false);
							console.error(error);
						}
					);
				},
				(error) => {
					console.error(error);
					setShowBackdrop(false);
				}
			)
			.catch((error) => console.error("Error", error));
	};

	var options = {
		enableHighAccuracy: true,
		timeout: 2000,
		maximumAge: 10000,
	};

	const getCurrentLocation = () => {
		Geocode.setApiKey(BhookyConstants.apiKey);
		Geocode.setLanguage("en");
		Geocode.enableDebug();

		setShowBackdrop(true);

		navigator.geolocation.getCurrentPosition(
			async function (position) {
				const { latitude, longitude } = position.coords;
				setCookies("latitude", latitude, {
					path: "/",
					maxAge: AddDays(30),
				});
				setCookies("longitude", longitude, {
					path: "/",
					maxAge: AddDays(30),
				});
				await Geocode.fromLatLng(latitude, longitude).then(
					(response) => {
						const address = response.results[0].formatted_address;
						setCookies("userLocation", address, {
							path: "/",
							maxAge: AddDays(30),
						});
						setLocation(address);
						dispatch(updateLocation(address));
						setShowBackdrop(false);
						window.location.reload();
					},
					(error) => {
						setShowBackdrop(false);
						console.error(error);
					}
				);
			},
			(error) => {
				console.error(error);
				setShowBackdrop(false);
			}
		);
	};

	const SearchItems = (e) => {
		if (e.target.value.length > 0) {
			let body = {
				keyword: e.target.value,
				longitude: cookies["longitude"],
				latitude: cookies["latitude"],
				maxDistance: 5000,
			};

			instance
				.post("merchant/complete/search", body)
				.then((response) => {
					if (response.data.success) {
						setSearchItems(response.data.searchItems);
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		}
	};

	const openSearchedItemDetails = (item) => {
		console.log({ item });
	};

	const getLocation = () => {
		getCurrentLocation();
	};

	const [loggedIn, setLoggedIn] = React.useState(true);

	const handleShowLinks = (e) => {
		if (e.target.className === "nav-link") {
			setShowLinks(false);
			document.body.classList.remove("fixed");
		} else {
			setShowLinks(!showLinks);
			if (showLinks === false) {
				document.body.classList.add("fixed");
			} else {
				document.body.classList.remove("fixed");
			}
		}
	};

	const NavigationLink = ({ toLink, linkTitle }) => {
		return (
			<NavLink
				to={toLink}
				activeClassName='selected'
				className='nav-link'
				onClick={handleShowLinks}>
				{linkTitle}
			</NavLink>
		);
	};

	const clearCookiesAndReturnHome = () => {
		document.cookie.split(";").forEach(function(c) {
			document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
		});
		window.location.reload(false);
		history.push('/');
	}

	useEffect(() => {
		if (window.innerWidth <= 1250) {
			setIsResponsive(true);
		}
		if (window.innerWidth <= 550) {
			setShowInNewLine(true);
		}
		history.listen((location, action) => {
			setPathname(location.pathname);
		});
		window.addEventListener("resize", () => {
			if (window.innerWidth <= 1000) {
				setIsResponsive(true);
			} else {
				setIsResponsive(false);
			}
			if (window.innerWidth <= 550) {
				setShowInNewLine(true);
			} else {
				setShowInNewLine(false);
			}
		});
	}, [location]);

	return (
		<nav className='navbar'>

			{/* Brand */}
			<div className='navbar__brand'>
				<NavLink to='/' className='navbar__brand__logo'>
					<img src='/favicon.png' alt='Vuacifood' />
					<h1 className='brand-name'>Vuacifood</h1>
				</NavLink>
			</div>

			{/* Nav Links */}

			<div className="navbar_content_wrapper">

				{cookies["userLocation"] ? (
					<PlacesAutocomplete
						value={address}
						onChange={handleChange}
						onSelect={handleSelect}>
						{({
							index,
							getInputProps,
							suggestions,
							getSuggestionItemProps,
							loading,
						}) => (
							<div key={index} className='user-location__wrapper'>
								<TextField
									id="outlined-secondary"
									variant="outlined"
									color="secondary"
									className="user-location-input"
									{...getInputProps({
										placeholder: "Search Places...",
									})}
									InputProps={{
										endAdornment: showBackdrop ? (
											<CircularProgress
												style={{
													width: 20,
													height: 20,
													color: "#191818",
												}}
											/>
										) : (
											<IconButton
												onClick={getLocation}
												style={{
													color: "#191818",
												}}>
												<MyLocationIcon />
											</IconButton>
										),
									}}
									onFocus={() => setAddress("")}
								/>
								<div className='user-location__dropdown shadow-sm my-2'>
									{loading && (
										<span className='user-location__dropdown__loading bg-white'>
											Loading...
										</span>
									)}
									{suggestions.map((suggestion) => {
										const className = "suggestion-item";

										const key = suggestion.description;
										return (
											<div
												className='user-location__dropdown__suggestions'
												{...getSuggestionItemProps(
													suggestion,
													{
														className,
														key,
													}
												)}>
												<span>
													{suggestion.description}
												</span>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</PlacesAutocomplete>)
					:
					""
				}

				<div className='navbar__body'>

					{/* Hamburger Menu Button */}
					<button
						className={
							showLinks
								? "navbar__body__toggler selected"
								: "navbar__body__toggler"
						}
						onClick={handleShowLinks}>
						<span className='sr-only'>Toggle navigation</span>
						<span className='bar bar__1'></span>
						<span className='bar bar__2'></span>
						<span className='bar bar__3'></span>
					</button>

					<div
						className={
							showLinks
								? "navbar__body__links show"
								: "navbar__body__links"
						}>

						<ul>
							{cookies["userLocation"] ? (
								//
								// if location is set
								//
								<>
									{globalState.isLoggedIn ? (
										<>
											<li>
												<IconButton
													aria-controls="simple-menu"
													aria-haspopup="true"
													onClick={handleClick}
													style={{
														color: "#191818",
														padding: 0,
													}}
												>
													<AccountCircleIcon />
												</IconButton>
													{/* <img
														src={user?.profilePic}
														alt='Vuacifood'
														className="rounded-circle"
														width="40px"
														height="40px"
														style={{"object-fit": "cover"}}
													/> */}

													{/* <svg
														className={
															showLinks
																? "arrow-selected"
																: ""
														}
														xmlns='http://www.w3.org/2000/svg'
														x='0px'
														y='0px'
														width='451.847px'
														height='451.847px'
														viewBox='0 0 451.847 451.847'>
														<path d='M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z' />
													</svg> */}
												<Menu
													id="simple-menu"
													anchorEl={anchorEl}
													keepMounted
													open={Boolean(anchorEl)}
													onClose={handleClose}
												>
													<MenuItem>
														<Link to='/profile' onClick={handleClose} style={{color: "#191818"}}>
															My Profile
														</Link>
													</MenuItem>
													<MenuItem>
														<Link to='/orders' onClick={handleClose} style={{color: "#191818"}}>
															Orders
														</Link>
													</MenuItem>
													<MenuItem>
														<Link to='#' onClick={logoutUser} style={{color: "#191818"}}>
															Logout
														</Link>
													</MenuItem>
												</Menu>
											</li>
											<li
												onClick={(e) => {
													clearCookiesAndReturnHome();
												}}
											>
												<Link>
													{/* Home */}
													<HomeIcon />
												</Link>
											</li>
										</>
									) :
									(
										<>
											<li
												onClick={(e) => {
													clearCookiesAndReturnHome();
												}}
											>
												<Link>
													{/* Home */}
													<HomeIcon />
												</Link>
											</li>
											<li>
												<NavigationLink
															toLink='/login'
															linkTitle='Login'
												/>
											</li>
										</>
									)
									}
									<li>
										<NavLink to='/user-help'>
											Help
										</NavLink>
									</li>
									<li>
										<NavLink to='/cart-summary'>
											Cart
											<StyledBadge
												badgeContent={cart}
												color='secondary'>
												<ShoppingCartIcon className="ml-1"/>
											</StyledBadge>
										</NavLink>
									</li>
									<li>
										<Search />
									</li>
								</>
							) : (

								//
								// if location not set
								//
								<>
									<li>
										<NavigationLink
											toLink='/become-a-promoter'
											linkTitle='Promote'
										/>
									</li>

									<li>
										<NavigationLink
											toLink='/become-a-partner'
											linkTitle='Become a Partner'
										/>
									</li>

									<li>
										<NavigationLink
											toLink='/about-us'
											linkTitle='About us'
										/>
									</li>

									<li>
										<NavigationLink
											toLink='/contact-us'
											linkTitle='Contact us'
										/>
									</li>

									{!globalState.isLoggedIn
										?
										<li>
											<NavLink
												className='nav-link'
												onClick={() => {
													window.open('https://merchant.vuacifood.com', '_blank')
												}}
												to='#'>
												Merchant Login
											</NavLink>

										</li>
										:
										""
									}

									<li>
										{globalState.isLoggedIn ? (
											<Link to='#' onClick={logoutUser}>
												Logout
											</Link>
										) : (
											<>
												<NavigationLink
															toLink='/login'
															linkTitle='Login'
												/>
											</>
										)}
									</li>
								</>
							)}
						</ul>
					</div>
				</div>

			</div>

			<SignInDialog
				show={showSignInDialog}
				onClose={toggleSignInDialog}
				onLoginSuccess={onSuccessfulLogin}
				onSignup={handleSignUp}
			/>
			<SignUpDialog
				show={showSignUpDialog}
				onClose={handleSignUp}
				onSignin={toggleSignInDialog}
				onSuccessfulSignup={onSuccessfulSignup}
			/>
		</nav>
	);
}

const mapStateToProps = (state) => {
	return {
		location: state.location,
		cart: state.cart,
	};
};
export default connect(mapStateToProps)(Header);