import { Card, CardContent, Typography } from '@material-ui/core';
import GradeIcon from '@material-ui/icons/Grade';
import { Rating } from '@material-ui/lab';

import React from 'react';
export default function Review(props) {
    return (
        <Card className="card m-3 w-25">
            <CardContent>
                <Typography className="bhooky-semibold title mb-3">
                    {props.reviewInfo.userName}
                </Typography>
                <div>
                    <h6 className="m-0 mb-1 p-0">Rating:</h6>
                    <Rating name="half-rating" size="small" defaultValue={props.reviewInfo.rating} precision={0.5} readOnly />
                </div>
                <Typography className="mt-2" variant='body2' color='textSecondary'>
                    {props.reviewInfo.description}
                </Typography>
            </CardContent>
        </Card>
    );
}
