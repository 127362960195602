import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import AppStateProvider from './common/components/store/AppStateProvider';
import {CookiesProvider} from "react-cookie";
import instance from "./api/axios";
import baseURL from "./api/baseURL";
require('dotenv').config()


instance.interceptors.request.use((config) => {
    if (localStorage.getItem('refreshToken') && localStorage.getItem('refreshToken').length > 0) {
        config.headers.Authorization = "Bearer " + localStorage.getItem("accessToken");
        config.headers.auth_type = 'user';
    }
    return config;
}, (error) => {
    console.log("error", error);
    return Promise.reject(error);
});

instance.interceptors.response.use(originalReq => {
    if (localStorage.getItem('refreshToken') && localStorage.getItem('refreshToken').length > 0) {

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('refreshToken'),
            'AuthType': 'user'
        }
        originalReq.config._retry = true;
        fetch(`${baseURL}auth/access-token`, {method: "POST", headers: headers})
            .then(res => {
                return res.json()
            }).then(data => {
                if(data.statusCode === "401"){
                    localStorage.clear();
                    window.location.reload();
                    window.location.reload();
                }else {
                    localStorage.setItem("accessToken", data.accessToken)
                    originalReq.config.headers.Authorization = "Bearer " + data.accessToken;
                    // console.log(originalReq.config, " ------ ----- ------ ", data.accessToken)
                    // window.location.reload()
                }
        }).catch(error => {
            console.log("error", error);
        })
    }
    return originalReq;
}, (error) => {
    // console.log("error", error);
    return Promise.reject(error);
})

ReactDOM.render(
    <React.StrictMode>
    <BrowserRouter>
        <AppStateProvider>
            <CookiesProvider>
                <App/>
            </CookiesProvider>
        </AppStateProvider>
    </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
