import React, {useContext, useState} from 'react'
import {Link, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useCookies} from "react-cookie";
import Geocode from "react-geocode";
import * as _ from 'lodash';
import sha256 from 'crypto-js/sha256';
// Material UI
import MuiPhoneNumber from "material-ui-phone-number";
import {Collapse, IconButton, InputAdornment, makeStyles, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import axios from '../../api/axios';
import AppContext from "../../common/components/store/AuthContext";
import {fetchUserDetails} from "../../components/Profile/functions";
import {updateLocation} from "../../common/components/redux/actions";
import {BhookyConstants} from "../../common/AppConstants";
import AddDays from "../../common/components/AddDays";
import {useForm} from '../../common/components/Form/useForm';
// CSS
import './Login.scss';
// Images
import Logo from '../../assets/favicon.png';
import Android from '../../assets/android.svg';
import Apple from '../../assets/apple.svg';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from '@material-ui/icons/VisibilityOff';

function Login() {

    const useStyles = makeStyles((theme) => ({
        paper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(2, 0, 2),
        },
    }));
    
    const dispatch = useDispatch();
    const [cookies, setCookies] = useCookies(['latitude', 'longitude'])
    const [location, setLocation] = React.useState(cookies['userLocation'] ? cookies['userLocation'] : "");

    const classes = useStyles();
    const initialFValues = {
        mobile: "",
        password: ""
    };
	const { globalState, globalDispatch } = useContext(AppContext);
    const [open, setOpen] = React.useState(false);
    const [alertInfo, setAlertInfo] = useState({});
    const history = useHistory();
    const {
        values,
        handleInputChange,
    } = useForm(initialFValues, true);
    // Password Visibility Handlers
    const [showPass, setShowPass] = useState(false);
    const handleShowPass = () => setShowPass(!showPass);

    // Primary Login Function
    const login = (event) => {
        const numArr = values.mobile.split(" ");
        numArr.shift();
        let mobile = _.join(numArr, "").replace(/[^A-Z0-9]/ig, "");
        const loginObj = {
            mobile,
            password: values.password
        }
        axios.defaults.headers.post['login-type'] = 'custom'
        axios
            .post(`/auth/login/`, loginObj)
            .then(res => {
                const data = res.data;
                if (data.success) {
                    history.push('/')
                    localStorage.setItem('password', sha256(values.password + values.password))
                    localStorage.setItem('phoneNumber', mobile)
                    localStorage.setItem("accessToken", data.accessToken);
                    localStorage.setItem("refreshToken", data.refreshToken);
                    localStorage.setItem("profileObj", JSON.stringify(data));
                    globalDispatch({ type: "LOGIN" });

                    fetchUserDetails()
                        .then(userData => {
                            if (userData.success) {
                                localStorage.setItem('user', JSON.stringify(userData.user_details))
                            }
                        })
                        .catch(error => {
                            console.log({error})
                        })

                    AddUserId(data._id);

                } else {
                    setOpen(true);
                    setAlertInfo({severity: "error", msg: data.message});
                }
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            })
            .catch((res) => {
                if (res.response) {
                    const data = res.response.data;
                    setAlertInfo({severity: "error", msg: data.message});
                    setTimeout(() => {
                        setOpen(false);
                    }, 2000);
                }
            })

    }

    const AddUserId = (userId) =>{
        let deviceId = localStorage.getItem('uuid');
        let body = {
            userId,
            deviceId
        }
        axios.post('user/updatecartIds', body )
            .then(response=>{
                if(response.data.success){
                    if(cookies['userLocation']){
                        fetchUserDetails()
                            .then(data => {
                                if (data.success) {
                                    localStorage.setItem('user', JSON.stringify(data.user_details))
                                } else {
                                }
                            })
                            .catch(error => {
                                console.log({error})
                            })
                    } else{
                        getLocation();
                    }
                }
            })
            .catch(error=>{
                console.error(error)
            })
    }

    const getLocation= ()=>{
        Geocode.setApiKey(BhookyConstants.apiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();

        navigator.geolocation.getCurrentPosition(
            async function (position) {
                const {latitude, longitude} = position.coords;
                setCookies('latitude', latitude, {path: "/", maxAge: AddDays(30)});
                setCookies('longitude', longitude, {path: "/", maxAge: AddDays(30)})
                await Geocode.fromLatLng(latitude, longitude)
                    .then(
                        response => {
                            const address = response.results[0].formatted_address;
                            setCookies('userLocation', address, {path: "/", maxAge: AddDays(30)})
                            setLocation(address)
                            dispatch(updateLocation(address));
                            fetchUserDetails()
                                .then(data => {
                                    if (data.success) {
                                        localStorage.setItem('user', JSON.stringify(data.user_details))
                                    } else {
                                    }
                                })
                                .catch(error => {
                                    console.log({error})
                                })
                        },
                        error => {
                            console.error(error);
                        }
                    )
            },
            (error) => {
                console.error(error);
            }
        );
    }

    return (
        <div className="container my-5">
            <div className="row m-0 login-wrapper">
                <div className="col-lg-6 col-md-8 login-form mx-auto">
                    <div className="heading">
                        <img src={Logo} alt="Vuacifood" width="60px" height="60px" />
                        <Typography variant='h5' className='bhooky-bold'>Login to Vuacifood</Typography>
                    </div>
                    <form noValidate>
                        <Collapse in={open}>
                            <Alert severity={alertInfo.severity}>{alertInfo.msg}</Alert>
                        </Collapse>
                        <div className="form-fields">
                            <div className="form-group my-2 text-center">
                                <MuiPhoneNumber
                                    defaultCountry='us'
                                    onlyCountries={['in', 'us']}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Mobile"
                                    name="mobile"
                                    id="mobile"
                                    countryCodeEditable={false}
                                    value={values.mobile}
                                    onChange={event => handleInputChange({target: {name: 'mobile', value: event}})}
                                    autoComplete="mobile"
                                />
                            </div>
                            <div className="form-group my-2">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPass ? "text" : "password"}
                                    id="password"
                                    value={values.password}
                                    onChange={handleInputChange}
                                    autoComplete="current-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="password visibility"
                                                onClick={handleShowPass}
                                              >
                                                {showPass ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                            <div className="form-group my-2 text-center">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={_.values(values).some(v => v === "")}
                                    className={classes.submit}
                                    onClick={login}
                                >
                                Sign In</Button>
                            </div>
                            <div className="d-flex justify-content-between w-100">
                            </div>
                            <div className="forgot-password-wrapper">
                                <a href="/forgot-password" className="text-decoration-none text-muted d-block mb-2">Forgot Password?</a>
                                <hr />
                                <Link to={"/signup"} className="text-decoration-none d-block mt-2">
                                    Don't have an account? Sign Up
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="app-store-links text-center my-5">
                <a href="http://vuacifood.com"><img src={Android} alt="Vuacifood on Android Playstore" className="mx-3" /></a>
                <a href="http://vuacifood.com"><img src={Apple} alt="Vuacifood on Appstore" className="mx-3"/></a>
            </div>
        </div>
    )    
}

export default Login
