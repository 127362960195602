
import React, {useState} from 'react';
import * as _ from 'lodash';
// Material UI
import MuiPhoneNumber from "material-ui-phone-number";
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import {Collapse, TextField, InputAdornment, IconButton } from '@material-ui/core';
import {makeStyles, Typography} from '@material-ui/core';
import {Link, useHistory} from "react-router-dom";
// Password Visibility 
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// CSS
import './SignUp.scss';
import axios from '../../api/axios';
import instance from '../../api/axios';
import store from '../../common/components/redux/store';
import {getOtp, verifyOTP} from "../../common/components/SignUpDialog/functions";
import { validateEmail } from '../../common/validators';
import {useForm} from '../../common/components/Form/useForm';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',  
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 1, 2, 1),

    },
}));

function SignUp(props) {

    const classes = useStyles();

    const initialFValues = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
    };

    store.subscribe(() => {
        setLocationObj(store.getState()['userLocation'][0].formatted_address);
    });
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [alertInfo, setAlertInfo] = useState({});
    const [locationObj, setLocationObj] = React.useState({results: []});
    const [isFormSignUp, setIsFormSignUp] = React.useState(true);
    const [otpRequestSent, setOtpRequestSent] = React.useState(false);
    const [emailOTPRequestSent, setEmailOTPRequestSent] = React.useState(false);
    const [otpVerified, setOtpVerified] = React.useState(false);
    const [emailOTPVerified, setEmailOTPVerified] = useState(false);
    const [emailOTP, setEmailOTP] = useState("");
    const [emailToken, setEmailToken] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobile, setMobile] = useState("");
    const [otp, setOTP] = useState("");
    const [errorMsg, setErrorMsg] = useState("")

    // Password Visibility Handlers
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const handleShowPass = () => setShowPass(!showPass);
    const handleShowConfirmPass = () => setShowConfirmPass(!showConfirmPass);

    const onSuccessfulSignup = (
		response,
		formValues,
		mobileNumber,
		isFormSignup
	) => {
		// debugger;
		console.log(response, formValues);
		let countryCode = mobileNumber.split(" ")[0];
		let mobile = mobileNumber.split(" ")[1].replace("-", "");
		const userObj = {
			userDetails: {
				userName: formValues.firstName + " " + formValues.lastName,
				email: formValues.email,
				mobile,
				password: formValues.password,
				countryCode,
				profilePic: isFormSignup ? "" : formValues.imageUrl,
				signupType: isFormSignup ? "custom" : "google",
				type: "user",
			},
		};
		console.log(userObj);
		axios
			.post(`/user/create`, userObj)
			.then((res) => {
				const data = res.data;
				console.log({ data });
				if (data.success) {
					history.push("/merchants");
				}
			})
			.catch((error) => {});
	};


    const getOtpForSignUp = (mobileNumber) => {
        // console.log(mobile);
        if(mobileNumber.length>0){
            let mobile = mobileNumber.replace("(", "");
            mobile = mobile.replace(")", "");
            mobile = mobile.replace("-", "");
            let mobileArray = mobile.split(" ");
            mobileArray[2] === undefined ?
                mobile = mobileArray[1]
                :
                mobile = mobileArray[1] + mobileArray[2];
            let countryCode = mobileArray[0];
            console.log(countryCode, mobile)

            if (mobile.length < 10) {
                setErrorMsg("Invalid Mobile Number")
            } else {
                getOtp(mobile, countryCode, 'user')
                    .then(data => {
                        console.log(data)
                        if (data.success) {
                            setOtpRequestSent(true);
                            setErrorMsg(data.message)
                        }else{
                            setAlertInfo({severity: "error", msg: data.message});
                            setErrorMsg(data.message)
                            setOtpRequestSent(false);
                        }
                    })
                    .catch(error => {
                        setOtpRequestSent(false);
                        setErrorMsg("Check your Mobile Number")
                    })
            }
        }else {
            setErrorMsg("Invalid Mobile Number")
        }
    }

    const verifyOtpForSignUp = (mobileNumber, otp) => {
        let mobile = mobileNumber.replace("(", "");
        mobile = mobile.replace(")", "");
        mobile = mobile.replace("-", "");
        let mobileArray = mobile.split(" ");
        mobileArray[2] === undefined ?
            mobile = mobileArray[1]
            :
            mobile = mobileArray[1] + mobileArray[2];
        let countryCode = mobileArray[0];
        console.log(countryCode, mobile, otp)

        if (mobile.length < 10) {
            setErrorMsg("Invalid Mobile Number")
        } else if (otp.length < 6) {
            setErrorMsg("Invalid OTP")
        } else {
            verifyOTP(mobile, otp, 'user')
                .then(data => {
                    console.log(data)
                    if (data.status) {
                        setOtpVerified(true);
                        setErrorMsg(data.message)
                    } else {
                        setAlertInfo({severity: "error", msg: data.message});
                        setErrorMsg(data.message)
                        setOtpVerified(false)
                    }
                })
                .catch(error => {
                    setOtpVerified(false);
                })
        }
    }

    const {
        values,
        handleInputChange,
    } = useForm(initialFValues, true);
  
    const onEmailOTPChange = (otp) => {
      setEmailOTP(otp);
      setEmailError("");
    };

    const getOTPEmail = (email) => {
        console.log('Email: ', email);
        if (validateEmail(email)) {
            instance
            .post("message/user/email", { email })
            .then((response) => {
                let { data } = response;
                if (data.success) {
                    setEmailError(data.message);
                    setEmailOTPRequestSent(true);
                    setEmailToken(data.token);
                    console.log(emailOTPRequestSent)
                    setAlertInfo({severity: "error", msg: data.message});
                } else {
                    setEmailOTPRequestSent(false);
                    setEmailError("Check the E-mail entered");
                    setAlertInfo({severity: "error", msg: data.message});
                    setEmailError(data.message);
                }
            })
            .catch((error) => {
                console.log({ error });
            });
        } else {
            setEmailError("Invalid Email");
        }
    };

    const verifyEmailOtpForSignUp = (email, otp) => {
        if (validateEmail(email)) {
            if (otp.length > 0) {
            let body = {
                otp: otp,
                token: emailToken,
                email: email,
            };
            instance
                .post("user/verifyotp", body)
                .then((response) => {
                let { data } = response;
                if (data.status) {
                    setEmailOTPVerified(true);
                } else {
                    setEmailError(data.message);
                    setAlertInfo({severity: "error", msg: data.message});
                    setEmailOTPVerified(false);
                }
                })
                .catch((error) => {
                    setEmailOTPVerified(false);
                    setEmailError("Try Again");
                });
            } else {
            setEmailError("Invalid OTP");
            }
        } else {
            setEmailError("Invalid Email");
        }
    };
    return (
        <div className="container my-2">
            <Collapse in={open}>
                <Alert severity={alertInfo.severity}>{alertInfo.msg}</Alert>
            </Collapse>
            <div className="row m-0 signup-wrapper">
                <div className="col-lg-6 col-md-6 my-4">
                    <div className="signup-illustration">
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 my-4 signup-form">
                    <h5 className="font-weight-bold">SignUp Now</h5>
                    <div className='d-flex justify-content-between w-100 option-btns'>
                        <Link to="/login" className="text-decoration-none link-to-login">
                            Already have an account? Sign In
                        </Link>
                    </div>
                    <form className={classes.form} noValidate>
                        <div className='d-flex'>
                            {isFormSignUp && <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                type="text"
                                id="firstName"
                                value={values.firstName}
                                label="First Name"
                                name="firstName"
                                autoComplete="firstName"
                                onChange={handleInputChange}
                                autoFocus
                                className='mr-3'
                            />}
                            {isFormSignUp && <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                type="text"
                                id="lastName"
                                value={values.lastName}
                                label="Last Name"
                                name="lastName"
                                autoComplete="lastName"
                                onChange={handleInputChange}
                            />}
                        </div>
                        <div className="d-flex justify-content-between">
                            <MuiPhoneNumber
                                defaultCountry='us'
                                onlyCountries={['in', 'us']}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="mobile"
                                disabled={otpVerified}
                                value={values.mobile}
                                placeholder='Phone Number'
                                label="Mobile"
                                name="mobile"
                                autoComplete="mobile"
                                countryCodeEditable={false}
                                onChange={(mobile) => setMobile(mobile)}
                            />
                            {
                                otpVerified ? null :
                                    <Button
                                        type="button"
                                        onClick={(e) => getOtpForSignUp(mobile)}
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        VERIFY</Button>
                            }

                        </div>
                        {!otpVerified ?
                        <div>
                            <small className="text-info">Please verify your mobile number</small>
                        </div>
                        : ""}
                        <small className="text-danger">{errorMsg}</small>
                        {
                            (otpRequestSent && !otpVerified) ?
                                <div className="d-flex justify-content-between">

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        type="text"
                                        id="otp"
                                        value={otp}
                                        label="OTP"
                                        name="otp"
                                        autoComplete="otp"
                                        onChange={(e) => setOTP(e.target.value)}
                                        autoFocus
                                        className='mr-3'
                                    />
                                    <Button
                                        type="button"
                                        onClick={(e) => verifyOtpForSignUp(mobile, otp)}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        VERIFY OTP</Button>
                                    <Button
                                        type="button"
                                        onClick={(e) => setOTP(e.target.value)}
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        RESEND OTP</Button>
                                </div>
                                : null
                        }
                        {isFormSignUp && 
                            <div className="d-flex justify-content-between">
                                <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                type="text"
                                id="email"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                disabled={emailOTPVerified}
                                value={values.email}
                                label="Email *"
                                name="email"
                                onChange={handleInputChange}
                                />
                                {emailOTPVerified ? null : (
                                <Button
                                    type="button"
                                    onClick={(e) => getOTPEmail(values.email)}
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    VERIFY
                                </Button>
                                )}
                            </div>
                        }
                        {!emailOTPVerified ?
                        <div>
                            <small className="text-info">Please verify your email</small>
                        </div>
                        : ""}
                        <small className="text-danger">{emailError}</small>
                        {emailOTPRequestSent && !emailOTPVerified ? (
                            <div className="d-flex justify-content-between">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="text"
                                    id="emailOTP"
                                    value={emailOTP}
                                    label="OTP"
                                    name="emailOTP"
                                    autoComplete="emailOTP"
                                    onChange={(event) =>
                                    onEmailOTPChange(event.target.value)
                                    }
                                    autoFocus
                                    className="mr-3"
                                />
                                <Button
                                    type="button"
                                    onClick={(e) =>
                                    verifyEmailOtpForSignUp(values.email, emailOTP)
                                    }
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    VERIFY OTP
                                </Button>
                                <Button
                                    type="button"
                                    onClick={(e) =>
                                        getOTPEmail(values.email)
                                    }
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    RESEND OTP
                                </Button>
                            </div>
                        ) : null}

                        {isFormSignUp && <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPass ? "text" : "password"}
                            id="password"
                            value={values.password}
                            onChange={handleInputChange}
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="password visibility"
                                        onClick={handleShowPass}
                                      >
                                        {showPass ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />}

                        {isFormSignUp && <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirm_password"
                            label="Confirm Password"
                            type={showConfirmPass ? "text" : "password"}
                            id="confirm_password"
                            value={values.confirm_password}
                            onChange={handleInputChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="password visibility"
                                        onClick={handleShowConfirmPass}
                                      >
                                        {showConfirmPass ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />}
                    </form>
                    <Typography variant="caption" display="block" gutterBottom>
                        By clicking Sign up or continue with Facebook or Google you agree to the
                        <Link to="/terms-and-conditions" target="_blank" className="mx-1">
                            Terms and Conditions  
                        </Link>
                         and  
                        <Link to="/privacy-statement" target="_blank" className="mx-1">
                        Privacy Statement
                        </Link>
                    </Typography>
                    {
                    otpVerified ?
                        <Button
                            type="submit"
                            onClick={event => onSuccessfulSignup(event, values, mobile, isFormSignUp)}
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={_.values(values).some(v => v === "")}
                            className={classes.submit}
                        >
                            Sign Up
                            </Button>
                        :null
                    }
                </div>
            </div>
        </div>
    )
}

export default SignUp
