import React, {useEffect, useMemo, useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import './Stripe.scss'
import useResponsiveFontSize from "./useResponsiveFontSize";
import {UpdateOrders} from "./functions";
import instance from "../../api/axios";

export const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }),
        [fontSize]
    );

    return options;
};

function CardForm(props) {

    let secret = props.secret;
    let {paymentId, VFID, payment_id} = props.payment;
    const [savedCardsFetched, setSavedCardsFetched] = useState(false);
    const [savedCards, setSavedCards] = useState([]);
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const handleSubmit = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        const payload = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        });

        console.log("[PaymentMethod]", payload);
        if (payload && payload.paymentMethod && payload.paymentMethod.id && payload.paymentMethod.id.length) {
            stripe
                .confirmPaymentIntent(secret, {
                    payment_method: payload.paymentMethod.id,
                })
                .then(function (result) {
                    // Handle result.error or result.paymentIntent
                    if (result.paymentIntent && result.paymentIntent.status) {
                        UpdateOrders(paymentId, VFID, payment_id);
                    } else {

                    }
                })
                .catch(error => {
                    console.log({error})
                });
        }
    };

    const payViaOldCard = async (card) =>{
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        if (card &&  card.id && card.id.length) {
            stripe
                .confirmPaymentIntent(secret, {
                    payment_method: card.id,
                })
                .then(function (result) {
                    // Handle result.error or result.paymentIntent
                    if (result.paymentIntent && result.paymentIntent.status) {
                        UpdateOrders(paymentId, VFID, payment_id);
                    } else {

                    }
                })
                .catch(error => {
                    console.log({error})
                });
        }
    }

    const fetchCards = () =>{
        let body = {
            customer_id: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).stripeId : "",
            type:"card",
        }
        instance.post('user/cards', body)
            .then(response=>{
                // console.log({response})
                setSavedCardsFetched(true)
                setSavedCards(response.data.data)
            })
            .catch(error=>{
                console.log({error})
                setSavedCardsFetched(true)

            })
    }

    useEffect(() => {
            fetchCards();
    }, [])

    return (
        <div className="stripe">
            <form onSubmit={handleSubmit}>
                <label>
                    Card details
                    <CardElement
                        options={options}
                        onChange={event => event}
                    />
                </label>
                <button type="submit" disabled={!stripe}>
                    Pay
                </button>
            </form>
            <hr/>
            {
                savedCards.length >0 ?
                    <div className="cards-div">
                        <h6>Existing Cards: </h6>
                        {
                            savedCards.map(card=>{
                                return(
                                    <button key={card.id} onClick={(e)=>payViaOldCard(card)}>************{card.card.last4}</button>
                                )
                            })
                        }
                    </div>
                    :null
            }
        </div>
    );
}

export default CardForm;
