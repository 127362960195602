import React, {useContext, useEffect, useState} from 'react';
import './BhookyUserHome.scss';
import RestaurantCard from '../../common/components/RestaurantCard/RestaurantCard';
import {
    Button,
    CardMedia,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    IconButton,
    Link,
    makeStyles,
    Snackbar,
    Typography,
    withStyles
} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import {Carousel, CloseButton} from 'react-bootstrap';
import Slider from '@material-ui/core/Slider';
import * as _ from 'lodash';
import {useHistory} from 'react-router-dom';
import AddorRemoveButtons from '../../common/components/AddorRemoveButtons/AddorRemoveButtons';
import axios from '../../api/axios';
import store from '../../common/components/redux/store';
import AppContext from '../../common/components/store/AuthContext';
import Axios from 'axios';
import {updateCartCount} from '../../common/components/redux/actions';
import {connect} from 'react-redux';
import {useCookies} from "react-cookie";
import InfiniteScroll from "react-infinite-scroll-component";

// Icons
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const itemCountsPerPage = 20;

const PrettoSlider = withStyles((theme) => ({
    root: {
        color: theme.palette.primary,
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    mark: {
        display: 'none'
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4
    },
}))(Slider);

const priceMarks = [
    {
        value: 1,
        label: '$',
    },
    {
        value: 2,
        label: '$$',
    },
    {
        value: 3,
        label: '$$$',
    },
    {
        value: 4,
        label: '$$$$',
    }
];

const ratingMarks = [
    {
        value: 0,
        label: '-'
    },
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    }
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fullList: {
        width: 'auto',
    },
    cover: {
        width: '100%',
        height: 300,
        marginBottom: 30
    },
}));

function BhookyUserHome(props) {
    // Skips
    const [skip, setSkip] = useState(0);
    const [filterSkip, setFilterSkip] = useState(0);

    const classes = useStyles();
    const history = useHistory();
    const [cookies, setCookies] = useCookies(['userLocation'])
    const {globalState} = useContext(AppContext);
    const [open, setOpen] = React.useState(false);
    const [cartToggler, setCartToggler] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [product, setProduct] = React.useState({});
    const [location, setLocation] = React.useState(cookies['userLocation'] ? cookies['userLocation'] : "");
    const [isResponsive, setIsResponsive] = React.useState(false);

    const [hasMore, setHasMore]= React.useState(true);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    // Cart Toggler
    const handleCartToggler = () => {
        setCartToggler(false)
    }

    useEffect(() => {
        if (store.getState()) {
            setLocation(store.getState()['userLocation'][0]);
        }
        store.subscribe(() => {
            setLocation(store.getState()['userLocation'][0]);
        });
        if (location !== "") {
            setProductsFromApi(noFilterProducts());
        } else {
            store.subscribe(() => {
                setLocation(store.getState()['userLocation'][0]);
            })
        }
        if (window.innerWidth <= 550) {
            setIsResponsive(true);
        }
    }, [location])

    window.addEventListener('resize', () => {
        if (window.innerWidth <= 550) {
            setIsResponsive(true);
        } else {
            setIsResponsive(false);
        }
    }, []);

    //
    const noFilterProducts = () => {
        const noFilterProduct = {
            status: "available",
            location: {
                longitude: +cookies['longitude'],
                latitude: +cookies['latitude'],
                maxDistance: 5000
            },
            type: 'user',
            filter: false
        }
        return noFilterProduct
    }

    const handleClickOnItem = (itemData) => {
        setProduct(itemData);
        setOpen(true);
    }

    const [state, setState] = React.useState({
        openDrawer: false,
        filterChips: [
            {name: "American"},
            {name: "Japanese"},
            {name: "Chinese"},
            {name: "Korean"},
            {name: "Thai"},
            {name: "Mexican"},
            {name: "Indian"},
            {name: "French"},
            {name: "Italian"},
            {name: "Pizza"},
            {name: "Seafood"},
            {name: "Vegetarian"},
            {name: "Vegan"},
            {name: "Gluten free"},
            {name: "Lactose free"}
        ],
        prices: [{name: 'All Prices', minPrice: 0, maxPrice: 100}, {
            name: 'Under $20',
            minPrice: 0,
            maxPrice: 20
        }, {name: '$20 - $50', minPrice: 20, maxPrice: 50}, {
            name: '$50 - $100',
            minPrice: 50,
            maxPrice: 100
        }, {name: 'Over $100', minPrice: 100, maxPrice: 100}],
        rating: '',
        isEnabled: true
    });

    const onFilterChipClick = (chip) => {
        let chipObj = _.find(state.filterChips, chip);
        chipObj['active'] = !chipObj['active'];
        setState({...state, filterChips: state.filterChips, isEnabled: false});
    }

    const applyFilters = () => {

        const currActvPrice = _.find(state.prices, 'active');
        let category = "";
        let price = "";
        let rating = "";


        // Category Filtration
        if (_.map(_.filter(state.filterChips, 'active'), 'name').length > 0) {
            category = _.map(_.filter(state.filterChips, 'active'), 'name');
        } else {
            category = _.map(state.filterChips, 'name')
        }

        // Prices Filtration
        if (currActvPrice) {
            if (currActvPrice.maxPrice === 0) {
                price = ""
            } else {
                price = {
                    "minPrice": currActvPrice ? currActvPrice.minPrice : undefined,
                    "maxPrice": currActvPrice ? currActvPrice.maxPrice : undefined
                }
            }
        } else {
            price = ""
        }

        // Setting Up Product Object
        const productObj = {
            status: "available",
            location: {
                longitude: +cookies['longitude'],
                latitude: +cookies['latitude'],
                maxDistance: 5000
            },
            category: {
                $in: category
            },
            price,
            rating: state.rating,
            type: 'user',
            filter: true
        }

        setProductsFromApi(productObj);
        setState({...state, openDrawer: false})
    }

    const updateCart = (count) => {
        const cartObj = {
            deviceId: store.getState().uuid,
            merchant: product.merchant._id,
            product: product._id,
            quantity: count,
            productName: product.name,
            productPrice: product.price
        }
        axios
            .post('user/cart', cartObj)
            .then(res => {
                const data = res.data;
                const key = product._id;
                const obj = {};
                obj[key] = count;
                if (data.success) {
                    setCartToggler(true);
                    let currPrducts = products;
                    console.log({data})
                    _.forEach(data.cartItems, productObj => {
                        const cartProdct = _.find(currPrducts, {'_id': productObj.product._id});
                        if (cartProdct) {
                            cartProdct.cartCount = productObj.quantity;
                        }
                    });

                    props.dispatch(updateCartCount(_.merge(props.cart, obj)));
                }
            })
            .catch((error) => {
            });
    }

    const cartObj = {
        deviceId: store.getState().uuid,
    }

    const setProductsFromApi = (productObj) => {

        console.log('%cProduct Object', 'background:green;font-size:18px');
        console.log(productObj);

        Axios.all([
            axios.post(`product/data?skip=0&limit=${itemCountsPerPage}`, productObj),
            axios.post('user/getcartitems', cartObj)
        ])
        .then(Axios.spread((res1, res2) => {

            const data = res1.data;
            const cartData = res2.data;

            if (data?.success) {
                setProducts(data?.products);
                if (data.products.length < itemCountsPerPage) {
                  setHasMore(false);
                }
                if(!state.isEnabled) {
                    setFilterSkip(filterSkip+data?.size)
                } else {
                    setSkip(skip+data?.size);
                }
            }

            if (cartData.success) {
                _.forEach(cartData.cartItems, productObj => {
                    const cartProdct = _.find(data.products, {'_id': productObj.product._id});
                    if (cartProdct) {
                        cartProdct.cartCount = productObj.quantity;
                        const key = productObj.product._id;
                        const obj = {};
                        obj[key] = productObj.quantity;
                        props.dispatch(updateCartCount(_.merge(props.cart, obj)));
                    }
                });
            }
        }))
        .catch((error) => {
            console.log('Error in setProductsFromApi', error);
        });

    }

    const setProductsOnScroll = (productObj) => {
        console.log('%cskip', 'background:red; font-size:18px');
        console.log(skip)
        console.log('%cfilter skip', 'background:cherry; font-size:18px');
        console.log(filterSkip)

        Axios.all(
          [
            axios
              .post(`product/data?skip=${!state.isEnabled ? filterSkip : skip}&limit=${itemCountsPerPage}`, productObj),
            axios.post('user/getcartitems', cartObj)
          ]
        )
            .then(Axios.spread((res1, res2) => {

                console.log('Response: ');
                console.log(res1);

                const data = res1.data;
                const cartData = res2.data;

                console.log(state.isEnabled)

                if (data.success) {
                    if(data?.size>0){
                        setProducts(prevState=>
                            [...prevState, ...data.products]
                        )
                        if (data.products.length < itemCountsPerPage) {
                          setHasMore(false);
                        }
                        if(!state.isEnabled) {
                            setFilterSkip(filterSkip+data?.size)
                        } else {
                            setSkip(skip+data?.size);
                        }
                    }
                }
                if (cartData.success) {
                    _.forEach(cartData.cartItems, productObj => {
                        const cartProdct = _.find(data.products, {'_id': productObj.product._id});
                        if (cartProdct) {
                            cartProdct.cartCount = productObj.quantity;
                            const key = productObj.product._id;
                            const obj = {};
                            obj[key] = productObj.quantity;
                            props.dispatch(updateCartCount(_.merge(props.cart, obj)));
                        }
                    });
                }

            }))
            .catch((error) => {
                console.log('Error in setProductsOnScroll', error);
            });
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({...state, [anchor]: open});
    };

    if (cookies['userLocation']) {
        if (location === "") {
            window.location.reload();
            return null;
        } else if(!_.isEmpty(products) || !state.isEnabled) {
          return (
              <>
              <div className={`cart-toggler ${!cartToggler ? 'display-none' : ''}`}>
                  <h6>Items Added</h6>
                  <button className="cart-toggler-button" onClick={(e) => history.push('/cart-summary')}>Go to Cart</button>
              </div>

              <Container component='section' className='mb-4'>
                  <div className='d-flex justify-content-between align-items-center my-4 restaurants-title-holder'>
                      <Typography variant="h6" className='restaurants-title'>
                          All items nearby
                      </Typography>

                      <Typography variant="body2">
                          Filters
                          <span className='d-inline'>
                              <IconButton
                                  component="span"
                                  onClick={toggleDrawer('openDrawer', true)}
                              >
                                  <TuneIcon/>
                              </IconButton>
                              </span>
                      </Typography>

                  </div>
                  <div className='restaurant-main'>
                    {
                      !_.isEmpty(products)
                        ? <InfiniteScroll
                            dataLength={products?.length} //This is important field to render the next data
                            next={()=>setProductsOnScroll(noFilterProducts())}
                            hasMore={hasMore}
                            endMessage={
                              <Typography variant="h6" className='restaurants-title mt-4 text-center'>
                                You have seen it all
                              </Typography>
                            }
                          >
                            <section className={`${products?.length > 3 ? '' : 'justify-items-start'} cards`}>
                                {
                                    products && products?.length>0 && products?.map((product, i) =>

                                    <RestaurantCard key={i} product={product}
                                    showItemDialog={() => handleClickOnItem(product)} />
                                )}
                            </section>
                          </InfiniteScroll>
                        : <div className='d-flex flex-column justify-content-center h-100vh text-center center'>
                            <div>
                              <img src={require('../../assets/restaurant.png')} alt="No Items Found" />
                              <Typography variant="h6" className='bhooky-bold'>
                                  NO ITEMS FOUND
                              </Typography>
                            </div>
                          </div>
                    }
                  </div>
              </Container>

              <Drawer anchor='right' open={state.openDrawer} onClose={toggleDrawer('openDrawer', false)}>
                  <div className='d-flex' style={{minHeight: 65}}>
                      <CloseButton
                          component="button"
                          className='px-3 close-drawer'
                          onClick={toggleDrawer('openDrawer', false)}
                      />
                      <Typography variant="h5" className='p-3'>
                          Filters
                      </Typography>
                      <Button variant='contained' color="primary"
                              className='ml-auto mr-2 align-self-center text-capitalize' onClick={applyFilters}
                              disabled={state.isEnabled}>
                          Apply
                      </Button>
                      <Button color="secondary" className='mr-2 align-self-center text-capitalize'
                              onClick={(event) => {
                                  state.filterChips.forEach(chip => {
                                      if (chip.active) {
                                          chip.active = false;
                                      }
                                  });
                                  state.prices.forEach(chip => {
                                      if (chip.active) {
                                          chip.active = false;
                                      }
                                  });
                                  setState({
                                      ...state,
                                      rating: '',
                                      prices: state.prices,
                                      filterChips: state.filterChips,
                                      isEnabled: true,
                                      openDrawer: false
                                  });
                                  setProductsFromApi(noFilterProducts())
                              }}>
                          Clear
                      </Button>
                  </div>
                  <Divider/>
                  <div
                      className='m-4 filters'
                      role="filters"
                  >
                      {state.filterChips.map((chip, i) =>
                          <Chip
                              key={i}
                              size="medium"
                              className='p-1 m-2'
                              label={chip.name}
                              color={chip.active ? 'primary' : ''}
                              clickable
                              onClick={event => onFilterChipClick(chip)}
                          />
                      )}

                      <Divider className='mx-auto mt-3 divider-filters'/>
                      <Typography variant="h6" className='py-3 pl-2 filter-title'>
                          Price
                      </Typography>

                      {state.prices.map((price, i) =>
                          <Chip
                              key={i}
                              size="medium"
                              className='p-1 m-2'
                              label={price.name}
                              color={price.active ? 'primary' : ''}
                              clickable
                              onClick={event => {
                                  const currActv = _.find(state.prices, 'active')
                                  if (currActv) {
                                      currActv.active = false;
                                  }
                                  price['active'] = !price['active'];
                                  setState({...state, prices: state.prices, isEnabled: false});
                              }}
                          />
                      )}
                      <Divider className='mx-auto mt-3 divider-filters'/>
                      <Typography variant="h6" className='py-3 pl-2 filter-title'>
                          Rating
                      </Typography>
                      <PrettoSlider className='mx-4 w-50' valueLabelDisplay="auto" aria-label="rating slider"
                                  defaultValue={0} marks={ratingMarks} min={1} value={state.rating}
                                  max={5}
                                  onChange={(event, v) => setState({...state, rating: v, isEnabled: false})}/>

                  </div>
              </Drawer>
              <Dialog
                  open={open}
                  onClose={handleClose}
                  fullWidth={true}
                  maxWidth={'sm'}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
              >
                  <DialogActions className='justify-content-between item-dialog'>
                      <DialogTitle id="scroll-dialog-title">
                          <Typography className='bhooky-semibold'>
                              {product?.name}
                          </Typography>
                      </DialogTitle>
                      <CloseButton onClick={handleClose} className='my-4 mr-2'/>
                  </DialogActions>
                  <DialogContent
                      dividers={true} className='m-0 p-0'>
                      <Carousel interval={2000} style={{textAlign:"center"}}>

                          {
                              product?.image?.map((img, i) => (
                                  <Carousel.Item key={i}>
                                      <img
                                          src={img ? img : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTI9wAAiM4eBLesNjgpOTn-_27WXIb6kEevJQ&usqp=CAU"}
                                          alt={""}
                                          style={{
                                              "objectFit": "cover",
                                              height: "200px",
                                              width: "100%",
                                          }}
                                      />
                                  </Carousel.Item>
                              ))
                          }

                      </Carousel>
                      <DialogContentText
                          id="scroll-dialog-description"
                          className='bhooky-regular m-3'
                      >
                          {product?.description}
                      </DialogContentText>
                      <div className='d-flex dialog-addtocart justify-content-between'>
                          <button color="primary"
                                  className='search-result-details-link-more-from-merchant'
                                  onClick={() => history.push('/' + _.replace(product.merchant.name.toLowerCase(), ' ', '-') + '/' + product.merchant._id + '/order')}>
                              More from this merchant
                          </button>
                          <div
                              className={`${isResponsive ? 'flex-column-reverse' : 'flex-row align-items-center'} d-flex `}>
                              <AddorRemoveButtons
                              size='extraSmall'
                              className='dialog-add'
                              handleCart={updateCart}
                              cartCount={product.cartCount}
                              totalQuantity={product.quantity}/>
                              <Typography
                                variant="body2"
                                component="p"
                                className='text-light bhooky-semibold pl-3 pr-2 text-center my-auto dialog-item-price'>
                                  ${product?.price}
                                  <ShoppingCartIcon className="cursor ml-3 text-white" onClick={(e) => history.push('/cart-summary') } />
                              </Typography>
                          </div>
                      </div>
                  </DialogContent>

              </Dialog>
          </>
          );
        } else {
          return (
              <div className='d-flex flex-column justify-content-center h-100vh text-center center'>
                  <div>
                      <img src={require('../../assets/restaurant.png')} alt="No Items Found" />
                      <Typography variant="h6" className='bhooky-bold'>
                          NO ITEMS FOUND
                      </Typography>
                  </div>
              </div>
          );
        }
    }
    else {
        history.push('/');
        return null;
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    };
}

export default connect(mapStateToProps)(BhookyUserHome);
