import React, {useState} from 'react';
import './CookiesAleart.scss'
import {useHistory} from "react-router-dom";

export default function () {
    const [style, setStyle] = useState(localStorage.getItem('isCookiesEnabled') ? {display: "none"} : {})
    const history = useHistory();
    const setCookieAppear = () => {
        setStyle({display: "none"})
        localStorage.setItem('isCookiesEnabled', true);
    }
    return (
        <div className="CookiesAleart" style={style}>
            <div className="bottom">
                By using this site you agree to Vuacifood's use of cookies to give you a personalised experience.
                Please read the 
                
                <span className={"cookie-policy-link"} onClick={() => {
                history.push('/terms-and-conditions')
                }}>
                    Cookie Policy
                </span>
                
                for more information.
                <span className="close-cookies-aleart" onClick={(e) => setCookieAppear()}>
                    &#10005;
                </span>
            </div>
        </div>
    );
}
