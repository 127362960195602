import instance from "../../api/axios";

const headers = {
    'auth-type': 'user',
    'Authorization': 'Bearer ' + window.localStorage.accessToken,
    'Content-Type': 'application/json',
};


export function onChange(e) {
    this.setState({
        [e.target.name]: e.target.value,
    })
}

export function onClick(changingElement) {
    this.setState({
        changingElement,
        otpRequestSent: false
    })
}

export function fetchUserDetails() {
    let body ={};

    return instance.post('user/info', body)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            // console.log({error})
            return error;
        })
}

export function getOTPForChangeMobileNumber(mobile, userName, user_id) {
    let body = JSON.stringify({
        "verifyMobile": true,
        mobile,
        userName,
        user_id,
        type: "user",
    })
    console.log({body})
    console.log({headers})
    return instance.post('message/mobile', body, {headers}).then(response => {
        return response.data;
    })
        .catch(error => {
            return error;
        })
}

export function verifyOTPForChangeMobileNumber(_id, name, mobile, otp, token) {
    let body = JSON.stringify({
        userDetails: {
            _id,
            name,
            mobile,
            mobileUpdate: true,
            otp,
            token,
        }
    })
    console.log({body})
    return instance.put('user/update', body, {headers})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error;
        })
}

export function changeEmail(_id, email) {
    let body = JSON.stringify({
        userDetails:{
            _id,
            email,
        }
    })
    console.log({body})
    return instance.put('user/update', body, {headers})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error;
        })
}

export function changePassword(_id, oldPassword, newPassword) {
    let body = JSON.stringify({
        userDetails:{
            _id,
            oldPassword,
            newPassword
        }
    })
    console.log({body})
    return instance.put('user/update', body, {headers})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return error;
        })
}

export function changeProfilePicture(_id, profilePic){
    let config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    let formData = new FormData();
    formData.append("file", profilePic);
    formData.append("name", _id);
    formData.append("folder", "user");

    return instance.post('aws/upload', formData, config)
        .then(response=>{
            return response.data;
        })
        .catch(error=>{
            console.log({error})
            return error;
        })
}

export function updateProfilePic(_id, profilePic){
    let body = JSON.stringify({
        userDetails:{
            _id,
            profilePic,
        }
    })
    return instance.put('user/update', body, {headers})
        .then(response=>{
            return response.data;
        })
        .catch(error=>{
            return error;
        })
}

export function deleteCard (payment_method_id){
    let body = {
        payment_method_id
    }

    return instance.post('/user/deletecard', body)
        .then(response=>{
            return response.data;
        })
        .catch(error=>{
            return error;
        })
}