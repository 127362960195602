import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useForm } from "../../common/components/Form/useForm";
import "./BecomeaPartner.scss";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { Collapse, Container } from "@material-ui/core";
import axios from "../../api/axios";
import MuiPhoneNumber from "material-ui-phone-number";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { BhookyConstants } from "../../common/AppConstants";
import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import {
  getOtp,
  verifyOTP,
} from "../../common/components/SignUpDialog/functions";
import { validateEmail } from "../../common/validators";
import instance from "../../api/axios";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2, 2),
  },
}));
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
};

function BecomeaPartner() {
  const [address, setAddress] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [alertInfo, setAlertInfo] = React.useState({});
  const [messageSuccess, setMerchantSuccess] = useState(false);
  const [aleartMessage, setAleartMessage] = useState("");
  const [businessNumber, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [otpRequestSent, setOtpRequestSent] = React.useState(false);
  const [emailOTPRequestSent, setEmailOTPRequestSent] = React.useState(false);
  const [otpVerified, setOtpVerified] = React.useState(false);
  const [emailOTPVerified, setEmailOTPVerified] = useState(false);
  const [otp, setOTP] = useState("");
  const [emailOTP, setEmailOTP] = useState("");
  const [emailToken, setEmailToken] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [emailError, setEmailError] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [userCaptcha, setUserCaptcha] = useState("");
  const [captchaError, setCaptchaError] = useState("");
  const initialFValues = {
    name: "",
    website: "",
    yourName: "",
    yourNumber: "",
  };
  const onChangeUserCaptcha = (userCaptcha) => {
    setUserCaptcha(userCaptcha);
    setCaptchaError("");
  };

  const getOTPForMerchantRegister = (mobileNumber) => {
    console.log({ mobileNumber });
    if (mobileNumber.length > 0) {
      let mobile = mobileNumber.replace("(", "");
      mobile = mobile.replace(")", "");
      mobile = mobile.replace("-", "");
      let mobileArray = mobile.split(" ");
      mobileArray[2] === undefined
        ? (mobile = mobileArray[1])
        : (mobile = mobileArray[1] + mobileArray[2]);
      let countryCode = mobileArray[0];
      console.log(countryCode, mobile);

      if (mobile && mobile.length < 10) {
        setErrorMsg("Invalid Mobile Number...");
      } else {
        getOtp(mobile, countryCode, "merchant")
          .then((data) => {
            console.log(data);
            if (data.success) {
              setOtpRequestSent(true);
            } else {
              setOtpRequestSent(false);
              setErrorMsg(data.message);
            }
          })
          .catch((error) => {
            setOtpRequestSent(false);
            setErrorMsg("Check your Mobile Number...");
          });
      }
    } else {
      setErrorMsg("Invalid Mobile Number...");
    }
  };

  const verifyOtpForSignUp = (mobileNumber, otp) => {
    let mobile = mobileNumber.replace("(", "");
    mobile = mobile.replace(")", "");
    mobile = mobile.replace("-", "");
    let mobileArray = mobile.split(" ");
    mobileArray[2] === undefined
      ? (mobile = mobileArray[1])
      : (mobile = mobileArray[1] + mobileArray[2]);
    let countryCode = mobileArray[0];
    console.log(countryCode, mobile, otp);

    if (mobile.length < 10) {
      setErrorMsg("Invalid Mobile Number...");
    } else if (otp.length < 6) {
      setErrorMsg("Invalid OTP...");
    } else {
      verifyOTP(mobile, otp, "merchant")
        .then((data) => {
          console.log(data);
          if (data.status) {
            setOtpVerified(true);
          } else {
            setErrorMsg(data.message);
            setOtpVerified(false);
          }
        })
        .catch((error) => {
          setOtpVerified(false);
        });
    }
  };

  const getOTPEmailForMerchantRegister = (email) => {
    console.log(email);
    if (validateEmail(email)) {
      instance
        .post("message/verify/email", { email })
        .then((response) => {
          let { data } = response;
          if (data.success) {
            console.log(data.message.message);
            if (data.message.message) {
              setEmailOTPRequestSent(false);
              setEmailError(data.message.message);
            } else {
              setEmailOTPRequestSent(true);
              setEmailToken(data.message);
            }
          } else {
            setEmailOTPRequestSent(false);
            setEmailError("Check the E-mail entered");
          }
        })
        .catch((error) => {
          console.log({ error });
        });
    } else {
      setEmailError("Invalid Email...");
    }
  };

  const verifyEmailOtpForSignUp = (email, otp) => {
    if (validateEmail(email)) {
      if (otp.length > 0) {
        let body = {
          otp: otp,
          token: emailToken,
          email: email,
        };
        instance
          .post("user/verifyotp", body)
          .then((response) => {
            let { data } = response;
            if (data.status) {
              setEmailOTPVerified(true);
            } else {
              setEmailError(data.message);
              setEmailOTPVerified(false);
            }
          })
          .catch((error) => {
            setEmailOTPVerified(false);
            setEmailError("Try Again...");
          });
      } else {
        setEmailError("Invalid OTP...");
      }
    } else {
      setEmailError("Invalid Email...");
    }
  };

  const onChangeMobile = (mobile) => {
    setMobile(mobile);
    setErrorMsg("");
  };

  const onChangeEmail = (email) => {
    setEmail(email);
    setEmailError("");
  };

  const onChangeOTP = (otp) => {
    setOTP(otp);
    setErrorMsg("");
  };

  const onEmailOTPChange = (otp) => {
    setEmailOTP(otp);
    setEmailError("");
  };

  const classes = useStyles();

  const getStarted = (event) => {
    event.preventDefault();
    if (userCaptcha !== captcha) {
      setCaptchaError("Invalid Captcha...");
    } else {
      geocodeByAddress(address.label).then((results) => {
        const locationObj = results[0];
        const cityName = _.find(locationObj.address_components, (l) =>
          _.includes(l.types, "locality")
        )?.long_name;
        const stateName = _.find(locationObj.address_components, (l) =>
          _.includes(l.types, "administrative_area_level_1")
        )?.long_name;
        let countryCode = businessNumber.substr(0, businessNumber.indexOf(" "));

        let business = businessNumber.split(" ");
        business.shift();

        let contactPersonMobile = values.yourNumber.split(" ");
        contactPersonMobile.shift();

        let isEmail = email.match(/[A-Za-z0-9]+@+[a-z]+[.a-z]/);

        let mobile = _.join(business, "").replace(/[^A-Z0-9]/gi, "");
        contactPersonMobile = _.join(contactPersonMobile, "").replace(
          /[^A-Z0-9]/gi,
          ""
        );

        if (mobile.length < 10 || contactPersonMobile.length < 10) {
          window.scrollTo({ behavior: "smooth", top: 0 });
          setOpen(true);
          setAlertInfo({
            severity: "error",
            msg: "Invalid Mobile Number(s)",
          });
          setTimeout(() => {
            setOpen(false);
          }, 2000);
        } else {
          const merchnatObj = {
            merchantDetails: {
              name: values.name,
              email: email,
              mobile,
              countryCode: countryCode,
              websiteLink: values.website,
              status: "pending",
              contactPersonName: values.yourName,
              contactPersonMobile,
              location: {
                type: "Point",
                coordinates: [
                  locationObj.geometry.location.lng(),
                  locationObj.geometry.location.lat(),
                ],
              },
              address: {
                doorNo: "",
                landMark: "",
                name: address.label,
                pincode: _.find(locationObj.address_components, (l) =>
                  _.includes(l.types, "postal_code")
                )?.long_name,
              },
              city: cityName,
              state: stateName,
            },
          };
          console.log({ merchnatObj });
          // window.scrollTo({behavior: 'smooth', top: 0});
          axios
            .post(`/merchant/create`, merchnatObj)
            .then((res) => {
              const data = res.data;
              window.scrollTo({ behavior: "smooth", top: 0 });
              if (data.success) {
                console.log(data);
                setOpen(true);
                setMerchantSuccess(true);
                setAleartMessage(data.message);
                // setAlertInfo({severity: "success", msg: data.message})
                resetForm();
                setAddress(null);
              } else {
                setOpen(true);
                setAlertInfo({ severity: "error", msg: data.message });
              }
              setTimeout(() => {
                setOpen(false);
              }, 2000);
            })
            .catch((error) => {
              setOpen(true);
              setAlertInfo({
                severity: "error",
                msg: "Something went wrong",
              });
              setTimeout(() => {
                setOpen(false);
              }, 2000);
            });
        }
      });
    }
  };

  const reloadPage = (e) => {
    window.location.reload();
  };

  const onTemp = (e) => {
    e.preventDefault();
    e.propertyIsEnumerable();
    return false;
  };

  const makeCaptcha = () => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setCaptcha(result);
  };

  useEffect(() => {
    makeCaptcha();
  }, []);

  const { values, setValues, resetForm, handleInputChange } = useForm(
    initialFValues,
    true
  );

  return (
    <Container maxWidth="xl" className="p-0 bg-image">
      <Grid container className="grid-overlay">
        <Grid item sm className="m-3">
          <div className="contact-form">
            {messageSuccess ? (
              <>
                <Typography>{aleartMessage} </Typography>
                <button
                  className="btn-primary-full"
                  onClick={(e) => reloadPage(e)}
                >
                  Okay
                </button>
              </>
            ) : (
              <>
                <Typography
                  component="h4"
                  className="align-self-start"
                  variant="h4"
                >
                  Get Started
                </Typography>
                <Collapse in={open} className="pt-3">
                  <Alert severity={alertInfo.severity}>{alertInfo.msg}</Alert>
                </Collapse>
                <form className={classes.form} onSubmit={getStarted} noValidate>
                  <Typography
                    component="h6"
                    className="align-self-start"
                    variant="h6"
                  >
                    Business Information
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="text"
                    id="name"
                    value={values.name}
                    label="Name"
                    name="name"
                    onChange={handleInputChange}
                  />
                  <GooglePlacesAutocomplete
                    apiKey={BhookyConstants.apiKey}
                    label="Address"
                    name="address"
                    selectProps={{
                      label: "Address",
                      name: "address",
                      placeholder: "Choose Address",
                      value: address,
                      onChange: setAddress,
                    }}
                    autocompletionRequest={{
                      bounds: [
                        { lat: 50, lng: 50 },
                        { lat: 100, lng: 100 },
                      ],
                      componentRestrictions: {
                        country: ["in", "us"],
                      },
                    }}
                  />
                  <MuiPhoneNumber
                    defaultCountry="us"
                    onlyCountries={["in", "us"]}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="yourNumber"
                    value={values.yourNumber}
                    label="Business Phone Number"
                    name="yourNumber"
                    autoComplete="mobile"
                    countryCodeEditable={false}
                    onChange={(event) =>
                      handleInputChange({
                        target: {
                          name: "yourNumber",
                          value: event,
                        },
                      })
                    }
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="text"
                    id="website"
                    value={values.website}
                    label="Website"
                    name="website"
                    onChange={handleInputChange}
                  />
                  {/* <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                type="text"
                id="licenseNumber"
                value={values.licenseNumber}
                label="License Number"
                name="licenseNumber"
                onChange={handleInputChange}
              /> */}
                  <Typography
                    component="h6"
                    className="align-self-start mt-1"
                    variant="h6"
                  >
                    Your Information
                  </Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    type="text"
                    id="yourName"
                    value={values.yourName}
                    label="Name"
                    name="yourName"
                    onChange={handleInputChange}
                  />

                  <div className="d-flex justify-content-between">
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type="text"
                      id="email"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={emailOTPVerified}
                      value={email}
                      label="Email *"
                      name="email"
                      onChange={(e) => onChangeEmail(e.target.value)}
                    />
                    {emailOTPVerified ? null : (
                      <Button
                        type="button"
                        onClick={(e) => getOTPEmailForMerchantRegister(email)}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        VERIFY
                      </Button>
                    )}
                  </div>
                  {!emailOTPVerified ? (
                    <div>
                      <small className="text-info">
                        Please verify your email
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                  <p>{emailError}</p>
                  {emailOTPRequestSent && !emailOTPVerified ? (
                    <div className="d-flex justify-content-between">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="text"
                        id="emailOTP"
                        value={emailOTP}
                        label="OTP"
                        name="emailOTP"
                        autoComplete="emailOTP"
                        onChange={(event) =>
                          onEmailOTPChange(event.target.value)
                        }
                        autoFocus
                        className="mr-3"
                      />
                      <Button
                        type="button"
                        onClick={(e) =>
                          verifyEmailOtpForSignUp(email, emailOTP)
                        }
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        VERIFY OTP
                      </Button>
                    </div>
                  ) : null}

                  <div className="d-flex justify-content-between">
                    <MuiPhoneNumber
                      defaultCountry="us"
                      onlyCountries={["in", "us"]}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="businessNumber"
                      disabled={otpVerified}
                      value={values.mobile}
                      placeholder="Phone Number"
                      label="Mobile Number"
                      name="businessNumber"
                      autoComplete="mobile"
                      countryCodeEditable={false}
                      // onChange={event => handleInputChange({target: {name: 'mobile', value: event}})}
                      onChange={(businessNumber) =>
                        onChangeMobile(businessNumber)
                      }
                    />

                    {otpVerified ? null : (
                      <Button
                        type="button"
                        onClick={(e) =>
                          getOTPForMerchantRegister(businessNumber)
                        }
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        VERIFY
                      </Button>
                    )}
                  </div>
                  {!otpVerified ? (
                    <div>
                      <small className="text-info">
                        Please verify your phone number
                      </small>
                    </div>
                  ) : (
                    ""
                  )}
                  <p>{errorMsg}</p>
                  {otpRequestSent && !otpVerified ? (
                    <div className="d-flex justify-content-between">
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="text"
                        id="otp"
                        value={otp}
                        label="OTP"
                        name="otp"
                        autoComplete="otp"
                        onChange={(event) => onChangeOTP(event.target.value)}
                        autoFocus
                        className="mr-3"
                      />
                      <Button
                        type="button"
                        onClick={(e) => verifyOtpForSignUp(businessNumber, otp)}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        VERIFY OTP
                      </Button>
                    </div>
                  ) : null}

                  <div className="d-flex justify-content-between">
                    <Typography
                      component="h6"
                      className="align-self-start mt-4 mr-2"
                      variant="h6"
                      onSelectCapture={(e) => onTemp(e)}
                      onPaste={(e) => onTemp(e)}
                      onCopy={(e) => onTemp(e)}
                      onCut={(e) => onTemp(e)}
                      onDrag={(e) => onTemp(e)}
                      onDrop={(e) => onTemp(e)}
                    >
                      {captcha}
                    </Typography>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type="text"
                      id="captcha"
                      value={userCaptcha}
                      label="Captcha"
                      name="captcha"
                      onChange={(e) => onChangeUserCaptcha(e.target.value)}
                      onSelectCapture={(e) => onTemp(e)}
                      onPaste={(e) => onTemp(e)}
                      onCopy={(e) => onTemp(e)}
                      onCut={(e) => onTemp(e)}
                      onDrag={(e) => onTemp(e)}
                      onDrop={(e) => onTemp(e)}
                    />
                  </div>
                  <p>{captchaError}</p>
                  {otpVerified && emailOTPVerified ? (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={
                        _.values(values).some((v) => v === "") ||
                        _.isEmpty(address)
                      }
                    >
                      Get Started
                    </Button>
                  ) : null}
                </form>
                <Typography variant="caption" display="block" gutterBottom>
                  By registering, you agree to the
                  <Link
                    to="/terms-and-conditions"
                    target="blank"
                    className="bhooky-semibold"
                  >
                    Vuacifood Terms and Conditions{" "}
                  </Link>
                  and{" "}
                  <Link
                    to="/privacy-statement"
                    target="blank"
                    className="bhooky-semibold"
                  >
                    Privacy Statement
                  </Link>
                </Typography>
              </>
            )}
          </div>
        </Grid>
        <Grid
          item
          sm
          alignItems="flex-start"
          className="d-flex grid-timeline align-items-center"
        >
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="text-light">
                Sell bulk directly to consumer
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="text-light">
                {" "}
                Bring in income by selling whatever is left directly to the
                consumer before business close
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className="text-light">
                {" "}
                Reduce waste
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot />
              </TimelineSeparator>
              <TimelineContent className="text-light">
                Convert unused inventory into cash
              </TimelineContent>
            </TimelineItem>
          </Timeline>
          {/* <Typography component="h6" className='align-self-start' variant="h6">
          Call Us
          </Typography> */}
          {/* <Typography variant="h5" className='align-self-center mt-1 text-light p-4 bold' gutterBottom>
            Sell bulk directly to consumer<br /><br />
            Bring in income by selling
            whatever is left directly to the
            consumer before business close<br /><br />
            Reduce waste<br /><br />
            Convert unused inventory into
            cash
    </Typography> */}
        </Grid>
      </Grid>
      <CssBaseline />
    </Container>
  );
}

BecomeaPartner.propTypes = {};

BecomeaPartner.defaultProps = {};

export default BecomeaPartner;
