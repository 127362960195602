import React from 'react';
import './PrivacyStatement.scss';
//
// const file = 'http://example.com/image.png'
// const type = 'png'
const PrivacyStatement = () => (
    // <pre className="PrivacyStatement">
    //   {/* <FileViewer
    //     fileType={type}
    //     filePath={file} /> */}

    // </pre>
    <div className='PrivacyStatement'>
        <div className="c38"><p className="c34"><span className="c2">PRIVACY NOTICE</span></p>
            <p className="c7"><span className="c25 c4 c35">Last updated September 27, 2020</span></p>
            <p className="c6"><span className="c2"></span></p>
            <p className="c7"><span className="c5 c4">Thank you for choosing to be part of our community at Mysmartcad LLC, doing business as Vuacifood (&ldquo;Vuacifood&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;, or &ldquo;our&rdquo;). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at contact@mysmartcad.com.</span>
            </p>
            <p className="c7"><span className="c11 c4">When you visit our website </span><span className="c29 c32 c4"><a
                className="c15"
                href="https://www.google.com/url?q=http://www.bhooky.com/&amp;sa=D&amp;ust=1606060439066000&amp;usg=AOvVaw1G4YT_Iy3-lEqsFvOejcf2">http://www.vuacifood.com</a></span><span
                className="c5 c4">&nbsp;(the &quot;Website&quot;), use our mobile application, as the case may be (the &quot;App&quot;) and more generally, use any of our services (the &quot;Services&quot;, which include the Website and App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</span>
            </p>
            <p className="c7"><span className="c5 c4">This privacy notice applies to all information collected through our Services (which, as described above, includes our Website and App), as well as any related services, sales, marketing or events.</span>
            </p>
            <p className="c7"><span className="c5 c4">Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</span>
            </p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c7"><span className="c2">TABLE OF CONTENTS</span></p>
            <p className="c7"><span className="c4">1. WHAT INFORMATION DO WE COLLECT?</span></p>
            <p className="c7"><span className="c0 c25">2. HOW DO WE USE YOUR INFORMATION?</span></p>
            <p className="c7"><span className="c0">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></p>
            <p className="c7"><span className="c29 c32 c4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
            </p>
            <p className="c7"><span className="c0 c25">5. DO WE USE GOOGLE MAPS?</span></p>
            <p className="c7"><span className="c0 c25">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></p>
            <p className="c7"><span className="c0 c25">7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></p>
            <p className="c7"><span className="c0 c25">8. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span></p>
            <p className="c7"><span className="c0"><a className="c15"
                                                      href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/529979/privacy-policy%23inforetain&amp;sa=D&amp;ust=1606060439068000&amp;usg=AOvVaw1_Av2lt3zf48Gvf9gq17dW">9. HOW LONG DO WE KEEP YOUR INFORMATION?</a></span>
            </p>
            <p className="c7"><span className="c0 c25">10. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></p>
            <p className="c7"><span className="c0 c25">11. DO WE COLLECT INFORMATION FROM MINORS?</span></p>
            <p className="c7"><span className="c0 c25">12. WHAT ARE YOUR PRIVACY RIGHTS?</span></p>
            <p className="c7"><span className="c0 c25">13. CONTROLS FOR DO-NOT-TRACK FEATURES</span></p>
            <p className="c7"><span className="c0"><a className="c15"
                                                      href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/529979/privacy-policy%23caresidents&amp;sa=D&amp;ust=1606060439069000&amp;usg=AOvVaw2hYOA1RMUW9LOCEiU86gEM">14. DO CALIFORNIA RESI</a></span><span
                className="c4">D</span><span className="c0"><a className="c15"
                                                               href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/529979/privacy-policy%23caresidents&amp;sa=D&amp;ust=1606060439069000&amp;usg=AOvVaw2hYOA1RMUW9LOCEiU86gEM">ENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></span>
            </p>
            <p className="c7"><span className="c0 c25">15. DO WE MAKE UPDATES TO THIS NOTICE?</span></p>
            <p className="c7"><span className="c0 c25">16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c7"><span className="c2">1. WHAT INFORMATION DO WE COLLECT?</span></p>
            <p className="c7 c13"><span className="c2"></span></p>
            <p className="c7"><span className="c2">Personal information you disclose to us</span></p>
            <p className="c7"><span
                className="c12 c19 c4">In Short: &nbsp;We collect information that you provide to us.</span></p>
            <p className="c7"><span className="c2">We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.</span>
            </p>
            <p className="c7"><span className="c2">The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect may include the following:</span>
            </p>
            <p className="c7"><span className="c2">Personal Information Provided by You. We collect names; phone numbers; email addresses; mailing addresses; usernames; passwords; contact preferences; contact or authentication data; billing addresses; debit/credit card numbers; and other similar information.</span>
            </p>
            <p className="c7"><span className="c2">Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: https://stripe.com/gb/privacy.</span>
            </p>
            <p className="c7"><span className="c2">Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the information described in the section called &quot;HOW DO WE HANDLE YOUR SOCIAL LOGINS&quot; below.</span>
            </p>
            <p className="c1"><span className="c2">All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</span>
            </p>
            <p className="c7 c13"><span className="c2"></span></p>
            <p className="c7"><span className="c2">Information automatically collected</span></p>
            <p className="c1"><span className="c12 c19 c4">In Short: &nbsp;Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device characteristics &mdash; is collected automatically when you visit our Services.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">Like many businesses, we also collect information through cookies and similar technologies. &nbsp;</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">The information we collect includes:</span></p>
            <ul className="c16 lst-kix_list_3-0 start">
                <li className="c20 c10"><span className="c11 c4 c17">Log and Usage Data.</span><span
                    className="c11 c4">&nbsp;Log and usage data is service-related, diagnostic usage and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called &#39;crash dumps&#39;) and hardware settings).</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_9-0 start">
                <li className="c1 c10"><span className="c11 c4 c17">Device Data. </span><span className="c11 c4">We collect device data such as information about your computer, phone, tablet or other device you use to access the </span><span
                    className="c4">Services</span><span className="c11 c4">. Depending on the device used, this device data may include information such as your IP address (or proxy server), device application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system configuration information.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_1-0 start">
                <li className="c21 c10"><span className="c11 c4 c17">Location Data.</span><span
                    className="c11 c4">&nbsp;We collect information data such as information about your device&#39;s location, which can be either precise or imprecise. How much information we collect depends on the type of settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Locations settings on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.</span>
                </li>
            </ul>
            <p className="c7 c13"><span className="c2"></span></p>
            <p className="c7"><span className="c2">Information collected through our App</span></p>
            <p className="c7"><span className="c12 c19 c4">In Short: &nbsp;We collect information regarding your geo-location, mobile device, push notifications, when you use our App.</span>
            </p>
            <p className="c1"><span
                className="c5 c4">If you use our App, we also collect the following information:</span></p>
            <ul className="c16 lst-kix_list_6-0 start">
                <li className="c20 c10"><span className="c11 c4 c17">Geo-Location Information.</span><span
                    className="c11 c4">&nbsp;We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our App, to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device&#39;s settings.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_8-0 start">
                <li className="c1 c10"><span className="c11 c4 c17">Mobile Device Access. </span><span
                    className="c11 c4">We may request access or permission to certain features from your mobile device, including your mobile device&#39;s camera, contacts, microphone, sms messages, and other features. If you wish to change our access or permissions, you may do so in your device&#39;s settings.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_11-0 start">
                <li className="c1 c10"><span className="c11 c4 c17">Mobile Device Data.</span><span
                    className="c11 c4">&nbsp;We automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also collect information about the phone network associated with your mobile device, your mobile device&#39;s operating system or platform, the type of mobile device you use, your mobile device&#39;s unique device ID and information about the features of our App you accessed.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_4-0 start">
                <li className="c21 c10"><span className="c11 c4 c17">Push Notifications. </span><span
                    className="c11 c4">We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device&#39;s settings.</span>
                </li>
            </ul>
            <p className="c1"><span className="c2">The information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.</span>
            </p>
            <p className="c6"><span className="c2"></span></p>
            <p className="c7"><span className="c2">2. HOW DO WE USE YOUR INFORMATION?</span></p>
            <p className="c7"><span className="c12 c4 c19">In Short: &nbsp;We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</span>
            </p>
            <p className="c7"><span className="c2">We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</span>
            </p>
            <p className="c7"><span className="c2">We use the information we collect or receive:</span></p>
            <ul className="c16 lst-kix_list_2-0 start">
                <li className="c20 c10"><span className="c4">To facilitate account creation and logon process. If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract. See the section below headed &quot;</span><span
                    className="c0"><a className="c15"
                                      href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/529979/privacy-policy%23sociallogins&amp;sa=D&amp;ust=1606060439076000&amp;usg=AOvVaw375tUv6RrcpoaFMVQEVDCK">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a></span><span
                    className="c2">&quot; for further information.<br/></span></li>
                <li className="c1 c10"><span className="c11 c4">To post testimonials. We post testimonials on our Services that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the consent of the testimonial. If you wish to update, or delete your testimonial, please contact us at mysmartcad.com/privacy and be sure to include your name, testimonial location, and contact information.<br/></span>
                </li>
                <li className="c1 c10"><span className="c11 c4">Request feedback. We may use your information to request feedback and to contact you about your use of our Services.<br/></span>
                </li>
                <li className="c1 c10"><span className="c11 c4">To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user&#39;s consent.<br/></span>
                </li>
                <li className="c1 c10"><span className="c11 c4">To manage user accounts. We may use your information for the purposes of managing our account and keeping it in working order.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_13-0 start">
                <li className="c1 c10"><span className="c2">To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.<br/></span>
                </li>
                <li className="c1 c10"><span className="c11 c4">To protect our Services. We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).<br/></span>
                </li>
                <li className="c1 c10"><span className="c11 c4">To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.<br/></span>
                </li>
                <li className="c1 c10"><span className="c11 c4">To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_20-0 start">
                <li className="c1 c10"><span className="c11 c4">Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.<br/></span>
                </li>
                <li className="c1 c10"><span className="c11 c4">Administer prize draws and competitions. We may use your information to administer prize draws and competitions when you elect to participate in our competitions.<br/></span>
                </li>
                <li className="c1 c10"><span className="c11 c4">To deliver and facilitate delivery of services to the user. We may use your information to provide you with the requested service.<br/></span>
                </li>
                <li className="c1 c10"><span className="c11 c4">To respond to user inquiries/offer support to users. We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_14-0 start">
                <li className="c1 c10"><span className="c11 c4">To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. For example, when expressing an interest in obtaining information about us or our Services, subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can opt-out of our marketing emails at any time (see the &quot;</span><span
                    className="c0"><a className="c15"
                                      href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/529979/privacy-policy%23privacyrights&amp;sa=D&amp;ust=1606060439079000&amp;usg=AOvVaw0EM9G4qxFrahavduJlfwYq">WHAT ARE YOUR PRIVACY RIGHTS</a></span><span
                    className="c11 c4">&quot; below).<br/></span></li>
                <li className="c21 c10"><span className="c11 c4">Deliver targeted advertising to you. We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</span>
                </li>
            </ul>
            <p className="c7 c13"><span className="c2"></span></p>
            <p className="c7"><span className="c2">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c4 c11">&nbsp;</span><span
                className="c12 c11 c4">We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</span>
            </p>
            <p className="c1"><span
                className="c5 c4">We may process or share your data that we hold based on the following legal basis:</span>
            </p>
            <ul className="c16 lst-kix_list_10-0 start">
                <li className="c20 c10"><span className="c2">Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.<br/></span>
                </li>
                <li className="c1 c10"><span className="c2">Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.<br/></span>
                </li>
                <li className="c1 c10"><span className="c2">Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.<br/></span>
                </li>
                <li className="c1 c10"><span className="c2">Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).<br/></span>
                </li>
                <li className="c21 c10"><span className="c11 c4">Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</span>
                </li>
            </ul>
            <p className="c7"><span className="c5 c4">More specifically, we may need to process your data or share your personal information in the following situations:</span>
            </p>
            <ul className="c16 lst-kix_list_16-0 start">
                <li className="c10 c20"><span className="c11 c4">Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_12-0 start">
                <li className="c1 c10"><span className="c11 c4">Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_17-0 start">
                <li className="c1 c10"><span className="c11 c4">Business Partners. We may share your information with our business partners to offer you certain products, services or promotions.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_19-0 start">
                <li className="c1 c10"><span className="c11 c4">Other Users. When you share personal information (for example, by posting comments, contributions or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.</span>
                </li>
            </ul>
            <ul className="c16 lst-kix_list_15-0 start">
                <li className="c21 c10"><span
                    className="c11 c4">Offer Wall. Our App may display a third-party hosted &ldquo;offer wall.&rdquo; Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer wall may appear in our App and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our App. A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward. Please note that we do not control third-party websites and have no responsibility in relation to the content of such websites. The inclusion of a link towards a third-party website does not imply an endorsement by us of such website. Accordingly, we do not make any warranty regarding such third-party websites and we will not be liable for any loss or damage caused by the use of such websites. In addition, when you access any third-party website, please understand that your rights while accessing and using those websites will be governed by the privacy notice and terms of service relating to the use of those websites.</span>
                </li>
            </ul>
            <p className="c7 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">We may use cookies and other tracking technologies to collect and store your information.</span>
            </p>
            <p className="c7"><span className="c5 c4">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</span>
            </p>
            <p className="c6"><span className="c2"></span></p>
            <p className="c7"><span className="c2">5. DO WE USE GOOGLE MAPS?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">Yes, we use Google Maps for the purpose of providing better service.</span>
            </p>
            <p className="c7"><span className="c11 c4">This Website or App uses Google Maps APIs which is subject to Google&#39;s Terms of Service. You may find the Google Maps APIs Terms of Service </span><span
                className="c29 c32 c4"><a className="c15"
                                          href="https://www.google.com/url?q=https://developers.google.com/maps/terms&amp;sa=D&amp;ust=1606060439083000&amp;usg=AOvVaw2wkU-kSwEOOHF0WSLimMOT">here</a></span><span
                className="c4">. To find out more about Google&rsquo;s Privacy Policy, please refer to this </span><span
                className="c29 c32 c4"><a className="c15"
                                          href="https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;ust=1606060439083000&amp;usg=AOvVaw3ICMtpJBQ1bvRWi3AY1hqE">link</a></span><span
                className="c5 c4">.</span></p>
            <p className="c7"><span className="c5 c4">We use the Google Maps API to retrieve certain information when you make location-specific requests. This includes:</span>
            </p>
            <p className="c7 c23"><span className="c2">&#9632; Your current location </span></p>
            <p className="c7 c23"><span className="c2">&#9632; Your proximity to our merchant </span></p>
            <p className="c7 c23"><span className="c2">&#9632; Your proximity to other Vuacifood platform users</span>
            </p>
            <p className="c7 c13 c23"><span className="c2"></span></p>
            <p className="c7"><span className="c11 c4">For a full list of what we use information for, please see the previous section titled &quot;</span><span
                className="c0"><a className="c15"
                                  href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/529979/privacy-policy%23infouse&amp;sa=D&amp;ust=1606060439084000&amp;usg=AOvVaw2g6RKwOSWtTZgPDm5TICb1">HOW DO WE USE YOUR INFORMATION?</a></span><span
                className="c4">&quot; and &ldquo;</span><span className="c0"><a className="c15"
                                                                                href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/529979/privacy-policy%23infoshare&amp;sa=D&amp;ust=1606060439084000&amp;usg=AOvVaw3YcU5CibBVSJM3EKlu_S7l">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></span><span
                className="c11 c4">&rdquo;. We obtain and store on your device (&#39;cache&#39;) your location</span><span
                className="c2">. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.</span>
            </p>
            <p className="c7"><span className="c5 c4">The Maps APIs that we use store and access cookies and other information on your devices. If you are a user currently in the European Economic Area (EU countries, Iceland, Liechtenstein and Norway), please take a look at our Cookie Notice.</span>
            </p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c7"><span className="c2">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</span>
            </p>
            <p className="c7"><span className="c5 c4">Our Services offers you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such social media platform.</span>
            </p>
            <p className="c7"><span className="c5 c4">We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.</span>
            </p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c7"><span className="c2">7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">We may transfer, store, and process your information in countries other than your own.</span>
            </p>
            <p className="c7"><span className="c11 c4">Our servers are located in United States. If you are accessing our Services from outside United States, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see &quot;</span><span
                className="c0"><a className="c15"
                                  href="https://www.google.com/url?q=https://app.termly.io/dashboard/website/529979/privacy-policy%23infoshare&amp;sa=D&amp;ust=1606060439086000&amp;usg=AOvVaw3kNTsz5AtNNk-ZP6zD3uzG">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></span><span
                className="c5 c4">&quot; above), in United States, and other countries.</span></p>
            <p className="c7"><span className="c5 c4">If you are a resident in the European Economic Area, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.</span>
            </p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c24"><span className="c2">8. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">We are not responsible for the safety of any information that you share with third-party providers who advertise, but are not affiliated with, our Website.</span>
            </p>
            <p className="c7"><span className="c5 c4">The Services may contain advertisements from third parties that are not affiliated with us and which may link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.</span>
            </p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c7"><span className="c2">9. HOW LONG DO WE KEEP YOUR INFORMATION?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</span>
            </p>
            <p className="c7"><span className="c11 c4">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than </span><span
                className="c4">three (3) months past the termination of the user&#39;s account</span><span
                className="c5 c4">.</span></p>
            <p className="c7"><span className="c5 c4">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</span>
            </p>
            <p className="c13 c33"><span className="c2"></span></p>
            <p className="c7"><span className="c2">10. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">We aim to protect your personal information through a system of organizational and technical security measures.</span>
            </p>
            <p className="c7"><span className="c5 c4">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</span>
            </p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c7"><span className="c2">11. DO WE COLLECT INFORMATION FROM MINORS?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c11 c4 c12">We do not knowingly collect data from or market to children under 18 years of age.</span>
            </p>
            <p className="c7"><span className="c5 c4">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at mysmartcad.com/privacy.</span>
            </p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c7"><span className="c2">12. WHAT ARE YOUR PRIVACY RIGHTS?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.</span>
            </p>
            <p className="c7"><span className="c11 c4">In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the</span><span
                className="c4">&nbsp;</span><span className="c30 c4">contact details</span><span
                className="c5 c4">&nbsp;provided below. We will consider and act upon any request in accordance with applicable data protection laws.</span>
            </p>
            <p className="c7"><span className="c5 c4">If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span>
            </p>
            <p className="c7"><span className="c11 c4">If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: </span><span
                className="c29 c32 c4"><a className="c15"
                                          href="https://www.google.com/url?q=http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;ust=1606060439089000&amp;usg=AOvVaw2asPNEfq6sWT6CDnHg2Qkh">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a></span><span
                className="c25 c31 c4">.</span></p>
            <p className="c7"><span className="c11 c4">If you are resident in Switzerland, the contact details for the data protection authorities are available here: </span><span
                className="c29 c32 c4"><a className="c15"
                                          href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;ust=1606060439090000&amp;usg=AOvVaw2x-rVI0H8WVpSNuzd1kGdt">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span><span
                className="c25 c4 c31">.</span></p>
            <p className="c7"><span
                className="c11 c4">If you have questions or comments about your privacy rights, you may email us at </span><span
                className="c11 c4 c30">contact@mysmartcad.com</span><span className="c5 c4">.</span></p>
            <p className="c22 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">Account Information</span></p>
            <p className="c7"><span className="c5 c4">If you would at any time like to review or change the information in your account or terminate your account, you can:</span>
            </p>
            <p className="c7"><span className="c4">&nbsp; &nbsp; &#9632; &nbsp;</span><span className="c5 c4">Log in to your account settings and update your user account.</span>
            </p>
            <p className="c7"><span className="c5 c4">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.</span>
            </p>
            <p className="c7"><span className="c0">Cookies and similar technologies:</span><span
                className="c11 c4">&nbsp;Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt-out of interest-based advertising by advertisers on our Services visit</span><span
                className="c4">&nbsp;</span><span className="c29 c4 c32"><a className="c15"
                                                                            href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;ust=1606060439091000&amp;usg=AOvVaw1gFLTmUlkYw1hsv-wwlllf">http://www.aboutads.info/choices/</a></span><span
                className="c5 c4">.</span></p>
            <p className="c7"><span className="c0">Opting out of email marketing:</span><span
                className="c5 c4">&nbsp;You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list &ndash; however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:</span>
            </p>
            <p className="c7"><span className="c4">&nbsp; &nbsp; &#9632; </span><span className="c5 c4">&nbsp;Access your account settings and update your preferences.</span>
            </p>
            <p className="c7"><span className="c4">&nbsp; &nbsp; &#9632; </span><span className="c5 c4">&nbsp;Contact us using the contact information provided.</span>
            </p>
            <p className="c6"><span className="c2"></span></p>
            <p className="c7"><span className="c2">13. CONTROLS FOR DO-NOT-TRACK FEATURES</span></p>
            <p className="c7"><span className="c5 c4">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&ldquo;DNT&rdquo;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span>
            </p>
            <p className="c6"><span className="c2"></span></p>
            <p className="c7"><span className="c2">14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</span>
            </p>
            <p className="c7"><span
                className="c5 c4">California Civil Code Section 1798.83, also known as the &ldquo;Shine The Light&rdquo; law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span>
            </p>
            <p className="c7"><span className="c5 c4">If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.). &nbsp;</span>
            </p>
            <p className="c13 c22"><span className="c2"></span></p>
            <p className="c1"><span className="c2">CCPA Privacy Notice</span></p>
            <p className="c7"><span
                className="c5 c4">The California Code of Regulations defines a &quot;resident&quot; as:</span></p>
            <p className="c7 c23"><span className="c4 c5">(1) every individual who is in the State of California for other than a temporary or transitory purpose and</span>
            </p>
            <p className="c7 c23"><span className="c5 c4">(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</span>
            </p>
            <p className="c1"><span
                className="c5 c4">All other individuals are defined as &quot;non-residents.&quot;</span></p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c5 c4">If this definition of &quot;resident&quot; applies to you, certain rights and obligations apply regarding your personal information.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c5 c4">What categories of personal information do we collect?</span></p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c5 c4">We have collected the following categories of personal information in the past twelve (12) months:</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p><a
                id="t.f491467241bf2924bf9fa15c83d2f9cc05c7acaa"></a><a
                id="t.0"></a>
            <table className="c9">
                <tbody>
                <tr className="c27">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Category</span>
                    </p>
                    </td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Examples</span>
                    </p>
                    </td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Collected</span>
                    </p></td>
                </tr>
                <tr className="c26">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">A. Identifiers</span></p></td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name</span>
                    </p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c26">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">B. Personal information categories listed in the California Customer Records statute</span>
                    </p></td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Name, contact information, education, employment, employment history and financial information</span>
                    </p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">YES</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c26">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">C. Protected classification characteristics under California or federal law</span>
                    </p></td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Gender and date of birth</span>
                    </p>
                    </td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c26">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">D. Commercial information</span>
                    </p>
                    </td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Transaction information, purchase history, financial details and payment information</span>
                    </p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c28">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">E. Biometric information</span>
                    </p>
                    </td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Fingerprints and voiceprints</span>
                    </p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c26">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">F. Internet or other similar network activity</span>
                    </p></td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements</span>
                    </p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c28">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">G. Geolocation data</span></p></td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span
                        className="c2">Device location</span></p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c28">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">H. Audio, electronic, visual, thermal, olfactory, or similar information</span>
                    </p></td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Images and audio, video or call recordings created in connection with our business activities</span>
                    </p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c28">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">I. Professional or employment-related information</span>
                    </p></td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us</span>
                    </p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c28">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">J. Education Information</span>
                    </p>
                    </td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Student records and directory information</span>
                    </p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                <tr className="c26">
                    <td className="c18" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">K. Inferences drawn from other personal information</span>
                    </p></td>
                    <td className="c8" colSpan="1" rowSpan="1"><p className="c1"><span className="c2">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual&rsquo;s preferences and characteristics</span>
                    </p></td>
                    <td className="c14" colSpan="1" rowSpan="1"><p className="c3 c13"><span className="c2"></span></p>
                        <p className="c3"><span className="c2">NO</span></p>
                        <p className="c3 c13"><span className="c2"></span></p></td>
                </tr>
                </tbody>
            </table>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c7"><span className="c5 c4">We may also collect other personal information outside of these categories in instances where you interact with us in-person, online, or by phone or mail in the context of:</span>
            </p>
            <ul className="c16 lst-kix_list_5-0 start">
                <li className="c20 c10"><span
                    className="c11 c4">Receiving help through our customer support channels<br/></span>
                </li>
                <li className="c1 c10"><span
                    className="c11 c4">Participation in customer surveys or contests; and<br/></span></li>
                <li className="c10 c21"><span className="c11 c4">Facilitation in the delivery of our Services and to respond to your inquiries</span>
                </li>
            </ul>
            <p className="c7"><span className="c5 c4">How do we use and share your personal information?</span></p>
            <p className="c1"><span className="c5 c4">More information about our data collection and sharing practices can be found in this privacy notice.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c5 c4">You may contact us by email at privacy@mysmartcad.com, or by referring to the contact details at the bottom of this document.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c5 c4">If you are using an authorized agent to exercise your right to opt-out, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c5 c4">Will your information be shared with anyone else?</span></p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c5 c4">We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c5 c4">We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be &quot;selling&quot; of your personal data.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c5 c4">Mysmartcad LLC has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. Mysmartcad LLC will not sell personal information in the future belonging to website visitors, users and other consumers.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">Your rights with respect to your personal data</span></p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span
                className="c25 c19 c4 c29">Right to request deletion of the data - Request to delete</span></p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation or any processing that may be required to protect against illegal activities.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c25 c29 c19 c4">Right to be informed - Request to know</span></p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">Depending on the circumstances, you have a right to know:</span></p>
            <ul className="c16 lst-kix_list_18-0 start">
                <li className="c20 c10"><span
                    className="c2">whether we collect and use your personal information;</span>
                </li>
                <li className="c1 c10"><span
                    className="c2">the categories of personal information that we collect;</span>
                </li>
                <li className="c1 c10"><span className="c2">the purposes for which the collected personal information is used;</span>
                </li>
                <li className="c1 c10"><span
                    className="c2">whether we sell your personal information to third parties;</span></li>
                <li className="c1 c10"><span className="c2">the categories of personal information that we sold or disclosed for a business purpose;</span>
                </li>
                <li className="c1 c10"><span className="c2">the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</span>
                </li>
                <li className="c21 c10"><span className="c2">the business or commercial purpose for collecting or selling personal information.</span>
                </li>
            </ul>
            <p className="c1"><span className="c2">In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span
                className="c25 c29 c19 c4">Right to Non-Discrimination for the Exercise of a Consumer&#39;s Privacy Rights</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">We will not discriminate against you if you exercise your privacy rights.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c25 c29 c19 c4">Verification process</span></p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with the information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c2">We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. If, however, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity, and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p>
            <p className="c1"><span className="c25 c29 c19 c4">Other privacy rights</span></p>
            <ul className="c16 lst-kix_list_7-0 start">
                <li className="c20 c10"><span
                    className="c2">you may object to the processing of your personal data</span>
                </li>
                <li className="c1 c10"><span className="c2">you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data</span>
                </li>
                <li className="c1 c10"><span className="c2">you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</span>
                </li>
                <li className="c21 c10"><span className="c2">you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission.</span>
                </li>
            </ul>
            <p className="c1"><span className="c5 c4">To exercise these rights, you can contact us by email at privacy@mysmartcad.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.</span>
            </p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c24"><span className="c2">15. DO WE MAKE UPDATES TO THIS NOTICE?</span></p>
            <p className="c7"><span className="c11 c4 c17">In Short: </span><span className="c11 c4">&nbsp;</span><span
                className="c12 c11 c4">Yes, we will update this notice as necessary to stay compliant with relevant laws.</span>
            </p>
            <p className="c7"><span className="c5 c4">We may update this privacy notice from time to time. The updated version will be indicated by an updated &ldquo;Revised&rdquo; date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span>
            </p>
            <p className="c6"><span className="c5 c4"></span></p>
            <p className="c7"><span className="c2">16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></p>
            <p className="c7"><span className="c5 c4">If you have questions or comments about this notice, you may email us at mysmartcad.com/privacy or by post to:</span>
            </p>
            <p className="c1"><span className="c5 c4">Mysmartcad LLC </span></p>
            <p className="c1"><span className="c2">15642 Sand Canyon Ave #54462</span></p>
            <p className="c1"><span className="c2">Irvine, CA 92619</span></p>
            <p className="c1"><span className="c2">United States</span></p>
            <p className="c6"><span className="c2"></span></p>
            <p className="c7"><span
                className="c2">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></p>
            <p className="c1"><span className="c2">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please email us at contact@mysmartcad.com. We will respond to your request within 30 days.</span>
            </p>
            <p className="c1 c13"><span className="c2"></span></p></div>
    </div>
);

PrivacyStatement.propTypes = {};

PrivacyStatement.defaultProps = {};

export default PrivacyStatement;
