import React, {Fragment, useContext, useState} from 'react';
import {Typography, Container, Button, IconButton, CardContent, Card, makeStyles, CardHeader} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/Remove';
import './CartSummary.scss';
import ResizeableIconButton from '../../common/components/ResizeableIconButton/ResizeableIconButton';
import CartItem from './CartItem/CartItem';
import * as _ from 'lodash';
import {useEffect} from 'react';
import {CardImg, Nav} from 'react-bootstrap';
import axios from '../../api/axios';
import AppContext from '../../common/components/store/AuthContext';
import store from '../../common/components/redux/store';
import {removeFromCart, updateCartCount} from '../../common/components/redux/actions';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import SignInDialog from "../../common/components/SignInDialog/SignInDialog";
import SignUpDialog from "../../common/components/SignUpDialog/SignUpDialog";
import {useCookies} from "react-cookie";
import AddDays from "../../common/components/AddDays";
import {Elements} from "@stripe/react-stripe-js";
import StripePromise from "../../api/StripePromise";
import Stripe from "./Stripe";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    summary: {
        margin: 'auto',
        width: 350
    }
}));

function CartSummary(props) {
    const [showSignInDialog, setShowSignInDialog] = React.useState(false);
    const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

    const toggleSignInDialog = () => {
        setShowSignInDialog(!showSignInDialog);
        setShowSignUpDialog(false);
    };
    const [cookies, setCookies] = useCookies(['latitude', 'longitude', 'userLocation'])
    const [location, setLocation] = React.useState(cookies['userLocation'] ? cookies['userLocation'] : "");

    // const [choosenItems, setChoosenItems] = React.useState([]);

    const [totalCartItems, setTotalCartItems] = React.useState([]);

    const [priceObj, setPriceObj] = React.useState({});
    const classes = useStyles();
    const {globalState, globalDispatch} = useContext(AppContext);
    const [paymentId, setPaymentId] = useState("");
    const [secret, setSecret] = useState("");
    const [VFID, setPaymentVFID] = useState("");
    const [payment_id, setPayment_id] = useState("");
    const [failed, setFailed] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [errorItems, setErrorItems] = useState([]);

    const clearCart = () => {
        // setChoosenItems([]);
    }

    const handleSignUp = () => {
        setShowSignInDialog(false);
        setShowSignUpDialog(!showSignUpDialog);
    };

    const onSuccessfulLogin = (response) => {
        // console.log({response})
        setFailed(false);
        setErrorMsg("")
        setErrorItems([])
        localStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem('refreshToken', response.refreshToken);
        localStorage.setItem("profileObj", JSON.stringify(response));

        globalDispatch({type: "LOGIN"});
        // window.location.reload();
        setShowSignInDialog(false);
        setShowSignUpDialog(false);
    };

    const onSuccessfulSignup = (response, formValues, locationDetails, isFormSignup) => {
        setFailed(false);
        setErrorMsg("")
        setErrorItems([])
        // debugger;
        // console.log(response, formValues, locationDetails);
        const locationObj = locationDetails.results[0];
        const cityName = _.find(locationObj.address_components, (l) =>
            _.includes(l.types, "locality")
        ).long_name;
        let countryCode = formValues.mobile.split(" ")[0];
        let mobile = formValues.mobile.split(" ")[1].replace("-", "")
        const userObj = {
            userDetails: {
                userName: formValues.firstName + " " + formValues.lastName,
                email: formValues.email,
                mobile,
                password: formValues.password,
                countryCode,
                profilePic: isFormSignup ? "" : formValues.imageUrl,
                signupType: isFormSignup ? "custom" : "google",
                location: {
                    type: "Point",
                    coordinates: [
                        locationObj.geometry.location.lat,
                        locationObj.geometry.location.lng,
                    ],
                },
                addresss: {
                    city: cityName,
                    street: _.trim(
                        _.split(locationObj.formatted_address, cityName, 1),
                        ", "
                    ),
                    pincode: _.find(locationObj.address_components, (l) =>
                        _.includes(l.types, "postal_code")
                    ).long_name,
                },
                city: cityName,
            },
        };
        console.log(userObj);
        axios
            .post(`/user/create`, userObj)
            .then((res) => {
                const data = res.data;
                // console.log({data})
                if (data.success) {
                    setShowSignInDialog(false);
                    setShowSignUpDialog(false);
                    setLocation(locationDetails.results[0].formatted_address);
                    setCookies('userLocation', locationDetails.results[0].formatted_address, {
                        path: "/",
                        maxAge: AddDays(30)
                    });
                }
            })
            .catch((error) => {
            });
    };

    const fetchCartItems = () => {
        setFailed(false);
        setErrorMsg("")
        setErrorItems([])
        const cartObj = {
            deviceId: store.getState().uuid,
        }

        axios.post('user/getcartitems', cartObj)
            .then(response => {
                let data = response.data;
                if (data.success) {
                    // console.log(data.response)
                    setTotalCartItems(data.response)
                }
            })
            .catch(error => {

            })
    }

    useEffect(() => {
        fetchCartItems();
    }, []);

    const updateCart = (merchant, currProduct, count) => {
        setFailed(false);
        setErrorMsg("")
        setErrorItems([])
        const cartObj = {
            deviceId: store.getState().uuid,
            merchant: merchant._id,
            product: currProduct.product._id,
            quantity: count,
            productName: currProduct.productName,
            productPrice: currProduct.productPrice
        }
        axios
            .post('user/cart', cartObj)
            .then(res => {
                const data = res.data;
                const key = currProduct.product._id;
                const obj = {};
                obj[key] = count;

                if (data.success) {
                    fetchCartItems();
                }
            })
            .catch((error) => {
            });
    }

    const checkOutItems = (item) => {
        setFailed(false);
        setErrorMsg("")
        setErrorItems([])
        let items = [];
        item.cartItems.map(temp => {
            items.push({
                id: temp.product._id,
                name: temp.product.name,
                quantity: temp.quantity,
                price: temp.productPrice
            })
        })

        let body = {
            orderDetails: {
                description: "Vuacifood Order",
                totalAmount: (item.totalAmount.toFixed(2)) * 100,
                totalItem: item.cartItems.length,
                currencyType: "usd",
                tax: item.taxAmount,
                itemTotal: item.amount.toFixed(0),
                items,
                user: JSON.parse(window.localStorage.profileObj) ? JSON.parse(window.localStorage.profileObj)._id : "",
                status: "initiated",
                merchant: item.merchant._id,
                stripeAccountId: item.merchant.stripeAccountId,
                userRating: "5",
                customerId: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).stripeId : "",
            }
        }
        axios.post('order/create', body)
            .then(response => {
                let data = response.data;
                if (data.success) {
                    //nextStep
                    let intentId = data.order.paymentIntent.id;
                    let secret = data.order.paymentIntent.client_secret
                    let id = data.order.id;
                    let _id = data.order._id
                    setPaymentId(intentId);
                    setSecret(secret)
                    setPaymentVFID(id);
                    setPayment_id(_id)
                } else {
                    setFailed(true);
                    setErrorMsg(data.message)
                    setErrorItems(data?.verifyOrder?.items)
                }
            })
            .catch(error => {
                console.log("=========", {error})
            })
    }

    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // console.log({choosenItems})
    if (paymentId !== "") {
        return (
            <Elements stripe={StripePromise}>
                <Stripe secret={secret} payment={{paymentId, VFID, payment_id}}/>
            </Elements>
        )
    } else {
        return (
            <Fragment>
                {
                    failed ?
                        <>
                        <Alert severity={"error"}>
                            {errorMsg}
                        </Alert>
                            <table className="table">
                                <tbody>

                            <Alert severity="info">
                                {
                                    errorItems.map(item=>{
                                        return(
                                            <tr key={item._id}>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item.status}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </Alert>
                                </tbody>
                            </table>
                        </>
                        :null
                }
                <div className='summary-root'>
                    {
                        totalCartItems && totalCartItems.length > 0
                            ?
                            <Container className={"container my-4"}>

                                {
                                    totalCartItems.map((item, index) => {
                                            return (
                                                <div className="row m-0" key={index}>
                                                    <div className="col-lg-8 col-md-8">
                                                        <div className="my-2 card shadow-sm rounded-lg">
                                                            <div className="pb-2 border-bottom">
                                                                <h5 className="title font-weight-bold">{item.merchant.name}</h5>
                                                                <h6 className="text-muted">{item.merchant.city}</h6>
                                                            </div>
                                                            {
                                                                item.cartItems.map((cartItem, index) => {
                                                                    return (
                                                                        <CartItem
                                                                            key={index}
                                                                            item={cartItem}
                                                                            handleCart={count => updateCart(item.merchant, cartItem, count)}/>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="my-2 card shadow-sm rounded-lg">
                                                            <h5 className="title font-weight-bold mb-2">Bill Details:</h5>
                                                            <table className="table">
                                                                <tbody>
                                                                <tr>
                                                                    <th>
                                                                        Total Amount:
                                                                    </th>
                                                                    <td>
                                                                        $ {item.amount ? item.amount : "0.00"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Taxes:
                                                                    </th>
                                                                    <td>
                                                                        $ {item.taxAmount ? item.taxAmount.toFixed(2) : "0.00"}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Amount to Pay:
                                                                    </th>
                                                                    <td>
                                                                        $ {item.totalAmount ? item.totalAmount.toFixed(2) : "0.00"}
                                                                    </td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                            {
                                                                globalState.isLoggedIn
                                                                    ?
                                                                    <Button variant='contained'
                                                                            onClick={(e) => checkOutItems(item)}
                                                                            color='primary'
                                                                            className='w-100'>Checkout</Button>
                                                                    :
                                                                    <Button variant='contained' onClick={toggleSignInDialog}
                                                                            color='primary'
                                                                            className='w-100'>Checkout</Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </Container>
                            :
                            <Container className='d-flex mb-auto flex-column p-0 summary-cart my-3' maxWidth='sm'>
                                <Card className={classes.summary}>
                                    <CardHeader title={'Cart is Empty'} className='pl-3 pb-0 summarycard-title'/>
                                    <div
                                        className='d-flex flex-column m-auto justify-content-center align-items-center cart-no-items'>
                                        <CardImg src='https://image.flaticon.com/icons/svg/413/413037.svg'/>
                                    </div>
                                </Card>
                            </Container>
                    }
                </div>
                <SignInDialog
                    show={showSignInDialog}
                    onClose={toggleSignInDialog}
                    onLoginSuccess={onSuccessfulLogin}
                    onSignup={handleSignUp}
                />
                <SignUpDialog
                    show={showSignUpDialog}
                    onClose={handleSignUp}
                    onSignin={toggleSignInDialog}
                    onSuccessfulSignup={onSuccessfulSignup}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    };
}

export default connect(mapStateToProps)(CartSummary);