import instance from "../../api/axios";
export function UpdateOrders (paymentId, VFID, payment_id){
    let body={
        orderDetails:{
            paymentIntentId: paymentId,
            _id: payment_id,
            id: VFID,
            status: "success",
            // fcmMessages: [],
            orderStatus: "pickup pending",
        }
    }

    instance.post('order/update', body)
        .then(response=>{
            console.log("------------------->",response.data)
            if(response.data.success){
                window.location.href="/orders"
                // window.location.reload()
            }else{
                console.log("--->", response.data.message);
            }
        })
        .catch(error=>{
            console.log("---------",{error})
        })
}