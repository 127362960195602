import React from "react";
import "./Home.scss";
import "./video-react.css"
import Typography from "@material-ui/core/Typography";
import Geocode from "react-geocode";
import {useHistory} from "react-router-dom";
import {BhookyConstants} from "../../common/AppConstants";
import LocationSearchInput from "../../common/components/LocationSearchInput/LocationSearchInput";
import {connect} from "react-redux";
import {useCookies} from "react-cookie";
import AddDays from "../../common/components/AddDays";
import ReactPlayer from "react-player";


function Home() {
    const history = useHistory();
    const [cookies, setCookies] = useCookies(['userLocation']);
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const [location, setLocation] = React.useState(cookies['userLocation'] ? cookies['userLocation'] : "");
    const getCurrentLocation = () => {
        Geocode.setApiKey(BhookyConstants.apiKey);
        setShowBackdrop(true);

        // history.push('/merchants');
        navigator.geolocation.watchPosition(
            async function (position) {
                setCookies('latitude', position.coords.latitude, {path: "/", maxAge: AddDays(30)})
                setCookies('longitude', position.coords.longitude, {path: "/", maxAge: AddDays(30)})
                await Geocode.fromLatLng(
                    position.coords.latitude,
                    position.coords.longitude
                ).then(
                    (response) => {
                        setShowBackdrop(false);
                        setLocation(response.results[0].formatted_address);
                        setCookies('userLocation', response.results[0].formatted_address, {
                            path: "/",
                            maxAge: AddDays(30)
                        })
                    },
                    (error) => {
                        setShowBackdrop(false);
                        console.error(error);
                    }
                );
            },
            () => {
                setShowBackdrop(false);
            }
        );
    };
    if (cookies['userLocation']) {
        history.push('/merchants');
        return null;
    }
    return (
            <>
                <div className="d-flex flex-column justify-content-center align-items-center landing-page-search">
                    <Typography 
                        variant="h4"
                        className="search-heading"
                    >
                        Pick location for your favorite food
                    </Typography>
                    <LocationSearchInput/>
                </div>


                <div className="homepage-content">
                    
                    <div className="video-row">
                        <div className="video-wrapper">
                            <ReactPlayer
                                className="brand-video"
                                url="https://player.vimeo.com/video/482148282"
                                controls={true}
                            />
                        </div>
                        <div className="brand-context">
                            <Typography variant="h4" className="brand-context-title">
                                Taste More Waste Less!
                            </Typography>
                            <Typography variant="subtitle1" className="brand-context-content">
                                Our mission is to reduce food waste and help local businesses increase their revenue
                            </Typography>
                            
                        </div>
                    </div>

                    <div className="first-row">
                        <div className="content-img-one">
                            <img
                                src={require("../../assets/home-page-one.png")}
                                className="img-prop flip right"
                                alt="Find Your Favorite Food"
                            />
                        </div>
                        <div className="brand-context">
                            <Typography variant="h4" className="brand-context-title">
                                Find Your Favorite Food
                            </Typography>
                            <Typography variant="subtitle1" className="brand-context-content">
                                Vuacifood lets you buy quality excess food in bulk from your
                                neighborhood retailer. From your neighborhood sushi joint to the
                                bakery around the corner, Vuacifood gives you the opportunity to
                                realize some deep saving
                            </Typography>
                        </div>
                    </div>
                    <div className="second-row">
                        <div className="brand-context">
                            <Typography variant="h4" className="brand-context-title">
                                Become A Partner With Us
                            </Typography>
                            <Typography variant="subtitle1" className="brand-context-content">
                                Become one of our partner retail stores. Increase foot traffic and
                                your revenue. By partnering with Vuacifood you can help your
                                community by providing quality discounted food and help the
                                environment by reducing waste
                            </Typography>
                        </div>
                        <div className="content-img-two">
                            <img
                                src={require("../../assets/home-page-two.png")}
                                className="img-prop flip left"
                                alt="Find Your Favorite Food"
                            />
                        </div>
                    </div>
                </div>
            </>
    );

}

Home.propTypes = {};

Home.defaultProps = {};
const mapStateToProps = (state) => {
    return {
        number: state.location,
    };
};
export default connect(mapStateToProps)(Home);
