import baseURL from "../../../api/baseURL";

const apiUrl = baseURL;

const headers = {
    'auth-type': 'user',
    'Authorization': 'Bearer ' + window.localStorage.accessToken,
    'Content-Type': 'application/json',
};
const post = 'POST';

export function getOtp(mobile, countryCode, type) {

    let body = JSON.stringify({
        "signup": true,
        mobile,
        countryCode,
        type,
    })
    console.log({body})
    return fetch(`${apiUrl}message/mobile`, {
        method: post,
        headers,
        body,
    })
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            return error;
        })
}

export function verifyOTP(mobile, otp, type){
    let body = JSON.stringify({
        mobile,
        otp,
        type,
    })
    console.log({body})
    return fetch(`${apiUrl}user/verifyotp`, {
        method: post,
        headers,
        body,
    })
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            return error;
        })
}