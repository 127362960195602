import {UPDATE_CART, UPDATE_LOCATION, UPDATE_UUID} from './actions';

const initialState = {
    userLocation: '',
    uuid: localStorage.getItem('uuid') ? localStorage.getItem('uuid') : "",
    cart: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LOCATION:
            // window.localStorage.setItem('userLocation', JSON.stringify(action.userLocation));
            return {
                ...state,
                userLocation: action.userLocation
            };
        case UPDATE_UUID:
            if (state.uuid !== "") {
                return {
                    ...state,
                    uuid: state.uuid
                };
            } else {
                localStorage.setItem('uuid', action.uuid)
                return {
                    ...state,
                    uuid: action.uuid
                };
            }

        case UPDATE_CART:
            return {
                ...state,
                cart: action.cart
            };

        default:
            return state;
    }

}
