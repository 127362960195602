import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import "./AboutUs.scss";
import qrcode from './QRCode.png';
import androidLogo from './android.svg';
const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      color: "white",
      fontSize: 50,
      fontFamily: "'BhookyFont-Regular'",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
  })
);
function AboutUs() {
  const classes = useStyles();
  return (
    <div className="d-flex flex-column text-center  h-100vh bg-image-about-us">
      <div className="about-us-overlay h-100vh op-5">&nbsp;</div>
      <div style={{ zIndex: 2, padding: 50 }}>
        <Typography className={classes.title} variant="button">
          Vuacifood
        </Typography>
        {/* <GridListTile key='about-us' cols={10}>
        <img src={aboutUs} />
      </GridListTile> */}
        <Typography variant="subtitle1" className="text-light">
          Our mission is to give everyone a voice and show them the world.
        </Typography>
        <Typography variant="subtitle1" className="text-light">
          Our mission is to reduce food waste and help local businesses increase
          their revenue. We want to become the premier platform for where retail
          stores such as bakeries , restaurants , grocery stores or food trucks
          can sell their excess quality food to a larger clientele.
        </Typography>
        <div className="margin-top-10">
          <div className="btn-download-android">
              <Typography variant="subtitle1" className="text-light">
                  Scan the QR Code For Android
                  <span>
                      <img width={"3%"} src={androidLogo} alt={""}/>
                  </span>
              </Typography>
              Comming soon

          </div>
          <img className="qr-code" src={qrcode} alt={"qrcode"}/>
        </div>
      </div>
    </div>
  );
}

AboutUs.propTypes = {};

AboutUs.defaultProps = {};

export default AboutUs;
