import Axios from "axios";
// import baseURL from "../../../api/baseURL";

const baseURL = "https://preprod.vuacifood.com/v1/";

const headers = {
    'auth-type': 'user',
    'Authorization': 'Bearer ' + window.localStorage.accessToken,
    'Content-Type': 'application/json',
};

export function getOtpForForgotPassword (mobile){
    let body = JSON.stringify({
        mobile,
    })
    return Axios.post(`${baseURL}auth/password/recover`, body, {headers})
        .then(response=>{
            return response.data;
        })
        .catch(error=>{
            return error;
        })
}

export function verifyOtpForForgotPassword (mobile, token, password, otp){
    let body= JSON.stringify({
        mobile, token, password, otp
    })

    return Axios.post(`${baseURL}auth/password/reset`, body, {headers})
        .then(response=>{
            return response.data;
        })
        .catch(error=>{
            return error;
        })
}