import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
} from "react-places-autocomplete";
import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { CircularProgress, IconButton, TextField } from "@material-ui/core";
import { updateLocation } from "../redux/actions";
import { BhookyConstants } from "../../AppConstants";
import AddDays from "../AddDays";

import "./Search.scss"

function Search() {

    const [cookies, setCookies] = useCookies(["latitude", "longitude"]);
	const [location, setLocation] = React.useState(
		cookies["userLocation"] ? cookies["userLocation"] : ""
    );
	const [address, setAddress] = useState(location);
	const [showBackdrop, setShowBackdrop] = React.useState(false);
    const dispatch = useDispatch();
    

	const getLocation = () => {
		getCurrentLocation();
	};
    
	const handleChange = (address) => {
		setAddress(address);
    };

    const handleSelect = (address) => {
		Geocode.setApiKey(BhookyConstants.apiKey);
		Geocode.setLanguage("en");
		Geocode.enableDebug();

		geocodeByAddress(address)
			.then((results) => getLatLng(results[0]))
			.then(
				(latLng) => {
					let { lat, lng } = latLng;

					setCookies("latitude", lat, {
						path: "/",
						maxAge: AddDays(30),
					});
					setCookies("longitude", lng, {
						path: "/",
						maxAge: AddDays(30),
					});
					Geocode.fromLatLng(lat, lng).then(
						(response) => {
							const address =
								response.results[0].formatted_address;
							setCookies("userLocation", address, {
								path: "/",
								maxAge: AddDays(30),
							});
							setLocation(address);
							dispatch(updateLocation(address));
							setShowBackdrop(false);
							window.location.reload();
						},
						(error) => {
							setShowBackdrop(false);
							console.error(error);
						}
					);
				},
				(error) => {
					console.error(error);
					setShowBackdrop(false);
				}
			)
			.catch((error) => console.error("Error", error));
    };
    
    const getCurrentLocation = () => {
		Geocode.setApiKey(BhookyConstants.apiKey);
		Geocode.setLanguage("en");
		Geocode.enableDebug();

		setShowBackdrop(true);

		navigator.geolocation.getCurrentPosition(
			async function (position) {
				const { latitude, longitude } = position.coords;
				setCookies("latitude", latitude, {
					path: "/",
					maxAge: AddDays(30),
				});
				setCookies("longitude", longitude, {
					path: "/",
					maxAge: AddDays(30),
				});
				await Geocode.fromLatLng(latitude, longitude).then(
					(response) => {
						const address = response.results[0].formatted_address;
						setCookies("userLocation", address, {
							path: "/",
							maxAge: AddDays(30),
						});
						setLocation(address);
						dispatch(updateLocation(address));
						setShowBackdrop(false);
						window.location.reload();
					},
					(error) => {
						setShowBackdrop(false);
						console.error(error);
					}
				);
			},
			(error) => {
				console.error(error);
				setShowBackdrop(false);
			}
		);
	};
	
	if(cookies["userLocation"]) {
		return (
			<div className="search-container mt-4">
				<PlacesAutocomplete
					value={address}
					onChange={handleChange}
					onSelect={handleSelect}>
					{({
						index,
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
					}) => (
						<div key={index} className='user-location__wrapper'>
							<TextField
								id="outlined-secondary"
								variant="outlined"
								color="secondary"
								className="user-location-input"
								{...getInputProps({
									placeholder: "Search Places...",
								})}
								InputProps={{
									endAdornment: showBackdrop ? (
										<CircularProgress
											style={{
												width: 20,
												height: 20,
												color: "#191818",
											}}
										/>
									) : (
										<IconButton
											onClick={getLocation}
											style={{
												color: "#191818",
											}}>
											<MyLocationIcon />
										</IconButton>
									),
								}}
								onFocus={() => setAddress("")}
							/>
							<div className='user-location__dropdown shadow-sm my-2'>
								{loading && (
									<span className='user-location__dropdown__loading bg-white'>
										Loading...
									</span>
								)}
								{suggestions.map((suggestion) => {
									const className = "suggestion-item";
	
									const key = suggestion.description;
									return (
										<div
											className='user-location__dropdown__suggestions'
											{...getSuggestionItemProps(
												suggestion,
												{
													className,
													key,
												}
											)}>
											<span>
												{suggestion.description}
											</span>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</PlacesAutocomplete>
			</div>	
		)
	} else {
		return(
		<>
		</>
		)
	}
}

export default Search