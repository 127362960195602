import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createMuiTheme, createStyles, makeStyles, MuiThemeProvider} from "@material-ui/core/styles";
import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import axios from './api/axios';
import "./App.scss";
import Header from "./common/components/Header/Header";
import BhookyUserHome from "./components/BhookyUserHome/BhookyUserHome";
import Home from "./components/Home/Home";
import BecomeaPartner from "./components/BecomeaPartner/BecomeaPartner";
import AboutUs from "./components/AboutUs/AboutUs";
import FindaRestaurant from "./components/FindaRestaurant/FindaRestaurant";
import ContactUs from "./components/ContactUs/ContactUs";
import Footer from "./common/components/Footer/footer";
import {PrivateRoute} from "./common/components/auth/PrivateRoute";
import RestaurantCheckout from "./components/RestaurantCheckOut/RestaurantCheckOut";
import Profile from "./components/Profile/Profile";
import {Provider} from "react-redux";
import store from "./common/components/redux/store";
import PrivacyStatement from "./components/PrivacyStatement/PrivacyStatement";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import Reviews from "./components/reviews/Reviews";
import {v4 as uuidv4} from 'uuid';
import {updateUUID} from "./common/components/redux/actions";
import CartSummary from "./components/CartSummary/CartSummary";
import Orders from "./components/orders/Orders";
import AddANewCard from "./components/Payments/AddANewCard";
import Search from "./common/components/Search/Search";

// Auth Components
import Login from "./auth/Login/Login";
import SignUp from "./auth/SignUp/SignUp";
import ForgotPassword from "./auth/ForgotPassword/ForgotPassword";

require("dotenv").config();

console.log(process.env)

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#000000",
        },
        secondary: {
            main: "#5c5c5c",
        },
    },
});

const useStyles = makeStyles((theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }),
);

const getCookie = (key) => {
    let cvalue = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return cvalue ? cvalue.pop() : '';
}

class App extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(updateUUID(uuidv4()));
        this.state = {
            snackBarProps: {
                open: false,
                vertical: 'bottom',
                horizontal: 'left',
                autoHideDuration: 30,
                message: '',
                severity: ''
            },
            showBackdrop: false,
            merchantInfo: JSON.parse(localStorage.getItem("merchantInfo")),
            authToken: localStorage.getItem("accessToken"),
            width: window.innerWidth
        }
    }

    componentWillMount() {

        // To check screen resolution for mobile - used in adding search component below header
        window.addEventListener('resize', this.handleWindowSizeChange);

        axios.interceptors.request.use((config) => {
            // debugger;
            // if (!_.isEqual(config.url, 'product/data')) {
            this.setState({showBackdrop: true});
            // }
            return config;
        }, (error) => {
            this.setState({showBackdrop: false});
            return Promise.reject(error);
        });

        axios.interceptors.response.use((response) => {
            this.setState({showBackdrop: false});
            return response;
        }, (error) => {
            this.setState({showBackdrop: false});
            return Promise.reject(error);
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
      }

    render() {

        const {vertical, horizontal, open, autoHideDuration, message, severity} = this.state.snackBarProps;

        const {width} = this.state;

        const isMobileOrTablet = width <= 800;

        const showAlert = (newState) => () => {
            this.setState({...newState, open: true});
        };
        const setToken = (token) => {
            localStorage.setItem("acessToken", JSON.stringify(token));
            const newState = {...this.state};
            this.setState({authToken: token, ...newState});
        }
        const updateState = (key, val) => {
            this.setState({[key]: val});
            console.log(this.state);
        }

        const handleClose = () => {
            const oldSnackBarProps = {...this.state.snackBarProps};
            oldSnackBarProps.open = false;
            this.setState({snackBarProps: oldSnackBarProps});
        };
        return (
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <BrowserRouter>

                        {/* Header */}
                        <Header/>

                        {/* Search - common for every page */}
                        {
                            isMobileOrTablet ?
                            <Search />
                            :
                            ""
                        }


                        {/* Main Content */}
                        <div className={`d-flex app p-0 ${ getCookie('userLocation') ? 'bhooky-content' : ''}`}>
                            <Switch>
                                <Route exact path="/" component={Home}/>
                                <Route path="/about-us" component={AboutUs}/>
                                <Route component={BecomeaPartner} path="/become-a-partner"/>
                                <Route path="/become-a-promoter" component={FindaRestaurant}/>
                                <Route path="/contact-us" component={ContactUs}/>
                                <PrivateRoute path="/profile" component={Profile}/>
                                <Route path="/merchants" component={BhookyUserHome}/>
                                <Route path="/cart-summary" component={CartSummary}/>
                                <Route path="/user-help" component={FindaRestaurant}/>
                                <Route path="/privacy-statement" component={PrivacyStatement}/>
                                <Route path="/terms-and-conditions" component={TermsAndConditions}/>
                                <Route path="/:restaurantName/:id/order" component={RestaurantCheckout}/>
                                <Route path="/:reviews/:merchantid" component={Reviews}/>
                                <Route path="/orders" component={Orders}/>
                                <Route path="/add-a-new-card" component={AddANewCard}/>
                                {/* Auth Routes */}
                                <Route path="/login" component={Login}/>
                                <Route path="/signup" component={SignUp}/>
                                <Route path="/forgot-password" component={ForgotPassword}/>
                            </Switch>
                        </div>

                        {/* Footer */}
                        <Footer/>
                    </BrowserRouter>

                    <Backdrop open={this.state.showBackdrop} className='backdrop'>
                        <CircularProgress color="inherit"/>
                    </Backdrop>

                </MuiThemeProvider>
            </Provider>
        );
    }
}

export default App;
