import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {IconButton, makeStyles, Typography} from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from 'react-router-dom';
import {useForm} from '../Form/useForm';
import PropTypes from 'prop-types';
import {CloseButton} from 'react-bootstrap';
import MuiPhoneNumber from "material-ui-phone-number";
import * as _ from 'lodash';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import {BhookyConstants} from '../../AppConstants';
import Geocode from "react-geocode";
import store from '../redux/store';
import {getOtp, verifyOTP} from "./functions";
import { validateEmail } from '../../validators';
import instance from '../../../api/axios';
// import './SignInDialog.scss';
const useStyles = makeStyles((theme) => ({
    paper: {

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 1, 2, 1),

    },
}));

function SignUpDialog(props) {
    const classes = useStyles();
    const initialFValues = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
    };
    store.subscribe(() => {
        setLocationObj(store.getState()['userLocation'][0].formatted_address);
    });
    const [showBackdrop, setShowBackdrop] = React.useState(false);
    const [locationObj, setLocationObj] = React.useState({results: []});
    const [isFormSignUp, setIsFormSignUp] = React.useState(true);
    const [email, setEmail] = useState("");
    const [otpRequestSent, setOtpRequestSent] = React.useState(false);
    const [emailOTPRequestSent, setEmailOTPRequestSent] = React.useState(false);
    const [otpVerified, setOtpVerified] = React.useState(false);
    const [emailOTPVerified, setEmailOTPVerified] = useState(false);
    const [emailOTP, setEmailOTP] = useState("");
    const [emailToken, setEmailToken] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobile, setMobile] = useState("");
    const [otp, setOTP] = useState("");
    const [errorMsg, setErrorMsg] = useState("")


    const getOtpForSignUp = (mobileNumber) => {
        // console.log(mobile);
        if(mobileNumber.length>0){
            let mobile = mobileNumber.replace("(", "");
            mobile = mobile.replace(")", "");
            mobile = mobile.replace("-", "");
            let mobileArray = mobile.split(" ");
            mobileArray[2] === undefined ?
                mobile = mobileArray[1]
                :
                mobile = mobileArray[1] + mobileArray[2];
            let countryCode = mobileArray[0];
            console.log(countryCode, mobile)

            if (mobile.length < 10) {
                setErrorMsg("Invalid Mobile Number")
            } else {
                getOtp(mobile, countryCode, 'user')
                    .then(data => {
                        console.log(data)
                        if (data.success) {
                            setOtpRequestSent(true);
                            setErrorMsg(data.message);
                        }else{
                            setErrorMsg(data.message);
                            setOtpRequestSent(false);
                        }
                    })
                    .catch(error => {
                        setOtpRequestSent(false);
                        setErrorMsg("Check your Mobile Number")
                    })
            }
        }else {
            setErrorMsg("Invalid Mobile Number")
        }
    }

    const verifyOtpForSignUp = (mobileNumber, otp) => {
        let mobile = mobileNumber.replace("(", "");
        mobile = mobile.replace(")", "");
        mobile = mobile.replace("-", "");
        let mobileArray = mobile.split(" ");
        mobileArray[2] === undefined ?
            mobile = mobileArray[1]
            :
            mobile = mobileArray[1] + mobileArray[2];
        let countryCode = mobileArray[0];
        console.log(countryCode, mobile, otp)

        if (mobile.length < 10) {
            setErrorMsg("Invalid Mobile Number")
        } else if (otp.length < 6) {
            setErrorMsg("Invalid OTP")
        } else {
            verifyOTP(mobile, otp, 'user')
                .then(data => {
                    console.log(data)
                    if (data.status) {
                        setOtpVerified(true)
                        setErrorMsg(data.message)
                    } else {
                        setErrorMsg(data.message)
                        setOtpVerified(false)
                    }
                })
                .catch(error => {
                    setOtpVerified(false);
                })
        }
    }

    const {
        values,
        resetForm,
        handleInputChange,
    } = useForm(initialFValues, true);

    const onChangeEmail = (email) => {
      setEmail(email);
      setEmailError("");
    };
  
    const onEmailOTPChange = (otp) => {
      setEmailOTP(otp);
      setEmailError("");
    };

    const getOTPEmail = (email) => {
        console.log('Email: ', email);
        if (validateEmail(email)) {
            instance
            .post("message/user/email", { email })
            .then((response) => {
                let { data } = response;
                if (data.success) {
                    console.log(data.message.message)
                if (data.message.message) {
                    setEmailOTPRequestSent(false);
                    setEmailError(data.message.message);
                } else {
                    setEmailOTPRequestSent(true);
                    setEmailToken(data.message);
                }
                } else {
                setEmailOTPRequestSent(false);
                setEmailError("Check the E-mail entered");
                }
            })
            .catch((error) => {
                console.log({ error });
            });
        } else {
            setEmailError("Invalid Email");
        }
    };

    const verifyEmailOtpForSignUp = (email, otp) => {
        if (validateEmail(email)) {
            if (otp.length > 0) {
            let body = {
                otp: otp,
                token: emailToken,
                email: email,
            };
            instance
                .post("user/verifyotp", body)
                .then((response) => {
                let { data } = response;
                if (data.status) {
                    setEmailOTPVerified(true);
                } else {
                    setEmailError(data.message);
                    setEmailOTPVerified(false);
                }
                })
                .catch((error) => {
                setEmailOTPVerified(false);
                setEmailError("Try Again");
                });
            } else {
            setEmailError("Invalid OTP");
            }
        } else {
            setEmailError("Invalid Email");
        }
    };

    return (
        <div>
            <Dialog open={props.show} han aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <div className='d-flex justify-content-between'>

                        <Typography variant='h5' className='bhooky-bold'>{!isFormSignUp && 'Complete'} Sign
                            Up</Typography>

                        <CloseButton onClick={() => {
                            props.onClose();
                            resetForm();
                            setIsFormSignUp(true);
                        }} className='mb-2'>

                        </CloseButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.paper}>
                        <form className={classes.form} noValidate>
                            <div className='d-flex'>
                                {isFormSignUp && <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="text"
                                    id="firstName"
                                    value={values.firstName}
                                    label="First Name"
                                    name="firstName"
                                    autoComplete="firstName"
                                    onChange={handleInputChange}
                                    autoFocus
                                    className='mr-3'
                                />}
                                {isFormSignUp && <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="text"
                                    id="lastName"
                                    value={values.lastName}
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="lastName"
                                    onChange={handleInputChange}
                                />}
                            </div>
                            <div className="d-flex justify-content-between">

                                <MuiPhoneNumber
                                    defaultCountry='us'
                                    onlyCountries={['in', 'us']}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="mobile"
                                    disabled={otpVerified}
                                    value={values.mobile}
                                    placeholder='Phone Number'
                                    label="Mobile"
                                    name="mobile"
                                    autoComplete="mobile"
                                    countryCodeEditable={false}
                                    // onChange={event => handleInputChange({target: {name: 'mobile', value: event}})}
                                    onChange={(mobile) => setMobile(mobile)}
                                />
                                {
                                    otpVerified ? null :
                                        <Button
                                            type="button"
                                            onClick={(e) => getOtpForSignUp(mobile)}
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            VERIFY</Button>
                                }

                            </div>
                            {!otpVerified ?
                            <div>
                                <small className="text-info">Please verify your mobile number</small>
                            </div>
                            : ""}
                            <small className="text-danger">{errorMsg}</small>
                            {
                                (otpRequestSent && !otpVerified) ?
                                    <div className="d-flex justify-content-between">

                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            type="text"
                                            id="otp"
                                            value={otp}
                                            label="OTP"
                                            name="otp"
                                            autoComplete="otp"
                                            onChange={(event) => setOTP(event.target.value)}
                                            autoFocus
                                            className='mr-3'
                                        />
                                        <Button
                                            type="button"
                                            onClick={(e) => verifyOtpForSignUp(mobile, otp)}
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            className={classes.submit}
                                        >
                                            VERIFY OTP</Button>
                                    </div>
                                    : null
                            }
                            {isFormSignUp && 
                                <div className="d-flex justify-content-between">
                                    <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    id="email"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    disabled={emailOTPVerified}
                                    value={values.email}
                                    label="Email *"
                                    name="email"
                                    onChange={handleInputChange}
                                    />
                                    {emailOTPVerified ? null : (
                                    <Button
                                        type="button"
                                        onClick={(e) => getOTPEmail(values.email)}
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                    >
                                        VERIFY
                                    </Button>
                                    )}
                                </div>
                            }
                            {!emailOTPVerified ?
                            <div>
                                <small className="text-info">Please verify your email</small>
                            </div>
                            : ""}
                            <small className="text-danger">{emailError}</small>
                            {emailOTPRequestSent && !emailOTPVerified ? (
                                <div className="d-flex justify-content-between">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    type="text"
                                    id="emailOTP"
                                    value={emailOTP}
                                    label="OTP"
                                    name="emailOTP"
                                    autoComplete="emailOTP"
                                    onChange={(event) =>
                                    onEmailOTPChange(event.target.value)
                                    }
                                    autoFocus
                                    className="mr-3"
                                />
                                <Button
                                    type="button"
                                    onClick={(e) =>
                                    verifyEmailOtpForSignUp(values.email, emailOTP)
                                    }
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    VERIFY OTP
                                </Button>
                                </div>
                            ) : null}

                            {isFormSignUp && <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                value={values.password}
                                onChange={handleInputChange}
                                autoComplete="current-password"
                            />}
                        </form>
                    </div>
                    <Typography variant="caption" display="block" gutterBottom>
                        By clicking Sign up or continue with Facebook or Google you agree to the
                        <Link to="/terms-and-conditions" target='blank' className='bhooky-semibold'>
                            Terms and Conditions</Link>
                        and <Link to="/privacy-statement" target='blank' className='bhooky-semibold'>
                        Privacy Statement
                    </Link>
                    </Typography>
                </DialogContent>
                <DialogActions className='flex-column mx-3 pt-0 pb-4 my-auto'>
                    {
                        otpVerified ?
                            <Button
                                type="submit"
                                onClick={event => props.onSuccessfulSignup(event, values, mobile, isFormSignUp)}
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={_.values(values).some(v => v === "")}
                                className={classes.submit}
                            >
                                Sign Up
                                </Button>
                            :null
                    }
                    <div className='d-flex justify-content-between w-100 option-btns'>
                        <Link to="#" href="#" variant="body2" onClick={event => {
                            props.onSignin();
                            resetForm();
                        }}>
                            Already have an account? Sign In
                        </Link>
                    </div>
                    {/* {isFormSignUp && <div class="or-div my-2">
                        <hr class="or-hr" />
                        <span class="or-span">or</span>
                    </div>}
                    {isFormSignUp && <div className='d-flex justify-content-between w-100'>
                        <GoogleButton className='w-100' btnText='Sign Up' handleLogin={signUp} />
                        <FacebookButton />
                    </div>} */}
                </DialogActions>
            </Dialog>

        </div>
    );
    // }
}

SignUpDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    children: PropTypes.node
};

export default SignUpDialog;
