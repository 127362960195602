import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
    Typography
} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import instance from "../../api/axios";
import axios from "../../api/axios";
import {useCookies, withCookies} from "react-cookie";
import {Carousel, CloseButton, Nav} from "react-bootstrap";
import AddorRemoveButtons from "../../common/components/AddorRemoveButtons/AddorRemoveButtons";
// import {instanceOf} from "prop-types";
import store from "../../common/components/redux/store";
import * as _ from "lodash";
import {updateCartCount, updateLocation} from "../../common/components/redux/actions";
import {connect, useDispatch} from 'react-redux';
import {useHistory} from "react-router-dom";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import Geocode from "react-geocode";
import {BhookyConstants} from "../../common/AppConstants";
import AddDays from "../../common/components/AddDays";

// Styling
import "./Search.scss";

// No Icons
import NoItemsFound from "../../assets/restaurant.png";

const Search = (props) => {
    const searchNearByButton= {
        backgroundColor: "#02b3a3",
        color:"white",
        padding: ".5rem",
        fontWeight:700,
        marginBottom: "1rem"
    }
    const [cookies, setCookies] = useCookies(['latitude', 'longitude']);
    const history = useHistory();
    const [keyword, setKeyWord] = useState("");
    const [openSearch, setOpenSearch] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({
        searchItems: [],
        longitude: cookies['longitude'],
        latitude: cookies['latitude'],
        products: [],
        product: {},
    });
    const [location, setLocation] = React.useState(cookies['userLocation'] ? cookies['userLocation'] : "");

    const [address, setAddress] = useState(location);
    const dispatch = useDispatch();
    const [showBackdrop, setShowBackdrop] = useState(false);

    const SearchItems = (e) => {
        setKeyWord(e.target.value);
        if (e.target.value.length >= 3) {
            setIsLoading(true)
            let body = {
                "keyword": e.target.value,
                longitude: state.longitude,
                latitude: state.latitude,
                maxDistance: 5000,
            }

            instance.post('merchant/complete/search', body)
                .then(response => {
                    console.log("--->",response.data)
                    if (response.data.success) {
                        setState(prevState => {
                            return {
                                ...prevState,
                                searchItems: response.data.searchItems
                            }
                        })
                    }
                    setIsLoading(false)
                })
                .catch(error => {
                    console.log({error})
                    setIsLoading(false)
                })
        }
    }

    const openSearchBar = (e) => {
        e.preventDefault();
        setOpenSearch(true)
    }

    const closeSearchBar = (e) => {
        e.preventDefault();
        setOpenSearch(false);
        setState(prevState => {
            return {
                ...prevState,
                searchItems: [],
            }
        })
    }

    const openSearchedItemDetails = (item) => {
        setIsLoading(true)
        let body = {
            location: {
                longitude: state.longitude,
                latitude: state.latitude,
                maxDistance: 5000,
            },
            type: "user",
        }
        instance.post('product/' + item._id, body)
            .then(response => {
                // console.log(response.data)
                if (response.data.success) {
                    let {productDetails} = response.data;
                    setState(prevState => {
                        return {
                            ...prevState,
                            product: productDetails,
                            showProductDetails: true,
                        }
                    })
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.log({error})
                setIsLoading(false)
            })
    }

    const handleClose = (e) => {
        e.preventDefault();
        setState(prevState => {
            return {
                ...prevState,
                showProductDetails: false,
                product: {},
            }
        })
    }

    const updateCart = (count, e) => {
        console.log({count, e})
        let {product, products} = state;
        const cartObj = {
            user: JSON.parse(window.localStorage.profileObj)._id ? JSON.parse(window.localStorage.profileObj)._id : null,
            deviceId: store.getState().uuid,
            merchant: product.merchant._id,
            product: product._id,
            quantity: count,
            productName: product.name,
            productPrice: product.price
        }
        console.log({cartObj})
        axios
            .post('user/cart', cartObj)
            .then(res => {
                const data = res.data;
                const key = product._id;
                const obj = {};
                obj[key] = count;
                if (data.success) {
                    let currPrducts = products;
                    console.log({data})
                    _.forEach(data.cartItems, productObj => {
                        const cartProdct = _.find(currPrducts, {'_id': productObj.product._id});
                        if (cartProdct) {
                            cartProdct.cartCount = productObj.quantity;
                        }
                    });
                    setState(prevState => {
                        return {
                            ...prevState,
                            products: currPrducts
                        }
                    })

                    props.dispatch(updateCartCount(_.merge(props.cart, obj)));
                    console.log(data);
                }
            })
            .catch((error) => {
            });
    }

    const getNearByMerchants = (e)=>{
        setKeyWord("");
        let body = {
            location : {
                longitude: state.longitude,
                latitude: state.latitude,
                maxDistance: 5000,
            }
        }
        instance.post('merchant/filter', body)
            .then(response => {
                if (response.data.success) {
                    console.log("===>",response.data)
                    setState(prevState => {
                        return {
                            ...prevState,
                            searchItems: response.data.merchants
                        }
                    })
                }
                setIsLoading(false)
            })
            .catch(error => {
                console.log({error})
                setIsLoading(false)
            })
    }

    const goToMerchant = (item, e) => {
        e.preventDefault();
        setIsLoading(true)
        let body = {
            merchantId: item._id,
            userId: localStorage.getItem('profileObj') ? JSON.parse(localStorage.getItem('profileObj'))._id : null,
            location: {
                longitude: state.longitude,
                latitude: state.latitude,
            }
        }
        instance.post('merchant/user', body)
            .then(response => {
                setIsLoading(false)
                if (response.data.success) {
                    redirectToMerchantDetails(response.data.merchantDetails, e)
                }
            })
            .catch(error => {
                setIsLoading(false);
                console.log({error})
            })
    }

    const handleChange = address => {
        setKeyWord("");
        setAddress(address)
    };

    const handleSelect = address => {
        Geocode.setApiKey(BhookyConstants.apiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();

        setState(prevState => {
            return {
                ...prevState,
                searchItems: []
            }
        })
        setKeyWord("");

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                    let {lat, lng} = latLng
                    setState(prevState => {
                        return{
                            ...prevState,
                            latitude: lat,
                            longitude: lng,
                        }
                    })
                    Geocode.fromLatLng(lat, lng)
                        .then(
                            response => {
                                const address = response.results[0].formatted_address;
                                console.log({address})
                                setAddress(address)
                                dispatch(updateLocation(address));
                            },
                            error => {
                                setShowBackdrop(false);
                                console.error(error);
                            }
                        )
                },
                (error) => {
                    console.error(error);
                    setShowBackdrop(false);
                })
            .catch(error => console.error('Error', error));
    };

    const getCurrentLocation = () => {
        setKeyWord("");
        Geocode.setApiKey(BhookyConstants.apiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();

        setShowBackdrop(true);

        navigator.geolocation.getCurrentPosition(
            async function (position) {
                const {latitude, longitude} = position.coords;
                setState(prevState => {
                    return{
                        ...prevState,
                        latitude: latitude,
                        longitude: longitude,
                    }
                })

                await Geocode.fromLatLng(latitude, longitude)
                    .then(
                        response => {
                            const address = response.results[0].formatted_address;
                            setAddress(address)
                            dispatch(updateLocation(address));
                            setShowBackdrop(false)
                        },
                        error => {
                            setShowBackdrop(false);
                            console.error(error);
                        }
                    )
            },
            (error) => {
                console.error(error);
                setShowBackdrop(false);
            }
        );
    };


    const getLocation = () => {
        setState(prevState => {
            return {
                ...prevState,
                searchItems: []
            }
        })
        getCurrentLocation();
    };


    const redirectToMerchantDetails = (merchant, e) => {
        e.preventDefault();
        setOpenSearch(false);
        setState(prevState => {
            return {
                ...prevState,
                showProductDetails: false,
                searchItems: [],
            }
        })
        history.push('/' + _.replace(merchant.name.toLowerCase(), ' ', '-') + '/' + merchant._id + '/order');

    }


    return (
        <div>
            <div className="search-items cursor" onClick={(e) => openSearchBar(e)}>
                <span className="font-weight-bold">Search</span>
                <i className="ml-1 fa fa-search" style={{fontSize: "20px"}}></i>
            </div>

            <Dialog open={openSearch} fullScreen={true} className="searching-div p-2">
                <DialogTitle>
                    <div className="search-container">
                        <div className="search-header">
                            <PlacesAutocomplete
                                value={address}
                                onChange={handleChange}
                                onSelect={handleSelect}
                            >
                                {({index, getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                    <div key={index} className='d-flex justify-content-center locate-me-field'>
                                        <TextField
                                            {...getInputProps({
                                                placeholder: 'Search Places',
                                                className: 'location-search-input',
                                            })}
                                            label={"Locate Me"}
                                            style={{marginBottom: "1rem"}}
                                            InputProps={{
                                                endAdornment: showBackdrop ? (
                                                    <CircularProgress
                                                        style={{width: 20, height: 20, color: "black"}}
                                                    />
                                                ) : (
                                                    <IconButton
                                                        onClick={getLocation}
                                                        style={{color: "black"}}
                                                    >
                                                        <MyLocationIcon/>
                                                    </IconButton>
                                                ),
                                            }}
                                            onFocus={(e) => setAddress("")}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div className='d-flex bg-white p-4' style={{ borderRadius: "20px", transition: ".2s linear" }}>Loading</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                const key = suggestion.description
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                            key
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>

                            <Button variant="contained" style={searchNearByButton} size={"small"} onClick={(e)=>getNearByMerchants(e)}>
                                <LocationOnIcon/> Search near by Merchants
                            </Button>

                            <span
                            className="cursor close-search"
                            onClick={(e) => closeSearchBar(e)}>
                                &#10005;
                            </span>
                        </div>
                    </div>
                    <input type="text" className="item-search-input"
                            autoFocus={true}
                            value={keyword}
                            onChange={(e) => SearchItems(e)}
                            placeholder="Search..."/>
                </DialogTitle>
                {
                    state.searchItems && state.searchItems.length > 0 ?
                        <DialogContent>
                            <div className="result d-flex">
                                {
                                    state.searchItems.map(item => {
                                        return (
                                            item.type === "product" ?
                                                <div className="search-result cursor"
                                                        onClick={(e) => openSearchedItemDetails(item)}
                                                        key={item._id}>
                                                    <div className="search-result-image">  
                                                        <img src={item.image} alt={item.name}/>
                                                    </div> 
                                                    <div className="search-result-details-div">
                                                        <p className="search-result-details-name">{item.name}</p>
                                                        <p className="search-result-details-type">{item.type === "product" ? "Dish" : "Restaurant"}</p>
                                                    </div>
                                                </div>
                                                :
                                                <div className="search-result cursor"
                                                        onClick={(e) => goToMerchant(item, e)}
                                                        key={item._id}>
                                                    <div className="search-result-image">  
                                                        <img src={item.image ? item.image : item.profilePic} alt={item.name}/>
                                                    </div> 
                                                    <div className="search-result-details-div">
                                                        <p className="search-result-details-name">{item.name}</p>
                                                        <p className="search-result-details-type">{item.type === "product" ? "Dish" : "Restaurant"}</p>
                                                    </div>
                                                </div>
                                        )

                                    })
                                }

                            </div>
                        </DialogContent>
                        :
                        <DialogContent>
                            <Typography className="no-items-found" color="primary">
                                <img src={NoItemsFound} alt="No Items Found" className="no-items-found-image" />
                                No Items Found
                            </Typography>
                        </DialogContent>
                }
                {
                    <Dialog open={isLoading}>
                        <DialogTitle>
                            <Typography>Loading...</Typography>
                        </DialogTitle>
                    </Dialog>
                }
                {
                    state.showProductDetails ?
                        <Dialog
                            open={state.showProductDetails}
                            fullWidth={true}
                            maxWidth={'sm'}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description"
                        >
                            <DialogActions className='justify-content-between item-dialog'>
                                <DialogTitle id="scroll-dialog-title">
                                    <Typography className='bhooky-semibold'>
                                        {state.product?.name}
                                    </Typography>
                                </DialogTitle>
                                <CloseButton onClick={handleClose} className='my-4 mr-2'/>
                            </DialogActions>
                            <DialogContent
                                dividers={true} className='m-0 p-0'>
                                <Carousel interval={500}>

                                    {
                                        state.product?.image?.map((img, i) => (
                                            <Carousel.Item key={i}>
                                                <img
                                                    src={img ? img : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTI9wAAiM4eBLesNjgpOTn-_27WXIb6kEevJQ&usqp=CAU"}
                                                    alt={""}
                                                    style={{
                                                        "objectFit": "cover",
                                                        height: "400px",
                                                        width: "600px"
                                                    }}
                                                />
                                            </Carousel.Item>
                                        ))
                                    }

                                </Carousel>
                                {state.product.bulkQuantity ?
                                    <DialogContentText
                                        style={{color: "black"}}
                                        className='bhooky-regular m-3'
                                    >
                                        Min. Units Per Order: {state.product?.bulkQuantity}
                                    </DialogContentText>
                                    : null
                                }
                                <DialogContentText
                                    id="scroll-dialog-description"
                                    className='bhooky-regular m-3'
                                >
                                    {state.product?.description}
                                </DialogContentText>
                                <div className='d-flex dialog-addtocart justify-content-between'>
                                    <button color="primary"
                                            className='search-result-details-link-more-from-merchant'
                                            onClick={(e) => redirectToMerchantDetails(state.product.merchant, e)}>
                                        More from this merchant
                                    </button>
                                    {/*<div*/}
                                    {/*    className={`${isResponsive ? 'flex-column-reverse' : 'flex-row align-items-center'} d-flex `}>*/}
                                    <AddorRemoveButtons size='extraSmall' className='dialog-add'
                                                        handleCart={(e) => updateCart(e)}
                                                        cartCount={state.product.cartCount}
                                                        totalQuantity={state.product.quantity}/>
                                    <Typography variant="body2" component="p"
                                                className='text-light bhooky-semibold pl-3 pr-2 text-center my-auto dialog-item-price'>
                                        {state.product?.price}
                                    </Typography>
                                    {/*</div>*/}
                                </div>
                            </DialogContent>

                        </Dialog>
                        : null
                }
            </Dialog>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        cart: state.cart
    };
}

export default connect(mapStateToProps)(withCookies(Search));