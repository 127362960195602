import instance from "../../api/axios";

export function fetchOrders (body, skip) {
    return instance.post('order/data?skip='+skip+'&limit=20', body)
        .then(response => {
            let {data} = response;
            return data;
        })
        .catch(error=>{
            return error;
        })
}

export function rateMerchant(body){
    return instance.post('merchant/review', body)
        .then(response=>{
            let {data} = response;
            return data;
        })
        .catch(error=>{
            return error;
        })
}