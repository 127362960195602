import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './reviews.scss';
import axios from '../../api/axios';
import Review from './Review/Review';
import { Container, Typography } from '@material-ui/core';

export default function Reviews(props) {
  const [reviews, setReviews] = React.useState([]);
  useEffect(() => {

    axios
      .post('merchant/getreviews', { merchant: props.match.params.merchantid })
      .then(res => {
        const data = res.data;
        if (data.success) {
          setReviews(data.merchantReviews);
          console.log(data);
        }
      })
      .catch((error) => {
      });


  }, []);
  return (
    <div className="container my-4">
      <h5 className="ml-3">
          Reviews
      </h5>
      <section className="cards d-flex flex-wrap">
        {
            reviews && reviews.length > 0 ?
            reviews.map((review, i) => <Review reviewInfo={review} />) 
            :
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <h6 className="ml-3">No Reviews found!</h6>
            </div>
        }
      </section>
    </div>
  )
}

