import React, {Component} from 'react';

import './Profile.css';
import {profilePic} from "../../assets";
import {
    changeEmail,
    changePassword,
    changeProfilePicture,
    deleteCard,
    fetchUserDetails,
    getOTPForChangeMobileNumber,
    onChange,
    onClick,
    updateProfilePic,
    verifyOTPForChangeMobileNumber,
} from "./functions";
import sha256 from "crypto-js/sha256";
import Alert from "@material-ui/lab/Alert";
import {Collapse, TextField} from "@material-ui/core";
import {Link} from 'react-router-dom';
import instance from "../../api/axios";

export default class Profile extends Component {

    constructor(props) {
        super(props);
        let password = localStorage.getItem('password');
        let user = JSON.parse(localStorage.getItem('user'));
        // let addressArray = user ? Object.values(user.deliveryAddress[user.deliveryAddress.length-1]) : [];
        // console.log(Object.values(user.deliveryAddress[user.deliveryAddress.length-1]) )
        // addressArray.shift()
        // let address = addressArray.length>0 ? addressArray.join() :"";
        // console.log(address)
        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            user_id: user ? user._id : "",
            userName: user ? user.userName : "",
            phoneNumber: user ? user.mobile : "",
            oldPhoneNumber: user?.mobile,
            oldEmail: user?.email,
            otp: "",
            otpToken: "",
            email: user ? user.email : "",
            password,
            profilePic: (user && user.profilePic.length > 0) ? user.profilePic : profilePic,
            oldPassword: "",
            newPassword_1: "",
            newPassword_2: "",
            address: user ? user.city : "",
            changingElement: "",
            otpRequestSent: false,
            userFetched: false,
            changeInitiated: false,
            newProfilePicture: "",
            open: false,
            aleartInfo: {severity: "info", msg: ""},
            showPayments: false,
            cards: [],
            isCardsLoaded: false,
            deleteError: "",
            isPhoneError: false,
            phoneError: "",
            isEmailError: false,
            emailError: "",
            isPasswordError: "",
            passwordError: "",
        }
        this.onChange = onChange.bind(this);
        this.onClick = onClick.bind(this);
        this.fetchUser = this.fetchUser.bind(this);
        this.ShowPayments = this.ShowPayments.bind(this);
    }

    componentWillMount() {
        fetchUserDetails()
            .then(data => {
                if (data.success) {
                    localStorage.setItem('user', JSON.stringify(data.user_details))
                    let user = data.user_details;
                    console.log({user})
                    this.setState({
                        userFetched: true,
                        phoneNumber: user.mobile,
                        email: user.email,
                        address: user.city,
                        userName: user.userName,
                    })
                } else {
                    this.setState({
                        userFetched: true
                    })
                }
            })
            .catch(error => {
                console.log({error})
            })
    }

    getCards(e) {
        e.preventDefault();
        let body = {
            customer_id: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).stripeId : "",
            type: "card",
        }
        instance.post('user/cards', body)
            .then(response => {
                // console.log({response})
                this.setState({
                    cards: response.data.data,
                    isCardsLoaded: true,
                })
            })
            .catch(error => {
                console.log({error})
                this.setState({
                    isCardsLoaded: true,
                })
            })
    }

    fetchUser() {
        console.log("hii")
        fetchUserDetails()
            .then(data => {
                if (data.success) {
                    localStorage.setItem('user', JSON.stringify(data.user_details))
                    let user = data.user_details;
                    this.setState({
                        userFetched: true,
                        phoneNumber: user.mobile,
                        email: user.email,
                        address: user.city,
                        userName: user.userName,
                        profilePic: user.profilePic === "" ? profilePic : user.profilePic,
                    })
                    window.location.reload();
                } else {
                    this.setState({
                        userFetched: true
                    })
                }
            })
            .catch(error => {
                console.log({error})
            })
    }

    getOTPForChangeMobileNumber(e) {
        e.preventDefault();
        let {phoneNumber, userName, user_id, oldPhoneNumber} = this.state;
        getOTPForChangeMobileNumber(phoneNumber, userName, user_id)
            .then(data => {
                console.log({data})
                if (data.success) {
                    this.setState({
                        otpRequestSent: true,
                        otpToken: data.token,
                    })
                } else {
                    this.setState({
                        open: true,
                        aleartInfo: {severity: "error", msg: data.message},
                        phoneNumber: oldPhoneNumber,
                    })
                    setTimeout(() => {
                        this.setState({
                            open: false,
                            changingElement: "",
                        });
                    }, 2000);
                }
            })
            .catch(error => {
                this.setState({
                    otpRequestSent: false,
                    changingElement: "",
                    open: true,
                    aleartInfo: {severity: "error", msg: "Try Again.."},
                    phoneNumber: oldPhoneNumber,
                })
                setTimeout(() => {
                    this.setState({
                        open: false,
                        changingElement: "",
                    });
                }, 2000);
            })
    }

    verifyOTPForChangeMobileNumber(e) {
        e.preventDefault();
        let {user_id, userName, phoneNumber, otp, otpToken, oldPhoneNumber} = this.state;
        verifyOTPForChangeMobileNumber(user_id, userName, phoneNumber, otp, otpToken)
            .then(data => {
                if (data.success) {
                    this.setState({
                        otpRequestSent: false,
                        phoneNumber,
                        otp: "",
                        changingElement: "",
                        open: true,
                        aleartInfo: {severity: "success", msg: data.message}
                    })
                    this.fetchUser()
                } else {
                    this.setState({
                        open: true,
                        aleartInfo: {severity: "error", msg: data.message},
                        phoneNumber: oldPhoneNumber,
                    })
                    setTimeout(() => {
                        this.setState({
                            open: false,
                            changingElement: "",
                        });
                    }, 2000);
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    otpRequestSent: true,
                    changingElement: "",
                    open: true,
                    aleartInfo: {severity: "error", msg: "Try Again.."},
                    phoneNumber: oldPhoneNumber,
                })
                setTimeout(() => {
                    this.setState({
                        open: false,
                        changingElement: "",
                    });
                }, 2000);
            })
    }

    changeEmail(e) {
        e.preventDefault();
        let {user_id, email, oldEmail} = this.state;
        if (email.length > 0) {
            changeEmail(user_id, email)
                .then(data => {
                    if (data.success) {
                        this.setState({
                            changingElement: "",
                            email,
                            open: true,
                            aleartInfo: {severity: "success", msg: data.message}
                        })
                        this.fetchUser();
                    } else {
                        this.setState({
                            open: true,
                            aleartInfo: {severity: "error", msg: data.message},
                            email: oldEmail,
                        })
                        setTimeout(() => {
                            this.setState({
                                open: false,
                                changingElement: "",
                            });
                        }, 2000);
                    }
                })
                .catch(error => {
                    console.log({error});
                    this.setState({
                        changingElement: "",
                        open: true,
                        aleartInfo: {severity: "error", msg: "Try Again.."},
                        email: oldEmail,
                    })
                    setTimeout(() => {
                        this.setState({
                            open: false,
                            changingElement: "",
                        });
                    }, 2000);
                })
        }
    }

    changePassword(e) {
        e.preventDefault();
        let {user_id, oldPassword, newPassword_1, newPassword_2} = this.state;
        if (oldPassword.length > 0 && newPassword_1.length > 0 && (newPassword_1 === newPassword_2)) {
            changePassword(user_id, oldPassword, newPassword_1)
                .then(data => {
                    if (data.success) {
                        localStorage.setItem('password', sha256(newPassword_1 + newPassword_1));
                        this.setState({
                            changingElement: "",
                            password: localStorage.getItem('password'),
                            open: true,
                            aleartInfo: {severity: "success", msg: data.message}
                        })
                    } else {
                        this.setState({
                            open: true,
                            aleartInfo: {severity: "error", msg: data.message}
                        })
                        setTimeout(() => {
                            this.setState({
                                open: false,
                                changingElement: "",
                            });
                        }, 2000);
                    }
                })
                .catch(error => {
                    console.log({error})
                    this.setState({
                        changingElement: "",
                        open: true,
                        aleartInfo: {severity: "error", msg: "Try Again.."}
                    })
                    setTimeout(() => {
                        this.setState({
                            open: false,
                            changingElement: "",
                        });
                    }, 2000);
                })
        } else {
            this.setState({
                open: true,
                aleartInfo: {severity: "error", msg: "Passwords not matching or Invalid"}
            })
        }
    }

    profilePicUpload(e) {
        changeProfilePicture(this.state.user_id, e.target.files[0])
            .then(data => {
                if (data.Location !== "") {
                    console.log({data})
                    this.profilePc(data.Location);
                } else {
                    this.setState({
                        open: true,
                        aleartInfo: {severity: "error", msg: data.message}
                    })
                }
            })
            .catch(error => {
                this.setState({
                    changingElement: "",
                    open: true,
                    aleartInfo: {severity: "error", msg: "Try Again.."}
                })
                setTimeout(() => {
                    this.setState({
                        open: false,
                        changingElement: "",
                    });
                }, 2000);
            })
    }

    profilePc(profilePic) {
        let {user_id} = this.state;
        updateProfilePic(user_id, profilePic)
            .then(data => {
                if (data.success) {
                    this.setState({
                        profilePic: profilePic
                    })
                } else {
                    this.setState({
                        open: true,
                        aleartInfo: {severity: "error", msg: data.message}
                    })
                    setTimeout(() => {
                        this.setState({
                            open: false,
                            changingElement: "",
                        });
                    }, 2000);
                }
            })
            .catch(error => {
                this.setState({
                    changingElement: "",
                    open: true,
                    aleartInfo: {severity: "error", msg: "Try Again.."}
                })
                setTimeout(() => {
                    this.setState({
                        open: false,
                        changingElement: "",
                    });
                }, 2000);
            })
    }

    ShowPayments(e) {
        if (this.state.showPayments) {
            this.setState({
                showPayments: false,
            })
        } else {
            this.setState({
                showPayments: true
            })
        }
    }

    deleteUserCard(id) {
        deleteCard(id)
            .then(data => {
                console.log({data})
                if (data.status) {
                    window.location.reload();
                } else {
                    this.setState({
                        deleteError: data.message
                    })
                }
                // window.location.reload();
            })
            .catch(error => {
                console.log({error})
            })
    }

    render() {
        let state = this.state;
        return (
            <div className="component">
                <div className="div-profile-picture">
                    <img className="profile-picture" src={state.profilePic} alt="USER"/>
                    <label htmlFor="upload-profile-picture" className="edit-profile-picture">
                        CHANGE
                    </label>
                    <input
                        name={"new-profile-picture"}
                        type={"file"}
                        id={"upload-profile-picture"}
                        style={{display: "none"}}
                        accept={"image/*"}
                        onChange={(e) => this.profilePicUpload(e)}
                    />
                    <p className="user-name"> {state.userName}</p>
                </div>

                <div className="div-user-details-form">
                    <Collapse in={state.open}>
                        <Alert severity={state.aleartInfo.severity}>{state.aleartInfo.msg}</Alert>
                    </Collapse>
                    <div className="div-input">

                        <label htmlFor="phoneNumber">Phone Number</label>

                        <div className="flex">
                            <input
                                type="number"
                                className={state.changingElement === "phoneNumber" ? "change-input" : "real-input"}
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Phone Number..."
                                value={state.phoneNumber}
                                onChange={this.onChange}
                                disabled={state.changingElement === "phoneNumber" ? false : true}
                                style={state.changingElement === "phoneNumber" ? {
                                    width: "100%",
                                    border: "2px solid black"
                                } : {}}
                            />
                            <button
                                className="change-button"
                                style={state.changingElement === "phoneNumber" ? {display: "none"} : {}}
                                onClick={(e) => this.onClick("phoneNumber")}>Change
                            </button>
                        </div>

                        <button
                            className="verify-button"
                            onClick={(e) => this.getOTPForChangeMobileNumber(e)}
                            style={state.changingElement === "phoneNumber" ? {} : {display: "none"}}
                        >Verify
                        </button>

                        <div className="flex">
                            <input
                                type="number"
                                className="change-input"
                                id="otp"
                                name="otp"
                                placeholder="OTP..."
                                value={state.otp}
                                onChange={this.onChange}
                                style={state.otpRequestSent ? {
                                    width: "100%",
                                    border: "2px solid black",
                                } : {
                                    display: "none",
                                }}
                            />
                        </div>

                        <div className="flex">
                            <button
                                className="verify-button"
                                onClick={(e) => this.verifyOTPForChangeMobileNumber(e)}
                                style={state.otpRequestSent ? {} : {display: "none"}}
                            >Confirm
                            </button>
                        </div>

                    </div>

                    <div className="div-input">

                        <label htmlFor="email">Email Id</label>

                        <div className="flex">
                            <input
                                type="email"
                                className={state.changingElement === "email" ? "change-input" : "real-input"}
                                id="email"
                                name="email"
                                placeholder="EMail..."
                                value={state.email}
                                onChange={this.onChange}
                                disabled={state.changingElement === "email" ? false : true}
                                style={state.changingElement === "email" ? {
                                    width: "100%",
                                    border: "2px solid black"
                                } : {}}
                            />
                            <button
                                className="change-button"
                                style={state.changingElement === "email" ? {display: "none"} : {}}
                                onClick={(e) => this.onClick("email")}>Change
                            </button>
                        </div>

                        <button
                            className="verify-button"
                            onClick={(e) => this.changeEmail(e)}
                            style={state.changingElement === "email" ? {} : {display: "none"}}
                        >Update
                        </button>

                    </div>

                    <div className="div-input">

                        <label htmlFor="password">
                            Password
                        </label>

                        <div className="flex">
                            <input
                                type="password"
                                className="real-input"
                                id="password"
                                name="password"
                                placeholder="Password..."
                                value={state.password}
                                onChange={this.onChange}
                                disabled={true}
                                style={state.changingElement === "password" ? {
                                    display: "none"
                                } : {}}
                            />
                            <button
                                className="change-button"
                                style={state.changingElement === "password" ? {display: "none"} : {}}
                                onClick={(e) => this.onClick("password")}>
                                Change
                            </button>
                        </div>

                        <div className="flex">
                            <TextField
                                label="Old password"
                                variant="outlined"
                                margin="normal"
                                type="password"
                                className="change-input"
                                id="oldPassword"
                                name="oldPassword"
                                placeholder="Current Password..."
                                value={state.oldPassword}
                                onChange={this.onChange}
                                style={state.changingElement === "password" ? {} : {display: "none"}}
                            />
                        </div>

                        <div className="flex">
                            <TextField
                                label="New password"
                                variant="outlined"
                                margin="normal"
                                type="password"
                                className="change-input"
                                id="newPassword_1"
                                name="newPassword_1"
                                placeholder="Type New Password..."
                                value={state.newPassword_1}
                                onChange={this.onChange}
                                style={state.changingElement === "password" ? {} : {display: "none"}}
                            />
                        </div>

                        <div className="flex">
                            <TextField
                                label="Confirm password"
                                variant="outlined"
                                margin="normal"
                                type="password"
                                className="change-input"
                                id="newPassword_2"
                                name="newPassword_2"
                                placeholder="Confirm New Password..."
                                value={state.newPassword_2}
                                onChange={this.onChange}
                                style={state.changingElement === "password" ? {} : {display: "none"}}
                            />
                        </div>

                        <div className="flex">
                            <button
                                className="verify-button"
                                onClick={(e) => this.changePassword(e)}
                                style={state.changingElement === "password" ? {} : {display: "none"}}
                            >Confirm
                            </button>
                        </div>

                    </div>

                    {/*<div className="div-input">*/}

                    {/*    <label htmlFor="address">Address</label>*/}

                    {/*    <div className="flex">*/}
                    {/*   <textarea*/}
                    {/*       id="address"*/}
                    {/*       name="address"*/}
                    {/*       value={state.address}*/}
                    {/*       className={state.changingElement === "address" ? "change-input" : "real-input"}*/}
                    {/*       onChange={this.onChange}*/}
                    {/*       disabled={state.changingElement === "address" ? false : true}*/}
                    {/*       placeholder="Address..."*/}
                    {/*       style={state.changingElement === "address" ? {*/}
                    {/*           width: "100%",*/}
                    {/*           border: "2px solid black",*/}
                    {/*           resize: "none",*/}
                    {/*       } : {*/}
                    {/*           resize: "none",*/}
                    {/*           border: "1px solid #ddd",*/}
                    {/*           backgroundColor: "white",*/}
                    {/*           fontSize: '15px'*/}
                    {/*       }}*/}
                    {/*   />*/}
                    {/*        <button*/}
                    {/*            className="change-button"*/}
                    {/*            style={state.changingElement === "address" ? {display: "none"} : {}}*/}
                    {/*            onClick={(e) => this.onClick("address")}>Change*/}
                    {/*        </button>*/}
                    {/*    </div>*/}

                    {/*    <button*/}
                    {/*        className="verify-button"*/}
                    {/*        onClick={(e) => this.verifyNewValue(e)}*/}
                    {/*        style={state.changingElement === "address" ? {} : {display: "none"}}*/}
                    {/*    >Update*/}
                    {/*    </button>*/}

                    {/*</div>*/}
                    <div className="div-input">
                        <div className="flex">
                            <button
                                className="change-button payments"
                                style={state.changingElement === "password" ? {display: "none"} : {}}
                                onClick={(e) => this.ShowPayments(e)}>
                                <i className="fa fa-credit-card"><span>Payments</span></i>
                            </button>
                        </div>
                        {
                            state.showPayments ?
                                <>
                                    {/*<Link to="existing-cards">*/}

                                    <button
                                        className="payments"
                                        style={state.changingElement === "password" ? {display: "none"} : {}}
                                        onClick={(e) => this.getCards(e)}
                                    >
                                        Existing Cards
                                    </button>
                                    {/*</Link>*/}
                                    {
                                        state.isCardsLoaded ?

                                            state.cards.length > 0 ?
                                                <table className="cards-details">
                                                    <tbody>
                                                    {
                                                        state.deleteError !== "" ?
                                                            <tr>
                                                                <th colSpan={2}>
                                                                    <p>{state.deleteError}</p>
                                                                </th>
                                                            </tr>
                                                            : null
                                                    }
                                                    {
                                                        state.cards.map(card => {
                                                            return (
                                                                <tr key={card.id}>
                                                                    <th>
                                                                        <i className="fa fa-credit-card"
                                                                           key={card.id}>************{card.card.last4}</i>
                                                                    </th>
                                                                    <td>
                                                                        <button className="cursor"
                                                                                style={{background: "transparent"}}
                                                                                onClick={(e) => this.deleteUserCard(card.id)}>
                                                                            <i className="fa fa-trash"> </i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                                : <table className="cards-details">
                                                    <tbody>
                                                    <tr>
                                                        <th>
                                                            No Cards...
                                                        </th>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            : null
                                    }
                                    <Link to="add-a-new-card">
                                        <button
                                            className="payments"
                                            style={state.changingElement === "password" ? {display: "none"} : {}}
                                        >
                                            Add a New Card
                                        </button>
                                    </Link>
                                </>
                                : null
                        }
                    </div>
                </div>

            </div>

        );
    }
}

