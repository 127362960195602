import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import './orders.scss'
import instance from "../../api/axios";
import QRCode from 'react-qr-code';
import Dialog from '@material-ui/core/Dialog';
import {CloseButton} from "react-bootstrap";
import {DialogContentText, Typography} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import {fetchOrders, rateMerchant} from "./functions";
import DialogContent from "@material-ui/core/DialogContent";
import ReactStars from "react-rating-stars-component";
import InfiniteScroll from 'react-infinite-scroll-component';


export default (props) => {
    const [state, setState] = useState({
        orders: [],
        ordersFetched: false,
        currentOrder: {},
        showCurrentOrder: false,
        isRatingOpen: false,
        rateTo: {},
        orderPanelWidth: 0
    });

    const [aboutRating, setAbout] = useState("");
    const [stars, setStars] = useState(0)
    const [itemsLength, setItemsLength] = useState(20);
    const [skipItems, setSkipItems] = useState(1);
    const user = JSON.parse(localStorage.getItem('user'));

    const viewOrderDetails = (order, e) => {
        e.preventDefault();
        setState(prevState => {
            return {
                ...prevState,
                currentOrder: order,
                showCurrentOrder: true,
                orderPanelWidth: "70vw",
            }
        })
    }

    const closeCurrentOrder = (e) => {
        e.preventDefault();
        setState(prevState => {
            return {
                ...prevState,
                currentOrder: {},
                showCurrentOrder: false,
                orderPanelWidth: "0",
            }
        })
    }

    const toggleRatingBar = (e) => {
        e.preventDefault();
        if (state.isRatingOpen) {
            setState(prevState => {
                return {
                    ...prevState,
                    isRatingOpen: false,
                }
            })
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    isRatingOpen: true,
                }
            })
        }
    }

    const setRateTo = (order, e) => {
        e.preventDefault();
        setState(prevState => {
            return {
                ...prevState,
                rateTo: order,
                isRatingOpen: true,
            }
        })
    }

    const changeRating = (e) => {
        setStars(e)
    }
    const changeAboutRating = (e) => {
        setAbout(e.target.value)
    }

    const updateUserStars = (rateTo, e) => {
        e.preventDefault();
        if (user && user._id && user._id.length && rateTo) {
            let body = {
                user: user._id,
                order_id: rateTo._id,
                merchant: rateTo.merchant._id,
                userName: user.userName,
                description: aboutRating,
                rating: stars,
            }
            rateMerchant(body)
                .then(data => {
                    if (data.success) {
                        window.location.reload();
                    }
                })
                .catch(error => {
                    console.log({error})
                })
        }
    }

    const fetchNextOrders = () => {
        if (user && user._id && user._id.length) {
            let body = {
                user: user._id
            }
            let skip = (skipItems + 1) * itemsLength
            setSkipItems(skipItems + 1);
            fetchOrders(body, skip)
                .then(data => {
                    if (data.success) {
                        setState(prevState => {
                            return {
                                ...prevState,
                                ordersFetched: true,
                                orders: state.orders.push(data.orders),
                            }
                        })
                    } else {
                        setState(prevState => {
                            return {
                                ...prevState,
                                ordersFetched: true,
                                orders: [],
                            }
                        })
                    }
                })
        }
    }

    useEffect(() => {
        if (!state.ordersFetched) {
            if (user && user._id && user._id.length) {
                let body = {
                    user: user._id
                }
                let skip = 0
                fetchOrders(body, skip)
                    .then(data => {
                        if (data.success) {
                            console.log({data})
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    ordersFetched: true,
                                    orders: data.orders,
                                }
                            })
                        } else {
                            setState(prevState => {
                                return {
                                    ...prevState,
                                    ordersFetched: true,
                                    orders: [],
                                }
                            })
                        }
                    })
            }
        }
    }, [state.ordersFetched])

    let {orders, currentOrder, showCurrentOrder, isRatingOpen, rateTo} = state;

    if (user) {
        return (
            <div>
                {
                    orders && orders.length > 0 ? orders.map(order => {
                        let {merchant, updatedTime, createdTime, items, rated} = order
                        let updated_time = new Date(updatedTime)
                        let created_time = new Date(createdTime)
                        return (
                            order.orderStatus === "initiated" ? null :
                                <div key={order._id} className="font-size-10">
                                <div className="_3xMk0 font-size-10">
                                    <div className="g28rk">
                                        <div className="_2XWVq font-size-10">
                                            <div className="_359Fc">
                                                <img className="_2tuBw _12_oN" height={200} width={300}
                                                     alt="" src={merchant.profilePic}/>
                                            </div>
                                            <div className="bhooky-bold font-size-10">{merchant.name}</div>
                                            <div
                                                className="bhooky-light font-size-10 break-address">{merchant.address.name}</div>
                                            <div className="bhooky-regular font-size-10">
                                                <p>ORDER #{order.id} | {created_time.toString()}</p>
                                            </div>
                                            <div
                                                className={order.orderStatus === "pickup pending" ? "green-color add-top-space" : "red-color add-top-space"}>
                                                Status: {order.orderStatus}
                                            </div>
                                            <div className="_1ziWV">
                                                <p onClick={(e) => viewOrderDetails(order, e)}>
                                                    VIEW DETAILS
                                                </p>
                                            </div>
                                            <div className="_2fkm7">
                                                {
                                                    order.orderStatus === "pickup pending"
                                                        ?
                                                        <QRCode value={order.id} size={96}/>
                                                        :
                                                        <span
                                                            className="font-size-10">{order.orderStatus} on {updated_time.toString()}</span>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_3SKK0">
                                        <div className="nRCg_">
                                            {
                                                items && items.map(item => {
                                                    return (
                                                        <div key={item._id}>
                                                            <b className="font-size-10">{item.quantity} {item.name} at {item.price} each
                                                                x 1</b>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                        {
                                            order.orderStatus === "pickup pending" ?
                                                null
                                                :
                                                rated ? null :
                                                    <div className="_2a27y">
                                                        <span
                                                            onClick={(e) => setRateTo(order, e)}
                                                            className="_3PUy8 f4Ovn cursor">
                                                            RATE US
                                                        </span>
                                                        <a className="_3PUy8">HELP</a></div>
                                        }
                                        <div className="_23DHc">Total Paid: $ {order.totalAmount / 100}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                        : null
                }
                {
                    currentOrder ?

                        <div className="side-panel" style={{width: state.orderPanelWidth}}>
                            <div className=" d-flex">
                                <DialogTitle id="form-dialog-title">
                                    <Typography className='bhooky-bold'>Order ID #{currentOrder.id}</Typography>
                                    <p className="bhooky-light font-size-7">{new Date(currentOrder.updatedTime).toString()}</p>
                                    <CloseButton className='mr-4 mb-2' onClick={(e) => closeCurrentOrder(e)}/>
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                {
                                    currentOrder.merchant ?
                                        <DialogContent className="justify-content-between">
                                            <img src={currentOrder.merchant.profilePic} height={100} width={100}
                                                 alt={"*"}/>
                                            <div className="p-lg-3 font-size-10">
                                                <p className="bhooky-bold">{currentOrder.merchant.name}</p>
                                                <p className="bhooky-light">{currentOrder.merchant.address.name}</p>
                                            </div>
                                        </DialogContent>
                                        : null
                                }
                                <hr/>
                                <table border={1} className="orders-table">
                                    <tbody>
                                    <tr>
                                        <th>
                                            Item Name
                                        </th>
                                        <th>
                                            Quantity
                                        </th>
                                        <th>
                                            Price
                                        </th>
                                    </tr>
                                    {
                                        currentOrder.items && currentOrder.items.length > 0
                                            ? currentOrder.items.map(item => {
                                                return (
                                                    <tr key={item._id}>
                                                        <td>
                                                            {item.name}
                                                        </td>
                                                        <td>
                                                            {item.quantity}
                                                        </td>
                                                        <td>
                                                            $ {item.quantity * item.price}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : null
                                    }
                                    </tbody>
                                </table>
                                <hr/>
                                <p id="form-dialog-title" className="bhooky-bold">
                                    Billing Details:
                                </p>
                                <table className="orders-table">
                                    <tbody>
                                    <tr>
                                        <th>
                                            Items Price
                                        </th>
                                        <th>
                                            :
                                        </th>
                                        <td>
                                            $ {currentOrder.itemTotal}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Taxes
                                        </th>
                                        <th>
                                            :
                                        </th>
                                        <td>
                                            $ {currentOrder.tax ? currentOrder.tax.toFixed(2) : 0.00}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Total Paid
                                        </th>
                                        <th>
                                            :
                                        </th>
                                        <td>
                                            $ {currentOrder.totalAmount / 100}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </DialogContent>
                        </div>
                        : null
                }
                {
                    rateTo ?
                        <Dialog open={isRatingOpen} han="true" aria-labelledby="form-dialog-title">
                            <div className="d-flex justify-content-between">
                                <DialogTitle id="form-dialog-title">
                                    <Typography className='bhooky-bold'>Rate Us: </Typography>
                                </DialogTitle>
                                <CloseButton className='mr-4 mb-2' onClick={(e) => toggleRatingBar(e)}/>
                            </div>
                            <DialogContent>
                                {
                                    rateTo.merchant ?
                                        <DialogContent className="d-flex">
                                            <img src={rateTo.merchant.profilePic} height={100} width={100}
                                                 alt={"*"}/>
                                            <div className="p-lg-3 font-size-10">
                                                <p className="bhooky-bold">{rateTo.merchant.name}</p>
                                                <p className="bhooky-light">{rateTo.merchant.address.name}</p>
                                            </div>
                                        </DialogContent>
                                        : null
                                }
                                <hr/>
                                {
                                    rateTo ?
                                        <div className="rate-us-div">
                                            <ReactStars
                                                value={stars}
                                                onChange={changeRating}
                                                count={5}
                                                name="stars"
                                                activeColor="#ffd700"
                                                size={24}
                                            />
                                            <textarea
                                                value={aboutRating}
                                                rows={5}
                                                name={"aboutRating"}
                                                className="aboutRating"
                                                placeholder="Write to Us..."
                                                onChange={changeAboutRating}
                                            > </textarea>
                                            <div>
                                                <div className="_2a27y">
                                                    <span
                                                        onClick={(e) => updateUserStars(rateTo, e)}
                                                        className="_3PUy8 f4Ovn cursor">
                                                        SUBMIT
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </DialogContent>
                        </Dialog>
                        : null
                }
            </div>
        )
    } else {
        return (
            <Redirect to="/"/>
        )
    }
}
