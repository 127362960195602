export function validateMobile (mobile){
    return mobile && mobile.length===10;
}

export function validateEmail(email){
    return email && email.match(/[A-Za-z0-9]+@+[a-z]+[.a-z]/);

}

export function validatePassword (password){
    return password && password.length>0;
}

export function capitalizeFirstLetter(string) {
    if(string && string.length)
    return string.charAt(0).toUpperCase() + string.slice(1);
}
