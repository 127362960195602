import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import Rating from '@material-ui/lab/Rating';
import './RestaurantCard.scss'
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '90%'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%'
    },
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    ratingIcon: {
        fontSize: 15
    },
    cardRatingAttr: {
        background: 'yellowgreen',
        color: 'white',
        padding: 3
    },
    cardTimingAttr: {
        color: '#000000c9'
    }
}));
export default function RestaurantCard(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    // console.log(props.product)

    return (
        props.product ?
        <Card className="restaurant-card flex-row" onClick={() => {
            props.showItemDialog();
        }}>
            <CardMedia
                className="food-item-image"
                image={props?.product?.image?.length > 0 ? props.product.image[0] : "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTI9wAAiM4eBLesNjgpOTn-_27WXIb6kEevJQ&usqp=CAU"}

            />
            <CardContent className="food-item-content">
                <div className='d-flex'>
                    <div className='d-flex flex-column px-3 py-2'>
                        <Typography className="food-item-name" component="h5" variant="h5" style={{textTransform: 'capitalize'}}>
                            {props.product.name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                            {props?.product?.merchant?.name}
                        </Typography>
                    </div>
                    <Typography variant="body2" component="p"
                                className='item-price bhooky-regular pt-3 mx-2 text-right'>
                        {props.product.price}
                    </Typography>
                </div>
                <div className={`${classes.controls} px-3 py-2`}>
                    <Rating name="half-rating" size="small" defaultValue={props?.product?.merchant?.totalRating} precision={0.5} readOnly />
                    <div>
                        <PersonIcon fontSize="small" className="text-secondary" />
                        <Typography variant="caption">
                            {props?.product.merchant.reviewCount}
                        </Typography>
                    </div>
                    <Typography variant="caption" className={classes.cardTimingAttr}>
                        {props.product.distance}
                    </Typography>
                </div>
            </CardContent>
        </Card>
            :null
    );
}
