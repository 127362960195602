import axios from 'axios';
import React from "react";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import baseURL from "./baseURL";

const instance = axios.create({
    baseURL: baseURL,
    headers: { 'auth-type': 'user', 'Authorization': 'Bearer ' + window.localStorage.accessToken },
});

export default instance;